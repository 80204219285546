import React from "react";
import {Navigate, Redirect, Route, Switch, useParams} from "react-router";
import {Routes} from 'react-router-dom';
import {PropsRoute} from "../../util/routeRestriction";
import asyncComponent from "../../util/asyncComponent";

// const AsyncProjectCoordinatorSupportPerson = asyncComponent(() => import(''));
const AsyncTimeSheet = asyncComponent(() => import('../engineer/timeSheet/index'));
const AsyncAccountDetails = asyncComponent(() => import('./accountDetails/index'));
const AsyncReimbursement = asyncComponent(() => import('./reimbursement/index'));

const Consultant = (props) => {
  const { username } = useParams();
  // <Switch>
  return <Routes>
    {/* <Redirect exact from={`${props.match.url}/consultant`} to={`${props.match.url}`}/> */}
    <Route path={`${username}/calendar`} element={<Navigate to={`${username}`} />} />
        {/* <PropsRoute path={`${props.match.url}/engineers/list`} dispatch={props.dispatch}
          component={AsyncEngieersList} user={props.user}/>
        <PropsRoute path={`${props.match.url}/engineers/edit/:engineerId`} 
          dispatch={props.dispatch} component={AsyncEngieerEdit} user={props.user}/> */}
      <Route path="timesheet/:offerId" element={
        <PropsRoute path={`${username}consultant/timesheet/:offerId`} 
          dispatch={props.dispatch} element={<AsyncTimeSheet/>} user={props.user}/>
      }></Route>
      <Route path="account-details" element={
        <PropsRoute path={`${username}consultant/account-details`} 
          dispatch={props.dispatch} element={<AsyncAccountDetails/>} user={props.user}/>
      }></Route>
      <Route path="reimbursement" element={
        <PropsRoute path={`${username}consultant/reimbursement`} 
          dispatch={props.dispatch} element={<AsyncReimbursement/>} user={props.user}/>
      }></Route>
  </Routes>
}

export default Consultant;


// const Consultant = (props) => (
//   // <Switch>
//   <Routes>
//     {/* <Redirect exact from={`${props.match.url}/consultant`} to={`${props.match.url}`}/> */}
//     <Route path={`${props.match.url}/calendar`} element={<Navigate to={`${props.match.url}`} />} />
//         {/* <PropsRoute path={`${props.match.url}/engineers/list`} dispatch={props.dispatch}
//           component={AsyncEngieersList} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/engineers/edit/:engineerId`} 
//           dispatch={props.dispatch} component={AsyncEngieerEdit} user={props.user}/> */}
//         <PropsRoute path={`${props.match.url}/timesheet/:offerId`} 
//           dispatch={props.dispatch} component={AsyncTimeSheet} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/account-details`} 
//           dispatch={props.dispatch} component={AsyncAccountDetails} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/reimbursement`} 
//           dispatch={props.dispatch} component={AsyncReimbursement} user={props.user}/>

//   </Routes>
// );

// export default Consultant;