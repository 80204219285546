import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css"; // You need to import the styles for PerfectScrollbar

const CustomScrollbars = (props) => (
  <PerfectScrollbar {...props} options={{ suppressScrollX: true }}>
    {props.children}
  </PerfectScrollbar>
);

export default CustomScrollbars;



// import React from "react";
// import {Scrollbars} from "react-custom-scrollbars";

// const CustomScrollbars = (props) => <Scrollbars  {...props} autoHide
//                                                  renderTrackHorizontal={props => <div {...props}
//                                                                                       style={{display: 'none'}}
//                                                                                       className="track-horizontal"/>}/>;

// export default CustomScrollbars;
