import { message } from 'antd';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_INPUT,
  HIDE_MESSAGE,
  HIDE_MODAL,
  SHOW_INPUT,
  SHOW_MESSAGE,
  SHOW_MODAL,
  HANDLE_ERROR_RESPONSE,
  SHOW_CONFETTI,
  HIDE_CONFETTI,
  HANDLE_DISABLE_SUBMIT_BUTTON,
  HANDLE_REMOVE_DISABLE_SUBMIT_BUTTON
} from '../../constants/ActionTypes'

const INIT_STATE = {
  error: "",
  loading: false,
  message: '',
  visible: false,
  error_response:{},
  confettiVisible: false,
  disableSubmitButton:{}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {...state, error: '', message: '', error_response:{}, loading: true};
    }
    case FETCH_SUCCESS: {
      return {...state, error: '', message: '',error_response:{},  loading: false};
    }
    case SHOW_MESSAGE: {
      if(action.payload){
        message.success(action.payload)
      }
      return {...state, error: '', message: null, error_response:{}, loading: false}; 
      // return {...state, error: '', message: action.payload, error_response:{}, loading: false};
    }
    case FETCH_ERROR: {
      if(action.payload){
        message.error(action.payload)
      }
      return {...state, loading: false, error: action.payload, error_response:{},  message: ''};
    }
    case HIDE_MESSAGE: {
      return {...state, loading: false, error: '',error_response:{},  message: ''};
    }
    case HANDLE_ERROR_RESPONSE: {
      return {...state, loading: false, error_response: action.payload, error:"", message: ''};
    }
    case SHOW_MODAL:
    case SHOW_INPUT:{
      return {...state, loading: false, error: '', message: '',error_response:{},  visible: true};
    }
    case HIDE_MODAL:
    case HIDE_INPUT:{
      return {...state, loading: false, error: '', message: '',error_response:{},  visible: false};
    }
    case SHOW_CONFETTI:{ 
      return {
        ...state, 
        confettiVisible: true,
      }
    }
    case HIDE_CONFETTI :{
      return {
        ...state,
        confettiVisible: false,
      }
    }
    case HANDLE_DISABLE_SUBMIT_BUTTON :{
      return {
        ...state,
        disableSubmitButton: {
          ...state.disableSubmitButton,
          [action.payload.formKey]: action.payload.disabled,
        },
      };
    }
    case HANDLE_REMOVE_DISABLE_SUBMIT_BUTTON:
      const { [action.payload.formKey]: _, ...newdisableSubmitButton } = state.disableSubmitButton;
      return {
        ...state,
        disableSubmitButton: newdisableSubmitButton,
      };

    default:
      return state;
  }
}
