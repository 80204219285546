import React from 'react';
import moment from "moment";
import { timeZoneOptions } from '../../../constants/FilterData';
import dayjs from 'dayjs';

export const mapEvents = (eventList) => {
  const events = [];
  if (eventList && eventList.length > 0) {
    eventList.map(event => {
      events.push({
        start: new Date(event.start),
        end: new Date(event.end),
        title: event.title,
        details: event.details
      });
    });
  }

  return events
};


const getDates = (inputDate) => {
    const compareDate = inputDate.toDateString();
    const displayDayName = inputDate.toLocaleString('default', {weekday: 'long'});
    const displayDate = inputDate.getDate();
    const displayMonth = inputDate.toLocaleString('default', {month: 'long'});
    const displayYear = inputDate.getFullYear();
    return {inputDate, compareDate, displayDayName, displayDate, displayMonth, displayYear};
  };

export  const getDisplayTime = (inputTime) => {
    let hours = inputTime.toLocaleString('default', {hour: 'numeric'});
    let minutes = inputTime.toLocaleString('default', {minute: 'numeric'});
    let newFormat = hours >= 12 ? ' PM' : ' AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ":" + minutes + newFormat
  };

export const displayDateFormat = (start, end) => {
    let allDay = false;
    let displayString = '';
    const startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
    const endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate());
    if (startDate === endDate || startDate < endDate) {
      allDay = true;
    }
    if (allDay) {

      let startValues = getDates(start);
      let endValues = getDates(end);

      if (startValues.compareDate === endValues.compareDate) {
        displayString = startValues.displayDayName + ', ' + startValues.displayDate + ' ' + startValues.displayYear;
      } else {
        displayString = startValues.displayDate + ' - ' + endValues.displayDate + ' ' + startValues.displayMonth + ' ' + startValues.displayYear
      }
    } else {
      let startValues = getDates(start);
      let endValues = getDates(end);
      const displayStartString = getDisplayTime(start);
      const displayEndString = getDisplayTime(end);
      if (startValues.compareDate === endValues.compareDate) {
        displayString = startValues.displayDayName + ', ' + startValues.displayDate + ' ' + startValues.displayMonth + ' ' + displayStartString + ' - ' + displayEndString
      } else {
        displayString = startValues.displayDate + ' ' + startValues.displayMonth + ' ' + startValues.displayYear + ', ' + displayStartString
          + ' - ' + endValues.displayDate + ' ' + endValues.displayMonth + ' ' + endValues.displayYear + ', ' + displayEndString

      }
    }
    return displayString;
  };

export const getAttendeeInformation = (attendeeList, screenerId) => {
    if (attendeeList) {
      let confirm = 0;
      let waiting = 0;
      let isCalendarOwner = false;
      const totalGuests = attendeeList.length;
      attendeeList.map(attendee => {
        if (attendee.responseStatus === 'accepted') {
          confirm += 1;
        } else {
          waiting += 1;
        }
        if (attendee.email === screenerId){
          isCalendarOwner = true;
        }

      });
      return {totalGuests, confirm, waiting, isCalendarOwner};
    }
    return ''
  };

export const convertToUserReadable = (key) => {
    key = key.replace(/[/_/]/g, ' ');
    return key.toUpperCase()
  };

export const convertTZ = (date, tzString) => {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
}


export const dateSplit = (date) => {
    const dateSplit = date.split(',');
    const datePart = dateSplit[0].split('/');
    const timePart = dateSplit[1].split(':');
    return new Date(datePart[2], datePart[1] - 1, datePart[0], timePart[0], timePart[1], timePart[2]).toISOString();
  };

  export const displayDateInMMDDYYYYTime = (date) => {
    if (date && date !== "undefined") {
      return moment.utc(date).local().format('DD MMM. YYYY HH:mm:ss');
    } else {
      return "N/A";
    }
  }
export const displayDateInMMDDYYYY = (date) => {
  if (date && date !== "undefined") {
    // return moment.utc(date).local().format('DD MMM. YYYY');
      return moment.utc(date).format('DD MMM. YYYY');
  } else {
    return "N/A";
  }
}

export const displayTimeInLocalAMPM = (time) => {
  if(!time)return
  const timeObj = new Date(time);
  timeObj.setHours(timeObj.getHours() + 5); // Add 5 hours
  timeObj.setMinutes(timeObj.getMinutes() + 30); // Add 30 minutes

  if (time && time!== "undefined") {
      const formattedTime = timeObj.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
    return formattedTime;
  } else {
    return "N/A";
  }
}

export const displayDateInDDMonYYYYFormat = (date) => {//formats MM/DD/YYYY only
  if (!date) return "N/A";
  // const parsedDate = dayjs(date, 'MM/DD/YYYY', true);
  const parsedDate = dayjs(date);
  if (parsedDate.isValid()) {
    return parsedDate.format('DD MMM. YYYY');
  } else {
    return "N/A";
  }
}

export const formatDateInYYYYMMDD = (startDate) => {
  return dayjs(startDate).format('YYYY-MM-DD');
};

export const displayInterviewSchedule = (startTime, endTime, timeZone) => {
  // const extraStartTimeKey = startTime ? startTime.split('+')[1] : 0
  // const extraEndTimeKey = endTime ? endTime.split('+')[1] : 0
  if (!startTime || !endTime) {
    return "N/A";
  }
  const extraStartTimeKey = startTime.includes('+') ? Number(startTime.split('+')[1]) : 0;
  const extraEndTimeKey = endTime.includes('+') ? Number(endTime.split('+')[1]) : 0;

  const parsedStartTime = dayjs(startTime);
  const parsedEndTime = dayjs(endTime);
  // const parsedStartTime = dayjs(startTime, moment.ISO_8601, true);
  // const parsedEndTime = dayjs(endTime, moment.ISO_8601, true);
  if (!parsedStartTime.isValid() || !parsedEndTime.isValid()) {
    return "N/A";
  }
  // const formattedStartTime = parsedStartTime.local().format('DD MMM. YYYY h:mmA');
  // const formattedEndTime = parsedEndTime.local().format('h:mmA');
  const startDate = startTime && dayjs(parsedStartTime).add(extraStartTimeKey,'hours').local().format('DD MMM. YYYY');
  const startLocalTime = startTime && dayjs(parsedStartTime).add(extraStartTimeKey,'hours').local().format('h:mmA');
  const endlocalTime = endTime&& dayjs(parsedEndTime).add(extraEndTimeKey,'hours').local().format('h:mmA');
  const getTimeZoneLabel = (timeZone) => {
    const foundTimeZone = timeZoneOptions.find((item) => item.value === timeZone);
    return foundTimeZone ? foundTimeZone.label : 'IST';
  };
  const timeZoneToShow = timeZone && getTimeZoneLabel(timeZone);

  // const interviewDate = parsedStartTime.local().format('DD MMM. YYYY');
  return(<div>
    <div>{startDate} </div>
    <div>{startLocalTime} - {endlocalTime} {timeZoneToShow}</div>
  </div>)
};  

export const Capitalize = (str) => {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const DeleteKeysFromSession = (keys) => {
  if (Array.isArray(keys) && keys.length > 0) {
      keys.forEach(key => {
          sessionStorage.removeItem(key);
      });
  }
}

export const formatHoursAndMinutes = (hours) => {
  const totalMinutes = Math.floor(hours * 60); // Convert hours to total minutes
  const hrs = Math.floor(totalMinutes / 60); // Get the whole hours
  const mins = totalMinutes % 60; // Get remaining minutes
  const secs = Math.round((hours * 3600) % 60); // Get seconds from the fractional part of hours

  const paddedHours = String(hrs).padStart(2, '0');
  const paddedMinutes = String(mins).padStart(2, '0');
  // const paddedSeconds = String(secs).padStart(2, '0');

  return `${paddedHours}:${paddedMinutes}:00`; // Format in HH:mm:ss
};

export const downloadFileOnSystem = async (fileUrl) => {
  if (!fileUrl) {
      console.error("No file URL provided.");
      return;
  }

  try {
      const response = await fetch(fileUrl, { method: "GET" });
      if (!response.ok) {
          throw new Error(`Failed to fetch file: ${response.statusText}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = fileUrl.split("/").pop(); // Extract filename from URL
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
  } catch (error) {
      console.error("Download failed:", error);
  }
};