import IntlMessages from "../../util/IntlMessages";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import React from "react";
import {useDispatch} from "react-redux";
import {
  fetchBarData,
  fetchConsultants,
  fetchDonutData,
  fetchHiredData,
  fetchPieData,
  fetchRatioData,
  fetchRecentRecruits,
  fetchRecruitsByStatus,
  fetchScreeners,
  fetchTasks,
  fetchEmployees,
  fetchRecruitersAddedConsultantCountList
} from "../../appRedux/actions";

import {THEME_TYPE_LITE} from "../../constants/ThemeSetting";
import moment from "moment";
import { groups, levelsConst } from "../../constants/Constants";
import { DeleteKeysFromSession } from "../../routes/recruitment/screening/utils";
import dayjs from "dayjs";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;


const RecruiterSidebar = ({navStyleClass, defaultOpenKeys, selectedKeys, themeType, user}) => {
  const yesterdayDate = new Date();
  yesterdayDate.setDate(new Date().getDate() - 1);
  const formattedYesterday = yesterdayDate.toISOString().slice(0, 10);
  const dispatch = useDispatch();
  const keysToDeleteFromSession = ['pageSize','employeePageNo','transferRequestPageNo']  // this keys from the session will be deleted in the click of the side bar tabs in the menu

  const fetchDashboardData = () => {
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchBarData(user.id));
    dispatch(fetchTasks(user));
    dispatch(fetchPieData());
    dispatch(fetchDonutData());
    dispatch(fetchHiredData());
    dispatch(fetchRatioData());
    dispatch(fetchRecentRecruits());
    dispatch(fetchRecruitsByStatus());
    (user && user.groups && user.level && user.groups.includes(groups['Recruitment']) && 
    user.level <= levelsConst['Team Lead']) && 
      dispatch(fetchRecruitersAddedConsultantCountList({
        from_date:dayjs(formattedYesterday).format('YYYY-MM-DD'),
        to_date: dayjs(formattedYesterday).format('YYYY-MM-DD')}));
  };

  const fetchConsultantList = () => {
    const storedDataString = sessionStorage.getItem('pagination');
    const storedData = storedDataString ? JSON.parse(storedDataString) : {};
  
    delete storedData.paginationPage;
    delete storedData.filterData;
    delete storedData.openTab; 
    storedData.resetPagination = 1;
  
    sessionStorage.setItem('pagination', JSON.stringify(storedData));
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchConsultants(null, false, {page:1}));
  };

  // const fetchConsultantList = () => {
  //   sessionStorage.removeItem('paginationPage')
  //   dispatch(fetchConsultants(null, false, {page:1}));
  //   sessionStorage.setItem('resetPagination' , 1);
  // };
  const fetchScreeningData = () => {
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchScreeners(user.id));
  };

  // const userData =  JSON.parse(sessionStorage.getItem("authUser"));
  const userData =  JSON.parse(localStorage.getItem("authUser"));

return (
  <Menu style={{marginBottom:'-40px'}} defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]} theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'} mode="inline">
  <Menu.Item key={"dashboard/".concat(user.username)}>
    <Link to={"/username/dashboard".replace('username', user.username)}
          onClick={() => fetchDashboardData()}>
      <i className="icon icon-dasbhoard"/>
      <span><IntlMessages id="sidebar.dashboard"/></span>
    </Link>
  </Menu.Item>

  {/* <MenuItemGroup key="recruitment" className="gx-menu-group">
    <SubMenu key="recruitment" popupClassName={navStyleClass}
            title={<span> <i className="icon icon-auth-screen"/>
              <span><IntlMessages id="sidebar.recruitment"/></span></span>}
    > */}
      {userData && userData.permissions.includes('view_consultant') &&
      <>
      <Menu.Item key="consultants">
        <Link to={"/".concat(user.username.concat("/recruitment/consultant/list"))}
              onClick={() => fetchConsultantList()}>
          <i className="icon icon-contacts"/>
          <span><IntlMessages id="sidebar.consultants"/></span>
        </Link>
        </Menu.Item>
        <Menu.Item key="recruitment.transferRequests">
          <Link to={"/".concat(user.username.concat("/transfer-requests"))}
                onClick={() => fetchConsultantList()}>
            <i className="icon icon-reply"/>
            <span><IntlMessages id="sidebar.transferRequests"/></span>
          </Link>
        </Menu.Item>
      
      </>
      }
      <Menu.Item key="screening">
        <Link to={"/".concat(user.username.concat("/recruitment/screening/list"))}
              onClick={() => fetchScreeningData()}>
          <i className="icon icon-hotel-booking"/>
          <span> <IntlMessages id="sidebar.screening"/></span>
        </Link>
      </Menu.Item>
      {userData && userData.permissions.includes('view_recruiter') &&
      <Menu.Item key="recruiters">
        <Link to={"/".concat(user.username.concat("/recruitment/recruiter/list"))}>
          <i className="icon icon-hotel-booking"/>
          <span> <IntlMessages id="sidebar.recruiters"/></span>
        </Link>
      </Menu.Item>
      }
    {/* </SubMenu>
  </MenuItemGroup> */}
</Menu>

);
};
export default RecruiterSidebar;
