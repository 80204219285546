import React, { useEffect, useState } from 'react'
import { fetchStart, fetchSuccess } from '../../../appRedux/actions';
import axios from 'axios';
import { Button, Card, Checkbox, Form, Input, InputNumber, message, Radio, Upload } from 'antd';
import { useDispatch } from 'react-redux';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Capitalize } from '../../recruitment/screening/utils';
import { useForm } from 'antd/lib/form/Form';

const AdditionalInformation = () => {
    const dispatch = useDispatch()
    const [form] = useForm()

    const [token, setToken] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const [data, setData] = useState(null)
    const [title, setTitle] = useState(null)
    const [attachmentFileList, setAttachmentFileList] = useState([]);
    const [checkboxData, setCheckBoxData] = useState(null);
    const [actionTaken, setActionTaken] = useState(false);

    useEffect(() => {
        if (window && window.location && window.location.pathname) {
            const splitedUrl = window.location.pathname.split('/');
            if (splitedUrl.length > 1) {
                const fullData = splitedUrl.slice(2);
                const fullToken = fullData && fullData.length > 0 ? `${fullData[0]}/${fullData[1]}` : null
                if (fullToken) {
                    setToken(fullToken)
                    const currentDomain = window.location.hostname;
                    var endpoint = '';
                    if (currentDomain === 'dev.stell.one') {
                        endpoint = "https://dev-backend.stell.one/api/consultants/forms/";
                    }
                    else if (currentDomain === 'stell.one') {
                        endpoint = "https://prod-backend.stell.one/api/consultants/forms/";
                    }
                    else if (currentDomain === 'staging.stell.one') {
                        endpoint = "https://backend.stell.one/api/consultants/forms/";
                    }
                    else if (currentDomain === 'localhost' || currentDomain === '127.0.0.1' || currentDomain === '0.0.0.0') {
                        endpoint = "http://localhost:8000/api/consultants/forms/";
                        // endpoint = "https://prod-backend.stell.one/api/consultants/on-boarding/";

                    } else {
                        endpoint = "https://backend.stell.one/api/consultants/forms/";
                    }
                    dispatch(fetchStart());
                    setIsLoading(true)
                    const apiEndpoint = `${endpoint}${fullToken ? fullToken + '/' : ''}`
                    axios.get(apiEndpoint)
                        .then((response) => {
                            dispatch(fetchSuccess());
                            setIsLoading(false)
                            if (response && response.data && response.data.form_fields) {
                                const keysArrayFromResponce = Object.keys(response.data.form_fields)
                                if (keysArrayFromResponce && keysArrayFromResponce.length > 0) {
                                    const formatedData = keysArrayFromResponce.map((labelKey) => {
                                        return {
                                            ...response.data.form_fields[labelKey],
                                            keyToUseInPayload: labelKey
                                        }
                                    })
                                    setData(formatedData)
                                    setTitle(response.data.title)
                                }
                            }
                        })
                        .catch((error) => {
                            setIsLoading(false)
                            message.error('Error !');
                            console.log('error', error);
                        });
                }
            }
        }
    }, [])

    const handleCheckBoxClick = (value, idx, field_type, label, mainObjIndex, keyToUseInPayload) => {
        setCheckBoxData((prev) => {
            if (!prev) {
                return [
                    {
                        field_type,
                        label,
                        value,
                        keyToUseInPayload,
                        mainObjIndex,
                        idx,
                    },
                ];
            }
            const existingIndex = prev.findIndex(
                (item) => item.label === label && item.idx === idx
            );
            if (existingIndex !== -1) {
                return prev.filter((_, i) => i !== existingIndex);
            } else {
                return [
                    ...prev,
                    {
                        field_type,
                        label,
                        value,
                        keyToUseInPayload,
                        mainObjIndex,
                        idx,
                    },
                ];
            }
        });
    };

    const handleSubmitClick = () => {
        form.validateFields().then((values) => {
            if (values) {
                submitForm(values)
            }
        }).catch((error) => {
            console.log('error', error)
        })
    }

    const submitForm = (values) => {
        if (data) {

            const keysFromData = data.map((obj) => {
                if (["file", "multiple_options"].includes(obj.field_type)) {
                    return obj.keyToUseInPayload
                }
                return null
            }).filter((item) => item !== null)

            if (keysFromData && keysFromData.length > 0) {
                keysFromData.forEach(element => {
                    delete values[element]
                });
            }
            const keyArrayFromValues = values ? Object.keys(values) : null

            if (keyArrayFromValues) {
                const form_fields = keyArrayFromValues.reduce((acc, key) => {
                    const dataType = data.find((iteam) => iteam.keyToUseInPayload === key)
                    acc[key] = { value: (dataType && dataType.field_type && dataType.field_type === 'number') ? parseFloat(values[key]) : values[key] }; // Format the data as key: { value: ... }
                    return acc;
                }, {});

                if (checkboxData && checkboxData.length > 0) {
                    const formattedCheckBoxData = checkboxData.reduce((acc, item) => {
                        // Check if there's an existing entry with the same label and mainObjIndex
                        const existing = acc.find((entry) => entry.keyToUseInPayload === item.keyToUseInPayload && entry.mainObjIndex === item.mainObjIndex);

                        if (existing) {
                            // If the entry exists, update the value array
                            existing.value = [...existing.value, item.value];  // Add the item value to the array (if you want to collect multiple values)
                        } else {
                            // If the entry does not exist, add a new entry
                            acc.push({
                                field_type: item.field_type,
                                label: item.label,
                                keyToUseInPayload: item.keyToUseInPayload,
                                value: [item.value], // Store value as an array (if you want to collect multiple values for the same label)
                                mainObjIndex: item.mainObjIndex,
                                idx: item.idx,
                            });
                        }

                        return acc; // Return the accumulator to continue reducing
                    }, []); // Initialize with an empty array

                    if (formattedCheckBoxData && formattedCheckBoxData.length > 0) {
                        formattedCheckBoxData.forEach((dataObj) => {
                            form_fields[dataObj.keyToUseInPayload] = { value: dataObj.value }
                        })
                    }
                }

                const formData = new FormData();
                if (form_fields) {
                    formData.append("form_fields", JSON.stringify(form_fields));
                }
                if (attachmentFileList && attachmentFileList.length > 0) {
                    const formattedAttachmentData = attachmentFileList.reduce((acc, item) => {
                        const existing = acc.find((entry) => entry.keyToUseInPayload === item.keyToUseInPayload && entry.mainObjIndex === item.mainObjIndex);

                        if (existing) {
                            existing.fileData = [...existing.fileData, item.fileData];
                        } else {
                            acc.push({
                                label: item.label,
                                keyToUseInPayload: item.keyToUseInPayload,
                                fileData: [item.fileData],
                                mainObjIndex: item.mainObjIndex,
                            });
                        }
                        return acc;
                    }, []);

                    if (formattedAttachmentData && formattedAttachmentData.length > 0) {
                        formattedAttachmentData.forEach((attachementObj) => {
                            attachementObj.fileData.forEach((file) => {
                                formData.append(attachementObj.keyToUseInPayload, file); // Append individual file
                            });
                        });
                    }
                }
                const currentDomain = window.location.hostname;
                var endpoint = '';
                if (currentDomain === 'dev.stell.one') {
                    endpoint = "https://dev-backend.stell.one/api/consultants/forms/";
                }
                else if (currentDomain === 'stell.one') {
                    endpoint = "https://prod-backend.stell.one/api/consultants/forms/";
                }
                else if (currentDomain === 'staging.stell.one') {
                    endpoint = "https://backend.stell.one/api/consultants/forms/";
                }
                else if (currentDomain === 'localhost' || currentDomain === '127.0.0.1' || currentDomain === '0.0.0.0') {
                    endpoint = "http://localhost:8000/api/consultants/forms/";
                    // endpoint = "https://prod-backend.stell.one/api/consultants/on-boarding/";

                } else {
                    endpoint = "https://backend.stell.one/api/consultants/forms/";
                }
                dispatch(fetchStart());
                setIsLoading(true)
                const apiEndpoint = `${endpoint}${token ? token + '/' : ''}`
                axios.post(apiEndpoint, formData)
                    .then((response) => {
                        dispatch(fetchSuccess());
                        setIsLoading(false)
                        setActionTaken(true)
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        message.error('Error !');
                        console.log('error', error);
                    });
            }
        }


    }

    return (<>
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            margin: "0",
            textAlign: "center"
        }}>
            {!actionTaken ? data ? (
                <Card
                    title={title ? title : 'Additional Info'}
                    style={{ minWidth: "600px" }}
                >
                    <Form
                        form={form}
                    >
                        {data.map((field, index) => (
                            <Form.Item
                                key={index}
                                label={field.label ? Capitalize(field.label) : 'N/A'}
                                name={field.keyToUseInPayload}
                                rules={[
                                    field.field_type === 'email' &&
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email address',
                                    },
                                    ({
                                        validator(_, value) {
                                            if (value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('This field is required !'));
                                        },
                                    })
                                ]}
                                style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {(() => {
                                        switch (field.field_type) {
                                            case "text":
                                                return <Input style={{ width: "100%" }} placeholder={`Enter ${field.label}`} />;
                                            case "number":
                                                return <InputNumber placeholder={`Enter ${field.label}`} style={{ width: "100%" }} />;
                                            case "email":
                                                return <Input style={{ width: "100%" }} type="email" placeholder={`Enter ${field.label}`} />;
                                            case "radio_options":
                                                return (
                                                    <Radio.Group>
                                                        {field.options.map((option, idx) => (
                                                            <Radio key={idx} value={option} style={{ marginRight: "10px" }}>
                                                                {option}
                                                            </Radio>
                                                        ))}
                                                    </Radio.Group>
                                                );
                                            case "multiple_options":
                                                return (
                                                    <Checkbox.Group  >
                                                        {field.options.map((option, idx) => (
                                                            <Checkbox onClick={() => handleCheckBoxClick(option, idx, field.field_type, field.label, index, field.keyToUseInPayload)} key={idx} value={option} style={{ marginRight: "10px" }}>
                                                                {option}
                                                            </Checkbox>
                                                        ))}
                                                    </Checkbox.Group>
                                                );
                                            case "file":
                                                return <Upload
                                                    key={index}
                                                    fileList={attachmentFileList.map((file) => ({
                                                        uid: file.fileData.uid, // Ensure each file has a unique ID
                                                        name: file.fileData.name, // Name of the file
                                                        status: "done", // You can use "done", "uploading", or "error" to indicate file status
                                                    }))}
                                                    beforeUpload={(file, fileList) => {
                                                        setAttachmentFileList((prevFileList) => [
                                                            ...prevFileList,
                                                            { label: field.label, fileData: file, mainObjIndex: index, keyToUseInPayload: field.keyToUseInPayload },
                                                        ]);
                                                        return false;
                                                    }}
                                                    multiple
                                                    onRemove={(file) => {
                                                        // Remove the file using its UID
                                                        setAttachmentFileList((prevFileList) =>
                                                            prevFileList.filter((f) => f.fileData.uid !== file.uid)
                                                        );
                                                    }}
                                                >
                                                    <Button icon={<UploadOutlined />}>Select Files</Button>
                                                </Upload>
                                            default:
                                                return null;
                                        }
                                    })()}
                                </div>
                            </Form.Item>
                        ))}
                        <Button type='primary' style={{ margin: 0 }} onClick={handleSubmitClick} >
                            Submit
                        </Button>
                    </Form>
                </Card>
            ) : isLoading ? (
                <div style={{ margin: 'auto', textAlign: "center" }}>Please wait...</div>
            ) : (
                <div style={{ margin: 'auto', textAlign: "center" }}>Not found...</div>
            ) : 'Thanks...'}
        </div>
    </>
    )
}

export default AdditionalInformation