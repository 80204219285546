import React from "react";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "../../appRedux/actions/Auth";
import {getUser} from "../../appRedux/actions/Users";
import {useNavigate} from "react-router-dom"

const UserProfile = ({user}) => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const navigate = useNavigate();
  const name = user.name;
  let photo = user.photo;
  let displayName = user.first_name;
  if(!photo){
    photo = "https://via.placeholder.com/90x18"
  }

  if(displayName === ''){
      displayName = name
  }


  const getMyProfile = () => {
        dispatch(getUser());
        let path = "/".concat(user.username.concat("/profile/view"));
        navigate(path)
        // history.push(path);
    };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={getMyProfile}>My Profile</li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions}>
        <Avatar src={photo} className="gx-size-35 gx-pointer gx-mr-2" alt=""/>
        <span className="gx-avatar-name">{displayName}<i
          className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
    </div>

  )
};

export default UserProfile;
