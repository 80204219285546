import React, {useEffect, useState} from "react";
import { Input, Form, DatePicker, Divider, Col, Row, Upload, message, Select } from "antd";
import InboxOutlined from "@ant-design/icons/lib/icons/InboxOutlined";

function AttachmentPage({ formDataBackground, setFormDataBackground, form }) {
    useEffect(()=>{
        console.log('formdata3page', formDataBackground)
    },[])
    const { Dragger } = Upload;
    const {Option} = Select;
    // const [form] = Form.useForm();
   
    const [fileList, setFileList] = useState([]);
    const acceptableFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'pdf'];
    const filterFilesByType = (fileList) => {
      return fileList.map(file => {
          const fileExtension = file.name.split('.').pop().toLowerCase();
          const isAcceptableFileType = acceptableFileTypes.includes(fileExtension);
  
          if (!isAcceptableFileType) {
              message.error(`Unsupported file type: ${file.name}`);
              return null;
          }
  
          return file;
      }).filter(Boolean); 
  };

    const handleFileChange = (info) => {
        let updatedFileList = [...info.fileList];

        updatedFileList = updatedFileList.filter((file) => {
            if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
            return file.status !== 'error';
        });
        updatedFileList = filterFilesByType(updatedFileList);
        setFileList(updatedFileList);
    };
    const handleBeforeUpload = (file) => {
        return false;
    };
     
   const [fileListBachelorsDegree, setFileListBachelorsDegree] = useState([]);
    const [fileListBachelorsLastMarksheet, setFileListBachelorsLastMarksheet] = useState([]);
    const [fileListTranscript, setFileListTranscript] = useState([]);
    const [fileListUSEquivalent, setFileListUSEquivalent] = useState([]);
    const [fileListMastersDegree, setFileListMastersDegree] = useState([]);
    const [fileListMastersLastMarksheet, setFileListMastersLastMarksheet] = useState([]);
   

    useEffect(()=>{
        if(formDataBackground && formDataBackground.bachelors_degree && formDataBackground.bachelors_degree.fileList){
            setFileListBachelorsDegree(formDataBackground.bachelors_degree.fileList )
        }
        if(formDataBackground && formDataBackground.bachelors_last_marksheet && formDataBackground.bachelors_last_marksheet.fileList){
            setFileListBachelorsLastMarksheet(formDataBackground.bachelors_last_marksheet.fileList )
        }
        if(formDataBackground && formDataBackground.transcript && formDataBackground.transcript.fileList){
            setFileListTranscript(formDataBackground.transcript.fileList )
        }
        if(formDataBackground && formDataBackground.us_equivalent && formDataBackground.us_equivalent.fileList){
            setFileListUSEquivalent(formDataBackground.us_equivalent.fileList )
        }
        if(formDataBackground && formDataBackground.masters_degree && formDataBackground.masters_degree.fileList){
            setFileListMastersDegree(formDataBackground.masters_degree.fileList )
        }
        if(formDataBackground && formDataBackground.masters_last_marksheet && formDataBackground.masters_last_marksheet.fileList){
            setFileListMastersLastMarksheet(formDataBackground.masters_last_marksheet.fileList )
        }
    },[])
   
    const handleFileChangeBachelorsDegree = (info) => {
        let updatedFileList = [...info.fileList];
        updatedFileList = updatedFileList.filter((file) => {
            if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
            return file.status !== 'error';
        });
        updatedFileList = filterFilesByType(updatedFileList);
        setFileListBachelorsDegree(updatedFileList);
    };

     const handleFileChangeBachelorsLastMarkSheet = (info) => {
        let updatedFileList = [...info.fileList];
        updatedFileList = updatedFileList.filter((file) => {
            if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
            return file.status !== 'error';
        });
        updatedFileList = filterFilesByType(updatedFileList);
        setFileListBachelorsLastMarksheet(updatedFileList);
    };
      const handleFileChangeTranscript = (info) => {
        let updatedFileList = [...info.fileList];
        updatedFileList = updatedFileList.filter((file) => {
            if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
            return file.status !== 'error';
        });
        updatedFileList = filterFilesByType(updatedFileList);
        setFileListTranscript(updatedFileList);
    };
      const handleFileChangeUSEquivalent = (info) => {
        let updatedFileList = [...info.fileList];
        updatedFileList = updatedFileList.filter((file) => {
            if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
            return file.status !== 'error';
        });
        updatedFileList = filterFilesByType(updatedFileList);
        setFileListUSEquivalent(updatedFileList);
    };
     const handleFileChangeMastersDegree = (info) => {
        let updatedFileList = [...info.fileList];
        updatedFileList = updatedFileList.filter((file) => {
            if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
            return file.status !== 'error';
        });
        updatedFileList = filterFilesByType(updatedFileList);
        setFileListMastersDegree(updatedFileList);
    };

     const handleFileChangeMastersLastMarkSheet = (info) => {
        let updatedFileList = [...info.fileList];
        updatedFileList = updatedFileList.filter((file) => {
            if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
            return file.status !== 'error';
        });
        updatedFileList = filterFilesByType(updatedFileList);
        setFileListMastersLastMarksheet(updatedFileList);
    };
     


    const uploadProps = {
        multiple: false,
        fileList,
        beforeUpload: handleBeforeUpload,
        onChange: handleFileChange,
    };
  return (
    <div>
        <Form
          layout="inline"
          form={form}
          initialValues={formDataBackground}
          onValuesChange={(changedValues, allValues) => {
            if(changedValues.ifMaster === 'no'){
                delete allValues.masters_degree
                delete allValues.masters_last_marksheet
                setFileListMastersDegree([])
                setFileListMastersLastMarksheet([])
                form.setFieldsValue({
                    'masters_degree': undefined,
                    'masters_last_marksheet': undefined,
                });
                // ['masters_degree']: undefined,
                // ['masters_last_marksheet']: undefined,
            }
            setFormDataBackground({ ...formDataBackground, ...allValues });
            }}
          className="gx-modal-box-form-item">
            <div style={{width:"100%",textAlign:"center",fontWeight:"bold",fontSize:"15px"}}>Bachelor's Details</div>
            <Divider/>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Bachelor's Degree" name={'bachelors_degree'} rules={[{ required: true, message: "Please upload bachelor's degree!" }]}> 
                        <Dragger {...uploadProps} fileList={fileListBachelorsDegree} onChange={handleFileChangeBachelorsDegree}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Last Year Mark Sheet" name={'bachelors_last_marksheet'} rules={[{ required: true, message: "Please upload Bachelor's marksheet!" }]}> 
                        <Dragger {...uploadProps} fileList={fileListBachelorsLastMarksheet} onChange={handleFileChangeBachelorsLastMarkSheet}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Upload Transcript" name={'transcript'}  rules={[{ required: true, message: "Please upload Transcript marksheet!" }]}> 
                        <Dragger {...uploadProps} fileList={fileListTranscript} onChange={handleFileChangeTranscript} rules={[{ required: true, message: "Please upload Transcript!" }]}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                    </Form.Item>
                    </Col>
                     <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Upload US Equivalent" name={'us_equivalent'} rules={[{ required: true, message: "Please upload US Equivalent!" }]}> 
                        <Dragger {...uploadProps} fileList={fileListUSEquivalent} onChange={handleFileChangeUSEquivalent}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                    </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="Have you done Master's?"
                        name="ifMaster"
                        rules={[{ required: true, message: 'Please select an option!' }]}
                        >
                        <Select placeholder="Select an option">
                            <Option value="yes">Yes</Option>
                            <Option value="no">No</Option>
                        </Select>
                    </Form.Item>
                    </Col>
                    {form.getFieldValue('ifMaster') === 'yes' && (
                   <div style={{width: '100%'}}>
                     <Divider/>
                     <div style={{width:"100%",textAlign:"center",fontWeight:"bold",fontSize:"15px"}}>Master's Details</div>
                    
                     <Divider/>
                     <Row>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item label="Master's Degree" name={'masters_degree'} rules={[{ required: true, message: "Please upload master's degree!" }]}> 
                                <Dragger {...uploadProps} fileList={fileListMastersDegree} onChange={handleFileChangeMastersDegree}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item label="Last Year Mark Sheet" name={'masters_last_marksheet'} rules={[{ required: true, message: "Please upload master's last marksheet!" }]}> 
                                <Dragger {...uploadProps} fileList={fileListMastersLastMarksheet} onChange={handleFileChangeMastersLastMarkSheet}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                    </Row>
                   </div>
                )}


            <Divider />
            <Col span={2}>
                          
            </Col>
        </Form>
    </div>
  );
}

export default AttachmentPage;
