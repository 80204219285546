import React from "react";
// import {Redirect, Switch} from "react-router";
import {Navigate, Redirect, Route, Switch, useParams} from "react-router";
import {Routes} from 'react-router-dom';
import {PropsRoute} from "../../util/routeRestriction";
import asyncComponent from "../../util/asyncComponent";

// const AsyncProjectCoordinatorSupportPerson = asyncComponent(() => import(''));
const AsyncAccountReceivable = asyncComponent(() => import('./accountReceivable/index'));
const AsyncAccountConsultant = asyncComponent(() => import('./consultant'));
const AsyncAccountConsultantView = asyncComponent(() => import('./consultant/edit'));
const AsyncAccountSupport = asyncComponent(() => import('./support'));
const AsyncAccountRunPayrollW2 = asyncComponent(() => import('../../components/Accounts/payroll/RunW2Payroll'));
const AsyncAccountRunPayroll1099 = asyncComponent(() => import('../../components/Accounts/payroll/Run1099Payroll'));
const AsyncAccountRunGlobal1099 = asyncComponent(() => import('../../components/Accounts/payroll/RunGlobalPayroll'));
const AsyncAccountTransactions = asyncComponent(() => import('./transactions'));
const AsyncTimeSheet = asyncComponent(() => import('../engineer/timeSheet/index'));
const AsyncPayroll = asyncComponent(() => import('../../components/Accounts/payroll/index'));
const AsyncAccountsPersonList = asyncComponent(() => import('../accounts/accountsPersons/index'));
const AsyncAccountsPersonEdit = asyncComponent(() => import('../accounts/accountsPersons/edit'));
const AsyncReimbursement = asyncComponent(() => import('../../routes/consultant/reimbursement/index'));

const Accounts = (props) => {
  const { username } = useParams();
  // <Switch>
  return<Routes>
    {/* <Redirect exact from={`${props.match.url}/accounts`} to={`${props.match.url}`}/> */}
    <Route path={`${username}/accounts`} element={<Navigate to={`${username}`} />} />
    {/* <Route path={`${props.match.url}/accounts`} element={<Navigate to={`${props.match.url}`} />} /> */}
    <Route path="account-person/edit/:accountPersonId" element={
       <PropsRoute path={`/${username}accounts/account-person/edit/:accountPersonId`} dispatch={props.dispatch}
       element={<AsyncAccountsPersonEdit/>} user={props.user}/>
    }></Route>
        {/* <PropsRoute path={`${props.match.url}/account-person/edit/:accountPersonId`} 
          dispatch={props.dispatch} component={AsyncAccountsPersonEdit} user={props.user}/> */}
          <Route path="account-person" element={
            <PropsRoute path={`/${username}accounts/account-person`} dispatch={props.dispatch}
            element={<AsyncAccountsPersonList/>} user={props.user}/>
          }></Route>
        {/* <PropsRoute path={`${props.match.url}/account-person`} dispatch={props.dispatch}
          component={AsyncAccountsPersonList} user={props.user}/> */}
          <Route path="consultant/view/:consultantSlug" element={
             <PropsRoute path={`/${username}accounts/consultant/view/:consultantSlug`} dispatch={props.dispatch}
             element={<AsyncAccountConsultantView/>} user={props.user}/>
          }></Route>
        {/* <PropsRoute path={`${props.match.url}/consultant/view/:consultantSlug`} dispatch={props.dispatch}
          component={AsyncAccountConsultantView} user={props.user}/> */}
          <Route path="account-receivable/list" element={
             <PropsRoute path={`/${username}accounts/account-receivable/list`} dispatch={props.dispatch}
             element={<AsyncAccountReceivable/>} user={props.user}/>
          }></Route>
        {/* <PropsRoute path={`${props.match.url}/account-receivable/list`} dispatch={props.dispatch}
          component={AsyncAccountReceivable} user={props.user}/> */}
          <Route path="consultant/list" element={
            <PropsRoute path={`${username}accounts/consultant/list`} dispatch={props.dispatch}
            element={<AsyncAccountConsultant/>} user={props.user}/>
          }
          ></Route>
        {/* <PropsRoute path={`${props.match.url}/consultant/list`} dispatch={props.dispatch}
          component={AsyncAccountConsultant} user={props.user}/> */}
          <Route path="support_person" element={
            <PropsRoute path={`${username}accounts/support_person`} dispatch={props.dispatch}
            element={<AsyncAccountSupport/>} user={props.user}/>
          }></Route>
        {/* <PropsRoute path={`${props.match.url}/support_person`} dispatch={props.dispatch}
          component={AsyncAccountSupport} user={props.user}/> */}
          <Route path="run_payroll/w2" element={
            <PropsRoute path={`${username}accounts/run_payroll/w2`} dispatch={props.dispatch}
            element={<AsyncAccountRunPayrollW2/>} user={props.user}/>
          }></Route>
        {/* <PropsRoute path={`${props.match.url}/run_payroll/w2`} dispatch={props.dispatch}
          component={AsyncAccountRunPayrollW2} user={props.user}/> */}
          <Route path="run_payroll/1099" element={
            <PropsRoute path={`${username}accounts/run_payroll/1099`} dispatch={props.dispatch}
            element={<AsyncAccountRunPayroll1099/>} user={props.user}/>
          }></Route>
          <Route path="run_payroll/global_payroll" element={
            <PropsRoute path={`${username}accounts/run_payroll/global_payroll`} dispatch={props.dispatch}
            element={<AsyncAccountRunGlobal1099/>} user={props.user}/>
          }></Route>
        {/* <PropsRoute path={`${props.match.url}/run_payroll/1099`} dispatch={props.dispatch}
          component={AsyncAccountRunPayroll1099} user={props.user}/> */}
          <Route path="transaction" element={
            <PropsRoute path={`${username}accounts/transaction`} dispatch={props.dispatch}
            element={<AsyncAccountTransactions/>} user={props.user}/>
          }></Route>
        {/* <PropsRoute path={`${props.match.url}/transaction`} dispatch={props.dispatch}
          component={AsyncAccountTransactions} user={props.user}/> */}
          <Route path="timesheet/:offerId" element={
             <PropsRoute path={`${username}accounts/timesheet/:offerId`} 
             dispatch={props.dispatch} element={<AsyncTimeSheet/>} user={props.user}/>
          }></Route>
        {/* <PropsRoute path={`${props.match.url}/timesheet/:offerId`} 
          dispatch={props.dispatch} component={AsyncTimeSheet} user={props.user}/> */}
          <Route path="support/payroll/:consultantId" element={
            <PropsRoute path={`${username}accounts/support/payroll/:consultantId`} 
            dispatch={props.dispatch} element={<AsyncPayroll/>} user={props.user}/>
          }></Route>
        {/* <PropsRoute path={`${props.match.url}/support/payroll/:consultantId`} 
          dispatch={props.dispatch} component={AsyncPayroll} user={props.user}/> */}
          <Route path="consultant/payroll/:consultantId" element={
             <PropsRoute path={`${username}accounts/consultant/payroll/:consultantId`} 
             dispatch={props.dispatch} element={<AsyncPayroll/>} user={props.user}/>
          }></Route>
        {/* <PropsRoute path={`${props.match.url}/consultant/payroll/:consultantId`} 
          dispatch={props.dispatch} component={AsyncPayroll} user={props.user}/> */}
          <Route path="reimbursement" element={
             <PropsRoute path={`${username}accounts/reimbursement`} 
             dispatch={props.dispatch} element={<AsyncReimbursement/>} user={props.user}/>
          }></Route>
        {/* <PropsRoute path={`${props.match.url}/reimbursement`} 
          dispatch={props.dispatch} component={AsyncReimbursement} user={props.user}/> */}
        {/* <PropsRoute path={`${props.match.url}/timesheet/:offerId`} 
          dispatch={props.dispatch} component={AsyncTimeSheet} user={props.user}/> */}
  </Routes>
  // </Switch>
        };

export default Accounts;
