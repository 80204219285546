import React from "react";
import {Navigate, Redirect, Route, Switch, useParams} from "react-router";
import {Routes} from "react-router-dom";
import {PropsRoute} from "../../util/routeRestriction";
import asyncComponent from "../../util/asyncComponent";

// const AsyncProjectCoordinatorSupportPerson = asyncComponent(() => import(''));
const AsyncEngieersList = asyncComponent(() => import('./engineers/index'));
const AsyncEngieerEdit = asyncComponent(() => import('./engineers/edit'));
const AsyncTimeSheet = asyncComponent(() => import('./timeSheet/index'));

const Engineer = (props) => {
  const { username } = useParams();

  return<Routes>
    {/* <Redirect exact from={`${props.match.url}/engineering`} to={`${props.match.url}`}/> */}
    <Route path={`${username}/engineering`} element={<Navigate to={`${username}`} />} />
      {/* <PropsRoute path={`${props.match.url}/support_person`} dispatch={props.dispatch}
        component={AsyncProjectCoordinatorSupportPerson} user={props.user}/> */}
      <Route path="engineers/list" element={
        <PropsRoute path={`/${username}engineering/engineers/list`} dispatch={props.dispatch}
          element={<AsyncEngieersList/>} user={props.user}/>}>
      </Route>
      <Route path="engineers/edit/:engineerId" element={
        <PropsRoute path={`/${username}engineering/engineers/edit/:engineerId`} 
          dispatch={props.dispatch} element={<AsyncEngieerEdit/>} user={props.user}/>}>
      </Route>
      <Route path="timesheet/:offerId" element={
        <PropsRoute path={`/${username}engineering/timesheet/:offerId`} 
          dispatch={props.dispatch} element={<AsyncTimeSheet/>} user={props.user}/>}>
      </Route>
  </Routes>
};

export default Engineer;


// const Engineer = (props) => (
//   <Routes>
//     {/* <Redirect exact from={`${props.match.url}/engineering`} to={`${props.match.url}`}/> */}
//     <Route path={`${props.match.url}/engineering`} element={<Navigate to={`${props.match.url}`} />} />
//         {/* <PropsRoute path={`${props.match.url}/support_person`} dispatch={props.dispatch}
//           component={AsyncProjectCoordinatorSupportPerson} user={props.user}/> */}
//         <PropsRoute path={`${props.match.url}/engineers/list`} dispatch={props.dispatch}
//           component={AsyncEngieersList} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/engineers/edit/:engineerId`} 
//           dispatch={props.dispatch} component={AsyncEngieerEdit} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/timesheet/:offerId`} 
//           dispatch={props.dispatch} component={AsyncTimeSheet} user={props.user}/>

//   </Routes>
// );

// export default Engineer;
