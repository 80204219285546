import LogRocket from 'logrocket';
import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose, createStore } from 'redux';
import {thunk} from 'redux-thunk';
import rootSaga from '../sagas/index';
import createRootReducer from '../reducers';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk, sagaMiddleware, LogRocket.reduxMiddleware()];

const resetEnhancer = rootReducer => (state, action) => {
  if (action.type === 'SIGNOUT_USER_SUCCESS') {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(preloadedState) {
  const store = createStore(
    resetEnhancer(createRootReducer()), // root reducer without history
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);
  return store;
}


// import LogRocket from 'logrocket';
// import createSagaMiddleware from "redux-saga";
// import {applyMiddleware, compose, createStore} from 'redux'
// import {routerMiddleware} from 'connected-react-router'
// import {thunk} from 'redux-thunk';
// import rootSaga from "../sagas/index";
// import createRootReducer from '../reducers'

// const createBrowserHistory = require('history').createBrowserHistory;


// export const history = createBrowserHistory();

// const routeMiddleware = routerMiddleware(history);
// const sagaMiddleware = createSagaMiddleware();

// const middlewares = [thunk,sagaMiddleware, routeMiddleware];

// const resetEnhancer = rootReducer => (state, action) => {
//   if (action.type === 'SIGNOUT_USER_SUCCESS') {
//     return rootReducer(undefined, action)
//   }
//   return rootReducer(state, action)
// };
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export default function configureStore(preloadedState) {
//   const store = createStore(
//     resetEnhancer(createRootReducer(history)), // root reducer with router state
//     preloadedState,
//     composeEnhancers(
//       applyMiddleware(
//         routerMiddleware(history), // for dispatching history actions
//         LogRocket.reduxMiddleware(),
//         ...middlewares
//       ),
//     ),
//   );

//   sagaMiddleware.run(rootSaga);
//   return store;
// }
