import React from "react";
import {Navigate, Redirect, Route, Switch, useParams} from "react-router";
import {Routes} from "react-router-dom";
import {PropsRoute} from "../../util/routeRestriction";
import asyncComponent from "../../util/asyncComponent";

const AsyncProjectCoordinatorEdit = asyncComponent(() => import('./projectCoordinators/edit'));
const AsyncProjectCoordinators = asyncComponent(() => import('./projectCoordinators/index'));
const AsyncProjectCoordinatorSupportPerson = asyncComponent(() => import('./supportPerson/index'));
const AsyncTimeSheet = asyncComponent(() => import('../engineer/timeSheet/index'));
const AsyncAccountDetails = asyncComponent(() => import('../consultant//accountDetails/index'));
const AsyncSupportPayroll = asyncComponent(() => import('../../components/ProjectCoordinator/supportPayroll/index'));

const ProjectCoordinator = (props) => {
const { username } = useParams();
  return <Routes>
    {/* <Redirect exact from={`${props.match.url}/project_coordination`} to={`${props.match.url}`}/> */}
    <Route path={`${username}/project_coordination`} element={<Navigate to={`${username}`} />} />
    <Route path="support_person" element={
      <PropsRoute path={`${username}/project_coordination/support_person`} dispatch={props.dispatch}
      element={<AsyncProjectCoordinatorSupportPerson/>} user={props.user}/>
    }></Route>
    <Route path="project_coordinators/edit/:project_coordinatorsId" element={
      <PropsRoute path={`${username}/project_coordinators/edit/:project_coordinatorsId`} 
        dispatch={props.dispatch} element={<AsyncProjectCoordinatorEdit/>} user={props.user}/>
    }></Route>
    <Route path="project_coordinators" element={
      <PropsRoute path={`${username}/project_coordination/project_coordinators`} dispatch={props.dispatch}
      element={<AsyncProjectCoordinators/>} user={props.user}/>
    }></Route>
    <Route path="account-details" element={
      <PropsRoute path={`${username}/project_coordination/account-details`} 
        dispatch={props.dispatch} element={<AsyncAccountDetails/>} user={props.user}/>
    }></Route>
    <Route path="support_payroll/:supportId" element={
      <PropsRoute path={`${username}/project_coordination/support_payroll/:supportId`} 
          dispatch={props.dispatch} element={<AsyncSupportPayroll/>} user={props.user}/>
    }></Route>
      {/* <PropsRoute path={`${props.match.url}/timesheet/:offerId`} 
        dispatch={props.dispatch} component={AsyncTimeSheet} user={props.user}/> */}
  </Routes>
}

export default ProjectCoordinator;

// const ProjectCoordinator = (props) => (
//   <Routes>
//     {/* <Redirect exact from={`${props.match.url}/project_coordination`} to={`${props.match.url}`}/> */}
//     <Route path={`${props.match.url}/project_coordination`} element={<Navigate to={`${props.match.url}`} />} />
//       <PropsRoute path={`${props.match.url}/support_person`} dispatch={props.dispatch}
//         component={AsyncProjectCoordinatorSupportPerson} user={props.user}/>
//       <PropsRoute path={`${props.match.url}/project_coordinators/edit/:project_coordinatorsId`} 
//         dispatch={props.dispatch} component={AsyncProjectCoordinatorEdit} user={props.user}/>
//       <PropsRoute path={`${props.match.url}/project_coordinators`} dispatch={props.dispatch}
//         component={AsyncProjectCoordinators} user={props.user}/>
//       <PropsRoute path={`${props.match.url}/account-details`} 
//         dispatch={props.dispatch} component={AsyncAccountDetails} user={props.user}/>
//       <PropsRoute path={`${props.match.url}/support_payroll/:supportId`} 
//           dispatch={props.dispatch} component={AsyncSupportPayroll} user={props.user}/>
//       {/* <PropsRoute path={`${props.match.url}/timesheet/:offerId`} 
//         dispatch={props.dispatch} component={AsyncTimeSheet} user={props.user}/> */}
//   </Routes>
// );

// export default ProjectCoordinator;
