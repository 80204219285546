import React, { useEffect, useRef } from 'react';
import confetti from 'canvas-confetti';

const ConfettiPage = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const myCanvas = canvasRef.current;
    const confettiInstance = confetti.create(myCanvas, { resize: true });

    // Launch confetti
    confettiInstance({
      particleCount: 1500,
      spread: 1200,
      origin: { x: 0.5, y: 0.5 }, // Center of the screen
      startVelocity: 20,
    });

    return () => {
      // Clean up
      confettiInstance.reset();
    };
  }, []);

  return (
    <>
      <canvas
        ref={canvasRef}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 9327900,
          pointerEvents: 'none',
        }}
      />
    </>
  );
};

export default ConfettiPage


// import React from 'react'

// import Confetti from 'react-confetti';
// const ConfettiPage = () => {
//   return (
//     <>
//         <Confetti
//           style={{position:"fixed", top:0, left:0, width:"100vw",height:'100vh', zIndex:9327900}}
//           width={window.outerWidth}
//           height={window.outerHeight}
//           recycle={true}
//           numberOfPieces={500}
//           initialVelocityX={5}
//           initialVelocityY={10}
//         />
//     </>
//   )
// }

// export default ConfettiPage