import React from "react";
import {Layout} from "antd";

import Sidebar from "../Sidebar/index";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";

import Topbar from "../Topbar/index";
import {footerText} from "../../util/config";
import App from "../../routes/index";
import {useSelector} from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
// import {useRouteMatch} from "react-router";
import { useMatch } from 'react-router-dom';
import InfoView from "../../components/Extras/InfoView";
import ConfettiPage from "../../components/Extras/ConfettiPage";

const {Content, Footer} = Layout;

const MainApp = (props) => {

  const {width, navStyle} = useSelector(({settings}) => settings);
  const {confettiVisible} = useSelector(({common})=>common);
  // const match = useRouteMatch();
  const match = useMatch('/*');
  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return '';
    }
  };
  const getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL :
        return <HorizontalDefault/>;
      case NAV_STYLE_DARK_HORIZONTAL :
        return <HorizontalDark/>;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL :
        return <InsideHeader/>;
      case NAV_STYLE_ABOVE_HEADER :
        return <AboveHeader/>;
      case NAV_STYLE_BELOW_HEADER :
        return <BelowHeader/>;
      case NAV_STYLE_FIXED :
        return <Topbar user={props.user} location={props.location}/>;
      case NAV_STYLE_DRAWER :
        return <Topbar user={props.user} location={props.location}/>;
      case NAV_STYLE_MINI_SIDEBAR :
        return <Topbar user={props.user} location={props.location}/>;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR :
        return <NoHeaderNotification/>;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR :
        return <NoHeaderNotification/>;
      default :
        return null;
    }
  };

  const getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar user={props.user}/>;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED :
        return <Sidebar user={props.user}/>;
      case NAV_STYLE_DRAWER :
        return <Sidebar user={props.user}/>;
      case NAV_STYLE_MINI_SIDEBAR :
        return <Sidebar user={props.user}/>;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR :
        return <Sidebar user={props.user}/>;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar user={props.user}/>;
      default :
        return null;
    }
  };

  return (
    <Layout className="gx-app-layout">
      {confettiVisible && <ConfettiPage/> }
      {getSidebar(navStyle, width)}
      <Layout>
        {getNavStyles(navStyle)}
        <Content className={`gx-layout-content ${getContainerClass(navStyle)} `}>
          <InfoView/>
          <App match={match} user={props.user} location={props.location} dispatch={props.dispatch}/>
          {/* <Footer> */}
          <Footer style={
            // props.location && props.location && props.location.pathname && 
            // (props.location.pathname.includes('accounts/consultant/payroll')||
            //   props.location.pathname.includes('accounts/run_payroll/w2') ||
            //   props.location.pathname.includes('accounts/run_payroll/1099') ||
            //   props.location.pathname.includes('accounts/run_payroll/global_payroll')
            // ) ?
              {position:'fixed', bottom:0, display:"flex" , width:"100%", marginLeft: "-15px" } 
              // : 
              // {}
            }
          >
            <div className="gx-layout-footer-content">
              {footerText}
            </div>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  )
};
export default MainApp;

