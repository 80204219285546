import en_US from "antd/lib/locale/en_US";
import saMessages from "../locales/ar_SA.json";

const saLang = {
  messages: {
    ...saMessages
  },
  antd: en_US,
  locale: 'ar'
};
export default saLang;
