import React from "react";


import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR, NAV_STYLE_NO_HEADER_MINI_SIDEBAR,} from "../../constants/ThemeSetting";
import {useSelector} from "react-redux";
import {hasRole} from "../../appRedux/actions/Auth";

import {ACCOUNTS, ADMIN, CONSULTANT, CONTRACT, ENGINEER, HUMAN_RESOURCE, MARKETER, PROJECT_COORDINATION, RECRUITER, SCREENER, TIMESHEETS} from "../../constants/Roles";
import RecruiterSidebar from "./RecruiterSidebar";
import MarketerSidebar from "./MarketerSidebar";
import AdminSidebar from "./AdminSidebar";
import CommonSidebar from "./CommonSidebar";
import ProjectCoordinatorSidebar from "./ProjectCoordinatorSideBar";
import EngineerSidebar from "./EngineerSidebar";
import HumanResourceSideBar from "./HumanResourceSidebar";
import ContractSidebar from "./ContractSidebar";
import ConsltantSidebar from "./ConsultantSidebar";
import AccountSidebar from "./AccountSideBar";
import TimesheetsSidebar from "./TimesheetsSidebar";


const SidebarContent = ({user}) => {

  let {navStyle, themeType, pathname} = useSelector(({settings}) => settings);
  // if(!user) {
  //   user = sessionStorage.getItem('user')
  // }

  // const user = useSelector(({auth}) => auth.authUser);
  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];



  return (
    <>
      <SidebarLogo user={user}/>
      <div className="gx-sidebar-content" >
        {/* <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}` }>
          <UserProfile user={user}/> */}
          {/* <AppsNavigation/> */}
        {/* </div> */}
          
        <CustomScrollbars className="gx-layout-sider-scrollbar" style={{ minHeight: "92vh"  ,marginLeft:navStyle=== "NAV_STYLE_FIXED" ? "-25px" : 0}}> 

            {hasRole(user, [ADMIN, SCREENER]) && <AdminSidebar
              navStyleClass={getNavStyleSubMenuClass(navStyle)} defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType} user={user}/> }

            {hasRole(user, [RECRUITER]) && <RecruiterSidebar
              navStyleClass={getNavStyleSubMenuClass(navStyle)} defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType} user={user}/> }
            {hasRole(user, [MARKETER]) && <MarketerSidebar
              navStyleClass={getNavStyleSubMenuClass(navStyle)} defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType} user={user}/> }
              {hasRole(user, [ PROJECT_COORDINATION]) && <ProjectCoordinatorSidebar
              navStyleClass={getNavStyleSubMenuClass(navStyle)} defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType} user={user}/> }
            {hasRole(user, [ENGINEER]) && <EngineerSidebar
              navStyleClass={getNavStyleSubMenuClass(navStyle)} defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType} user={user}/> }
            {hasRole(user, [HUMAN_RESOURCE]) && <HumanResourceSideBar
              navStyleClass={getNavStyleSubMenuClass(navStyle)} defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType} user={user}/> }
            {hasRole(user, [CONTRACT]) && <ContractSidebar
              navStyleClass={getNavStyleSubMenuClass(navStyle)} defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType} user={user}/> }
            {hasRole(user, [CONSULTANT]) && <ConsltantSidebar
              navStyleClass={getNavStyleSubMenuClass(navStyle)} defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType} user={user}/> }
            {hasRole(user, [ACCOUNTS]) && <AccountSidebar
              navStyleClass={getNavStyleSubMenuClass(navStyle)} defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType} user={user}/> }
            {hasRole(user, [TIMESHEETS]) && <TimesheetsSidebar
              navStyleClass={getNavStyleSubMenuClass(navStyle)} defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType} user={user}/> }
            {/* {hasRole(user, [MARKETER, RECRUITER, ADMIN, SCREENER]) && <CommonSidebar
              navStyleClass={getNavStyleSubMenuClass(navStyle)} defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType} user={user}/> } */}

        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;

