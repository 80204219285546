import React, { useEffect, useRef, useState } from "react";
import NotificationItem from "./NotificationItem";
import CustomScrollbars from 'util/CustomScrollbars'
import Auxiliary from "util/Auxiliary";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotification, readNotification } from "../../appRedux/actions";
import { Spin } from "antd";

const MailNotification = ({user ,setHandleNotificationPopoverOpen = () => {}}) => {
  const dispatch = useDispatch();
  const prevArrReadRef = useRef([]);
  const notificationsData = useSelector((state) => state.essentials.notification);
  var notificationList = useSelector((state) => state.essentials.notificationList);
  const [page, setPage] = useState(1);
  const [arrayRead, setArrayRead] = useState([])
  notificationList = [...new Map(notificationList.map(obj => [obj.id, obj])).values()]

  function areArraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }

  var arrRead = notificationList.map((obj) => {
    return obj.id;
  });
  useEffect(() => {
    
    // if (arrRead.length > 0 && !areArraysEqual(arrRead, arrayRead)) {
      if (arrRead.length > 0 && !areArraysEqual(arrRead, prevArrReadRef.current)) {
      setArrayRead(arrRead);
      dispatch(readNotification(arrRead));
      // Update the ref with the new arrRead
      prevArrReadRef.current = arrRead;
    }
  }, [dispatch, notificationList]);

  const fetchNotifications = async () => {
    if(notificationsData && notificationsData.next != null){
      try {
        await dispatch(fetchNotification(page+1));
        setPage(page+1);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
  }
  };

  const debouncedFetchNotifications = debounce(fetchNotifications, 2000);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const isBottom = scrollTop + clientHeight >= scrollHeight -10;

    if (isBottom) {
      debouncedFetchNotifications();
    }
  };

  return (
    <Auxiliary>
      <div className="gx-popover-header">
        <h3 className="gx-mb-0">Notifications</h3>
        {/* <i className="gx-icon-btn icon icon-charvlet-down" /> */}
      </div>
      {/* <div style={{ height: "100px", overflow: "auto" }} onScroll={handleScroll} > */}
      <CustomScrollbars className="gx-popover-scroll" onScroll={handleScroll} >
        <ul className="gx-sub-popover">
          {notificationList.map((notification, index) => (
            <NotificationItem key={index} notification={notification} user={user} setHandleNotificationPopoverOpen={setHandleNotificationPopoverOpen} />
          ))}
        </ul>
       {notificationsData && notificationsData.next != null && <div style={{textAlign:'center'}}>
           <Spin />
        </div>}
      </CustomScrollbars>
      {/* </div> */}
    </Auxiliary>
  );
};

// Utility function for debouncing
function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}

export default MailNotification;

// import React, { useEffect, useState } from "react";
// import NotificationItem from "./NotificationItem";
// import CustomScrollbars from 'util/CustomScrollbars'
// import Auxiliary from "util/Auxiliary";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchNotification, readNotification } from "../../appRedux/actions";
// // import Scrollbars from "react-custom-scrollbars";
// import { Spin } from "antd";

// const MailNotification = ({user ,setHandleNotificationPopoverOpen = () => {}}) => {
//   const dispatch = useDispatch();
//   const notificationsData = useSelector((state) => state.essentials.notification);
//   var notificationList = useSelector((state) => state.essentials.notificationList);
//   const [page, setPage] = useState(1);
//   const [arrayRead, setArrayRead] = useState([])
//   notificationList = [...new Map(notificationList.map(obj => [obj.id, obj])).values()]
// console.log('notificationList notificationList',notificationList)
//   function areArraysEqual(arr1, arr2) {
//     if (arr1.length !== arr2.length) {
//       return false;
//     }
  
//     for (let i = 0; i < arr1.length; i++) {
//       if (arr1[i] !== arr2[i]) {
//         return false;
//       }
//     }
  
//     return true;
//   }

//   var arrRead = notificationList.map((obj) => {
//     return obj.id;
//   });
//   useEffect(() => {
//     if (arrRead.length > 0 && !areArraysEqual(arrRead, arrayRead)) {
//       setArrayRead(arrRead);
//       dispatch(readNotification(arrRead));
//     }
//   }, [notificationList]);
// // }, [notificationList]);

//   const fetchNotifications = async () => {
//     if(notificationsData && notificationsData.next != null){
//       try {
//         await dispatch(fetchNotification(page+1));
//         setPage(page+1);
//       } catch (error) {
//         console.error("Error fetching notifications:", error);
//       }
//   }
//   };

//   const debouncedFetchNotifications = debounce(fetchNotifications, 2000);

//   const handleScroll = (values) => {
//     const { scrollTop, scrollHeight, clientHeight } = values;
//     const isBottom = scrollTop + clientHeight >= scrollHeight -10;
// console.log('isBottom',isBottom)
//     if (isBottom) {
//       debouncedFetchNotifications();
//     }
//   };

//   return (
//     <Auxiliary>
//       <div className="gx-popover-header">
//         <h3 className="gx-mb-0">Notifications</h3>
//         {/* <i className="gx-icon-btn icon icon-charvlet-down" /> */}
//       </div>
//       <CustomScrollbars className="gx-popover-scroll" onScrollFrame={handleScroll}>
//         <ul className="gx-sub-popover">
//           {notificationList.map((notification, index) => (
//             <NotificationItem key={index} notification={notification} user={user} setHandleNotificationPopoverOpen={setHandleNotificationPopoverOpen} />
//           ))}
//         </ul>
//        {notificationsData && notificationsData.next != null && <div style={{textAlign:'center'}}>
//            <Spin />
//         </div>}
//       </CustomScrollbars>
//     </Auxiliary>
//   );
// };

// // Utility function for debouncing
// function debounce(func, delay) {
//   console.log('assd')
//   let timeoutId;
//   return function (...args) {
//     clearTimeout(timeoutId);
//     timeoutId = setTimeout(() => func.apply(this, args), delay);
//   };
// }

// export default MailNotification;
