import React from "react";
import {Navigate, Redirect, Route, Switch, useParams} from "react-router";
import {Routes} from "react-router-dom";

import asyncComponent from "../../util/asyncComponent";
import {PropsRoute} from "../../util/routeRestriction"

const AsyncProfileComp = asyncComponent(() => import('./profile/view'));
const AsyncProfileViewComp = asyncComponent(() => import('./profile/edit'));

const Profile = (props) => {
  const { username } = useParams();

  return <Routes>
    {/* <Redirect exact from={`${props.match.url}/profile`} to={`${props.match.url}`}/> */}
    <Route path={`/:username/profile`} element={<Navigate to={`${username}`} />} />
    <Route path="view" element={
      <PropsRoute
        path={`/${username}/view`}
        user={props.user}
        dispatch={props.dispatch}
        element={<AsyncProfileComp/>}/>
    }></Route>
    <Route path="edit" element={
      <PropsRoute
        path={`/${username}/edit`}
        user={props.user}
        dispatch={props.dispatch}
        element={<AsyncProfileViewComp/>}/>
    }></Route>
    {/*<PropsRoute*/}
    {/*  path={`${props.match.url}/education/edit/:educationId`}*/}
    {/*  user={props.user} component={asyncComponent(() => import('./education/edit'))}/>*/}
    {/*<PropsRoute*/}
    {/*  path={`${props.match.url}/experience/edit/:experienceId`}*/}
    {/*  dispatch={props.dispatch}*/}
    {/*  user={props.user} component={asyncComponent(() => import('./experience'))}/>*/}
  </Routes>
}

export default Profile;


// const Profile = (props) => {
//   const { username } = useParams();
//   // alert('Profile Profile Profile')
//   return <Routes>
//     {/* <Redirect exact from={`${props.match.url}/profile`} to={`${props.match.url}`}/> */}
//     <Route path={`${props.match.url}/profile`} element={<Navigate to={`${props.match.url}`} />} />
//     <PropsRoute
//       path={`${props.match.url}/view`}
//       user={props.user}
//       dispatch={props.dispatch}
//       component={AsyncProfileComp}/>
//     <PropsRoute
//       path={`${props.match.url}/edit`}
//       user={props.user}
//       dispatch={props.dispatch}
//       component={AsyncProfileViewComp}/>
//     {/*<PropsRoute*/}
//     {/*  path={`${props.match.url}/education/edit/:educationId`}*/}
//     {/*  user={props.user} component={asyncComponent(() => import('./education/edit'))}/>*/}
//     {/*<PropsRoute*/}
//     {/*  path={`${props.match.url}/experience/edit/:experienceId`}*/}
//     {/*  dispatch={props.dispatch}*/}
//     {/*  user={props.user} component={asyncComponent(() => import('./experience'))}/>*/}
//   </Routes>
// }

// export default Profile;