import {
  ADD_EMPLOYEE_FAILURE,
  ADD_EMPLOYEE_SUCCESS,
  ADD_SCREENER_CALENDAR_FAILURE,
  ADD_SCREENER_CALENDAR_SUCCESS,
  DELETE_EVENT_FAILURE,
  DELETE_EVENT_SUCCESS,
  EDIT_EMPLOYEE_FAILURE,
  EDIT_EMPLOYEE_SUCCESS,
  EMPLOYEE_INIT,
  FETCH_BAR_DATA_FAILURE,
  FETCH_BAR_DATA_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DONUT_DATA_FAILURE,
  FETCH_DONUT_DATA_SUCCESS,
  FETCH_EMPLOYEE_FAILURE,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE_LIST_SUCCESS,
  FETCH_SUPPORT_ID_LIST_SUCCESS,
  FETCH_EMPLOYEE_LIST_FAILURE,
  FETCH_EVENTS_FAILURE,
  FETCH_EVENTS_SUCCESS,
  FETCH_HIRED_DATA_FAILURE,
  FETCH_HIRED_DATA_SUCCESS,
  FETCH_PIE_DATA_FAILURE,
  FETCH_PIE_DATA_SUCCESS,
  FETCH_RATIO_DATA_FAILURE,
  FETCH_RATIO_DATA_SUCCESS,
  FETCH_RECENT_FAILURE,
  FETCH_RECENT_SUCCESS,
  FETCH_SCHEDULED_SCREENINGS_FAILURE,
  FETCH_SCHEDULED_SCREENINGS_SUCCESS,
  FETCH_SCREENER_FAILURE,
  FETCH_SCREENER_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_SUCCESS,
  FETCH_TASKS_FAILURE,
  FETCH_TASKS_SUCCESS,
  GET_ATTACHMENT_FAILURE,
  GET_ATTACHMENT_SUCCESS,
  GET_DEPARTMENTS_FAILURE,
  GET_DEPARTMENTS_SUCCESS,
  GET_EMPLOYEE_FAILURE,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYERS_SUCCESS,
  GET_MANAGERS_FAILURE,
  GET_MANAGERS_SUCCESS,
  GET_MARKETERS_FAILURE,
  GET_MARKETERS_SUCCESS,
  GET_LEVELS_SUCCESS,
  GET_LEVELS_FAILURE,
  UPDATE_SCREENING_FAILURE,
  UPDATE_SCREENING_SUCCESS,
  FETCH_RECRUITERS_FAILURE,
  FETCH_RECRUITERS_SUCCESS,
  GET_MARKETING_LEAD_FAILURE,
  GET_MARKETING_LEAD_SUCCESS,
  GET_MARKETING_SUBORDINATES_FAILURE,
  GET_MARKETING_SUBORDINATES_SUCCESS,
  FETCH_MARKETING_STANDUP_LIST_SUCCESS,
  GET_RECRUITMENT_LEAD_FAILURE,
  GET_RECRUITMENT_LEAD_SUCCESS,
  FETCH_RECRUITERS_ADDED_CONSULTANT_COUNT_LIST_SUCCESS,
  FETCH_MARKETER_LIST_SUCCESS,
  GET_ALL_RECRUITER_SUCCESS
} from "../../constants/ActionTypes";
import {fetchError, fetchStart,handleErrorResponse, fetchSuccess, showMessage, handleRemoveDisableSubmitButton} from "./Common";
import {userService} from "../services";
import {
  ADD_ATTACHMENT,
  ALL_EMPLOYEES,
  CREATE_EMPLOYEE,
  CREATE_EVENT,
  DELETE_EVENT,
  EDIT_EMPLOYEE,
  FETCH_TASKS,
  GET_ACTIVE_RATIO,
  GET_DEPARTMENTS,
  GET_EMPLOYEE,
  GET_EMPLOYERS,
  GET_EVENTS,
  GET_HIRED_RATIO,
  GET_LEFT_RATIO,
  GET_MANAGERS,
  GET_MARKETERS,
  GET_RECENT_RECRUITS,
  GET_RECENT_RECRUITS_BY_STATUS,
  GET_RECRUITER_DASHBOARD_DATA,
  GET_SCHEDULED_SCREENINGS,
  GET_SCREENERS,
  GET_SCREENING_RATIO,
  GET_STATUS_RATIO,
  GET_LEVELS,
  UPDATE_SCREENING_STATUS,
  EMPLOYEE_LIST,
  GET_SUPPORT_ID_LIST,
  ATTACHMENT_STATUS,
  GET_MARKETING_LEAD,
  GET_MARKETERS_SUBORDINATES,
  CREATE_ASSESSMENT_EVENT,
  GET_MARKETING_STANDUP_LIST,
  GET_RECRUITMENT_LEAD,
  GET_RECRUITERS_ADDED_CONSULTANT_COUNT_LIST,
  GET_ALL_RECRUITER
} from "../../constants/ApiEndpoints";
import {fetchAttachments, fetchConsultants,} from "./Consultants";
import { fetchAssessments, fetchAssessmentsList, selectedAssessmentType } from "./Submissions";
// import {userInit} from "./Users";
import { levelsConst } from "../../constants/Constants";
import moment from "moment";
import dayjs from "dayjs";

export const employeeInit = () => {
  return {
    type: EMPLOYEE_INIT,
  }
};


export const fetchDashboardSuccess = (dashboard_data) => {
  return {
    type: FETCH_DASHBOARD_SUCCESS,
    payload: dashboard_data
  }
};

export const fetchDashboardFailure = () => {
  return {
    type: FETCH_DASHBOARD_FAILURE
  }
};



export const fetchDashboard = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = GET_RECRUITER_DASHBOARD_DATA.replace('id', id);
    userService.get(apiEndpoint).then((response) => {
      if (response) {
        dispatch(fetchDashboardSuccess(response.results[0]));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchDashboardFailure());
      console.log("Error****:", error.data);
    });
  }
};

export const fetchBarDataSuccess = (barData) => {
  return {
    type: FETCH_BAR_DATA_SUCCESS,
    payload: barData
  }
};

export const fetchBarDataFailure = () => {
  return {
    type: FETCH_BAR_DATA_FAILURE
  }
};

export const fetchBarData = (id, params={by_employee:'no'}) => {
  return (dispatch) => {
    let apiEndpoint = GET_SCREENING_RATIO.replace('id', id);
    dispatch(fetchStart());
    userService.get(apiEndpoint, params).then((response) => {
      if (response) {
        dispatch(fetchBarDataSuccess(response.results));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchBarDataFailure());
      console.log("Error****:", error.data);
    });
  }
};

export const fetchPieDataSuccess = (pieData) => {
  return {
    type: FETCH_PIE_DATA_SUCCESS,
    payload: pieData
  }
};

export const fetchPieDataFailure = () => {
  return {
    type: FETCH_PIE_DATA_FAILURE
  }
};

export const fetchPieData = (filterBy={filter_by:'year', by_employee:'no', id: null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_STATUS_RATIO, filterBy).then((response) => {
      if (response) {
        dispatch(fetchPieDataSuccess(response.results));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchPieDataFailure());
      console.log("Error****:", error.data);
    });
  }
};


export const fetchDonutDataSuccess = (donutData) => {
  return {
    type: FETCH_DONUT_DATA_SUCCESS,
    payload: donutData
  }
};


export const fetchDonutDataFailure = () => {
  return {
    type: FETCH_DONUT_DATA_FAILURE
  }
};


export const fetchDonutData = (params={by_employee:'no', id: null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_ACTIVE_RATIO, params).then((response) => {
      if (response) {
        dispatch(fetchDonutDataSuccess(response.results));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchDonutDataFailure());
      console.log("Error****:", error.data);
    });
  }
};


export const fetchHiredDataSuccess = (hiredData) => {
  return {
    type: FETCH_HIRED_DATA_SUCCESS,
    payload: hiredData
  }
};

export const fetchHiredDataFailure = () => {
  return {
    type: FETCH_HIRED_DATA_FAILURE
  }
};

export const fetchHiredData = (params={by_employee: 'no', id: null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_HIRED_RATIO, params).then((response) => {
      if (response) {
        dispatch(fetchHiredDataSuccess(response.results));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchHiredDataFailure());
      console.log("Error****:", error.data);
    });
  }
};

export const fetchRatioDataSuccess = (ratioData) => {
  return {
    type: FETCH_RATIO_DATA_SUCCESS,
    payload: ratioData
  }
};

export const fetchRatioDataFailure = () => {
  return {
    type: FETCH_RATIO_DATA_FAILURE
  }
};

export const fetchRatioData = (params={by_employee:'no', id: null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_LEFT_RATIO, params).then((response) => {
      if (response) {
        dispatch(fetchRatioDataSuccess(response.results));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchRatioDataFailure());
      console.log("Error****:", error.data);
    });
  }
};

export const fetchRecentSuccess = (recent) => {
  return {
    type: FETCH_RECENT_SUCCESS,
    payload: recent
  }
};

export const fetchRecentFailure = () => {
  return {
    type: FETCH_RECENT_FAILURE
  }
};

export const fetchRecentRecruits = (filterBy={by_employee:'no', id: null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_RECENT_RECRUITS, filterBy).then((response) => {
      if (response) {
        dispatch(fetchRecentSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      console.log(error);
      dispatch(fetchError(error.data.error));
      dispatch(fetchRecentFailure());
      console.log("Error****:", error.data);
    });
  }
};

export const fetchStatusSuccess = (response) => {
  return {
    type: FETCH_STATUS_SUCCESS,
    payload: response
  }
};

export const fetchStatusFailure = () => {
  return {
    type: FETCH_STATUS_FAILURE
  }
};

export const fetchRecruitsByStatus = (params={id: null, by_employee: 'no'}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_RECENT_RECRUITS_BY_STATUS, params).then((response) => {
      if (response) {
        dispatch(fetchStatusSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchStatusFailure());
      console.log("Error****:", error.data);
    });
  }
};


export const fetchScreenersSuccess = (response) => {
  return {
    type: FETCH_SCREENER_SUCCESS,
    payload: response
  }
};


export const fetchScreenersFailure = () => {
  return {
    type: FETCH_SCREENER_FAILURE
  }
};

export const fetchScreeners = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = GET_SCREENERS.replace('id', id);
    userService.get(apiEndpoint).then((response) => {
      if (response) {
        dispatch(fetchScreenersSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchScreenersFailure());
      console.log("Error****:", error.data);
    });
  }
};

export const fetchScreeningsSuccess = (response) => {
  return {
    type: FETCH_SCHEDULED_SCREENINGS_SUCCESS,
    payload: response
  }
};

export const fetchScreeningsFailure = () => {
  return {
    type: FETCH_SCHEDULED_SCREENINGS_FAILURE
  }
};

export const fetchScreenings = (filterBy = 'scheduled', asScreener = true, page = 1) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const params = {
      'filter_by': filterBy,
      'as_screener': asScreener,
      'page': page
    };
    userService.get(GET_SCHEDULED_SCREENINGS, params).then((response) => {
      if (response) {
        dispatch(fetchScreeningsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchScreeningsFailure());
      console.log("Error****:", error.data);
    });
  }
};


export const updateScreeningStatusSuccess = () => {
  return {
    type: UPDATE_SCREENING_SUCCESS,
  }
};

export const updateScreeningStatusFailure = () => {
  return {
    type: UPDATE_SCREENING_FAILURE
  }
};

export const updateScreeningStatus = (payload, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndPoint = UPDATE_SCREENING_STATUS.replace('id', id);
    userService.patch(apiEndPoint, payload).then((response) => {
      if (response) {
        dispatch(updateScreeningStatusSuccess());
        dispatch(showMessage("Successfully updated status"));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(updateScreeningStatusFailure());
      console.log("Error****:", error.data);
    });
  }
};


export const fetchEventsSuccess = (response) => {
  return {
    type: FETCH_EVENTS_SUCCESS,
    payload: response
  }
};

export const fetchEventsFailure = () => {
  return {
    type: FETCH_EVENTS_FAILURE
  }
};


export const fetchCalendar = (params={screener_email:'', start:null , end:null, timeZone:"Asia/Kolkata"}) => {
  const today = new Date();
  const start = new Date(today.setDate(today.getDate() - today.getDay()));
  start.setHours(0, 0, 0, 0);
  const end = new Date(today.setDate(today.getDate() + 6));
  end.setHours(0, 0, 0, 0);
  // params["end"] = params[start]?params[start]:start;
  // params["start"] =  params[end]? params[end]:end;

  params["start"] = params.start == null ?start:params.start;
  params["end"] = params.end == null ?end:params.end;
  params && params.end && params.end !== null && params.end !== "" && params.end.setDate(params.end.getDate() + 1);  // adding 1 more day to fetch the currect data for 7 day in a weak 
  params["start"] = start&&  dayjs(params.start).format('YYYY-MM-DDTHH:mm:ss').concat('.00Z')
  params["end"] = dayjs(params.end).format('YYYY-MM-DDTHH:mm:ss').concat('.00Z')
  
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(GET_EVENTS, params).then((response) => {
      if (response) {
        response['data'] = response;
        response['screener'] = params['screener_email'];
        dispatch(handleRemoveDisableSubmitButton('select_details_to_create_assessment'))
        dispatch(fetchEventsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(handleRemoveDisableSubmitButton('select_details_to_create_assessment'))
      sessionStorage.removeItem('events');
      dispatch(fetchError(error.data.error));
      dispatch(fetchEventsFailure());
      console.log("Error Calendar****:", error.data);
    });
  }
};

export const createEventSuccess = (response) => {
  return {
    type: ADD_SCREENER_CALENDAR_SUCCESS,
    payload: response
  }
};

export const createEventFailure = () => {
  return {
    type: ADD_SCREENER_CALENDAR_FAILURE
  }
};


export const createCalendarEvent = (formData) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const headers = {
      "Content-Type": "multipart/form-data"
     };
    userService.post(CREATE_EVENT, formData, headers).then((response) => {
      if (response) {
        dispatch(showMessage("Successfully created event."));
        dispatch(createEventSuccess(response));
        dispatch(fetchSuccess());
        dispatch(handleErrorResponse({"createCalendarEvent": "success"}))
      }
    }).catch(function (error) {
      dispatch(fetchError("Error: Event Creation Failed!"));
      dispatch(createEventFailure());
      console.log("Error****:", error.data);
       dispatch(handleErrorResponse({"createCalendarEvent": error}))
    });
  }
};



export const createMarketingCalendarEvent = (formData, submissionId=null ,fetchBy={screener_email:null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const headers = {
      "Content-Type": "multipart/form-data"
     };
    userService.post(CREATE_ASSESSMENT_EVENT, formData, headers).then((response) => {
      if (response) {
        if(fetchBy && fetchBy.screener_email !== null){ // fetching the updated calendar after even is created
          dispatch(fetchCalendar({ screener_email: fetchBy.screener_email, start: null, end: null }));
        }
        dispatch(selectedAssessmentType(null));  // remove the selectedAssessmentType whichc is showiong innitially in the event creation form.
        dispatch(showMessage("Successfully created event."));
        dispatch(createEventSuccess(response));
        dispatch(fetchSuccess());
        dispatch(fetchAssessments(submissionId));
        dispatch(fetchAssessmentsList());
        dispatch(handleErrorResponse({"createMarketingCalendarEvent": "success"}))
      }
    }).catch(function (error) {
      dispatch(createEventFailure());
      if(error.data && error.data !== undefined && error.data.errors ){
        const keys = Object.keys(error.data.errors );
        keys.forEach(key => {
          const errorMessage = error.data.errors[key];
          dispatch(fetchError(errorMessage));
          dispatch(fetchError("Error: Event Creation Failed!"));
        });
      }
        if(error && error.data && error.data.error){
            dispatch(fetchError(error.data.error));
        }
      console.log("Error****:", error.data);
       dispatch(handleErrorResponse({"createMarketingCalendarEvent": error}))
    });
  }
};

export const deleteEventSuccess = (response) => {
  return {
    type: DELETE_EVENT_SUCCESS,
    payload: response.details
  }
};

export const deleteEventFailure = () => {
  return {
    type: DELETE_EVENT_FAILURE
  }
};

export const deleteCalendarEvent = (eventId, selected_screener, event) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const payload = {
      'eid': eventId,
      'selected_screener': selected_screener,
      'event': event
    };
    userService.post(DELETE_EVENT, payload).then((response) => {
      if (response) {
        dispatch(showMessage(response));
        dispatch(deleteEventSuccess(event));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(deleteEventFailure());
      console.log("Error****:", error.data);
    });
  }
};

export const fetchEmployeesSuccess = (employeeData) => {
  return {
    type: FETCH_EMPLOYEE_SUCCESS,
    payload: employeeData
  }
};

export const fetchEmployeesFailure = () => {
  return {
    type: FETCH_EMPLOYEE_FAILURE
  }
};

export const fetchEmployees = (page = 1, status = null, department=null, fetchedBy=null ,q=null ,filter_By={page_size: null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const filterBy = {
      'page': page,
      'status': status,
      'department': Array.isArray(department) ? department.join(',') : department,
      'fetched_by': fetchedBy,
      'q': q,
      'page_size':filter_By && filter_By.page_size ,
    };
    userService.get(ALL_EMPLOYEES, filterBy).then((response) => {
      if (response) {
        dispatch(fetchEmployeesSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchEmployeesFailure());
    });
  }
};

// export const fetchRecruitersSuccess = (employeeData) => {
//   return {
//     type: FETCH_RECRUITERS_SUCCESS,
//     payload: employeeData
//   }
// };

// export const fetchRecruitersFailure = () => {
//   return {
//     type: FETCH_RECRUITERS_FAILURE
//   }
// };

// export const fetchRecruiters = (page = 1, status = null, fetchedBy=null) => {
//   return (dispatch) => {
//     dispatch(fetchStart());
//     const filterBy = {
//       'page': page,
//       'status': status,
//       'department': 'recruitment',
//       'fetched_by': fetchedBy
//     };
//     userService.get(ALL_EMPLOYEES, filterBy).then((response) => {
//       if (response) {
//         dispatch(fetchRecruitersSuccess(response));
//         dispatch(fetchSuccess());
//       }
//     }).catch(function (error) {
//       dispatch(fetchError(error.data.error));
//       dispatch(fetchRecruitersFailure());
//     });
//   }
// };

export const fetchEmployeeSuccess = (employee) => {
  return {
    type: GET_EMPLOYEE_SUCCESS,
    payload: employee
  }
};

export const fetchEmployeeFailure = () => {
  return {
    type: GET_EMPLOYEE_FAILURE
  }
};


export const fetchEmployee = (id,filterBy={expand:'profile,department'}) => {
  return (dispatch) => {
    dispatch(employeeInit());
    // dispatch(userInit());
    dispatch(fetchStart());
    let apiEndpoint = GET_EMPLOYEE.replace('id', id);
    userService.get(apiEndpoint, filterBy).then((response) => {
      if (response) {
        if (typeof response === "string") {
          dispatch(fetchError(response));
          dispatch(fetchEmployeeFailure());
        } else {
          dispatch(fetchEmployeeSuccess(response));
        }
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchEmployeeFailure());

    });
  }
};


export const fetchRecruitersSuccess = (employeeData) => {
  return {
    type: FETCH_RECRUITERS_SUCCESS,
    payload: employeeData
  }
};

export const fetchRecruitersFailure = () => {
  return {
    type: FETCH_RECRUITERS_FAILURE
  }
};

export const fetchRecruiters = (page = 1, status = null, fetchedBy=null) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const filterBy = {
      'page': page,
      'status': status,
      'department': 'recruitment',
      'fetched_by': fetchedBy
    };
    userService.get(ALL_EMPLOYEES, filterBy).then((response) => {
      if (response) {
        dispatch(fetchRecruitersSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchRecruitersFailure());
    });
  }
};


export const addEmployeeSuccess =  (employeeData) => {
  return{
    type: ADD_EMPLOYEE_SUCCESS,
    payload: employeeData
  }
};

export const addEmployeeFailure =  (error) => {
  return {
    type: ADD_EMPLOYEE_FAILURE,
    payload: error
  }
};


export const addEmployee = (values, page = 1, status = null, department=null) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const headers = {
     "Content-Type": "multipart/form-data"
    };
  
    const apiEndpoint = CREATE_EMPLOYEE + '?expand=profile';
    userService.post(apiEndpoint, values,  headers ).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        dispatch(handleRemoveDisableSubmitButton('add_new_employee'))
        dispatch(addEmployeeSuccess(response));
        dispatch(showMessage(`Successfully added ${response.profile.name}.`));
        dispatch(fetchEmployees(page, status, department));
        dispatch(handleErrorResponse({"employeeAdd": "success"}))
      }
    }).catch(function (error) {
      dispatch(handleRemoveDisableSubmitButton('add_new_employee'))
      if (error && error.data) {
        const keys = Object.keys(error.data);
        keys.forEach(key => {
            const errorMessages = error.data[key];
            if (Array.isArray(errorMessages)) {
                errorMessages.forEach(errorMessage => {
                  // dispatch(showMessage(errorMessage))
                    dispatch(fetchError(errorMessage));
                });
            } else if (typeof errorMessages === 'string') {
                // dispatch(showMessage(errorMessages))
                dispatch(fetchError(errorMessages));
            }
        });
    }
    // if(error && error.data && error.data.error){
    //   dispatch(fetchError(error.data.error));
    // }
      dispatch(handleErrorResponse({"employeeAdd": error}))
      dispatch(addEmployeeFailure(error));
    });
  }
};


export const editEmployeeSuccess =  (employeeData) => {
  return{
    type: EDIT_EMPLOYEE_SUCCESS,
    payload: employeeData
  }
};

export const editEmployeeFailure =  () => {
  return {
    type: EDIT_EMPLOYEE_FAILURE,
  }
};


export const editEmployee = (values, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    let apiEndpoint = EDIT_EMPLOYEE.replace('id', id).concat('?expand=profile,department');
    userService.patch(apiEndpoint, values).then((response) => {
      if (response) {
        // if (typeof response === "string") {
        //   dispatch(fetchError(`Employee deleted successfully.`));
        //   dispatch(fetchEmployees(1,null));
        // }
        // else{
          dispatch(handleRemoveDisableSubmitButton('employee_edit_form'))
          dispatch(editEmployeeSuccess(response));
          dispatch(fetchSuccess());
          dispatch(showMessage(`${response.profile.name} updated successfully.`));
        // }
      }
    }).catch(function (error) {
      dispatch(handleRemoveDisableSubmitButton('employee_edit_form'))
      dispatch(fetchError(error.data.error));
      dispatch(editEmployeeFailure());
    });
  }
};

export const fetchManagersSuccess = (managers) => {
  return {
    type: GET_MANAGERS_SUCCESS,
    payload: managers
  }
};

export const fetchManagersFailure = () => {
  return {
    type: GET_MANAGERS_FAILURE,
  }
};


export const fetchManagers = (department, filter_By={employee_id:null}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  const filterBy = {
    'department': department,
    'employee_id': filter_By.employee_id
  };
  userService.get(GET_MANAGERS, filterBy).then((response) => {
      if (response) {
        dispatch(fetchManagersSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchManagersFailure());
    });
  }
};

export const fetchMarketersSuccess = (marketers) => {
  return {
    type: GET_MARKETERS_SUCCESS,
    payload: marketers
  }
};

export const fetchMarketersFailure = () => {
  return {
    type: GET_MARKETERS_FAILURE,
  }
};


export const fetchMarketers = () => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_MARKETERS).then((response) => {
      if (response) {
        dispatch(fetchMarketersSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      // dispatch(fetchError(error.data.error));
      dispatch(fetchMarketersFailure());
    });
  }
};

export const fetchAllRecruiterSuccess = (recruiter) => {
  return {
    type: GET_ALL_RECRUITER_SUCCESS,
    payload: recruiter
  }
};

export const fetchAllRecruiter = () => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_ALL_RECRUITER).then((response) => {
      if (response) {
        dispatch(fetchAllRecruiterSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      // dispatch(fetchError(error.data.error));
      dispatch(fetchMarketersFailure());
    });
  }
};

export const fetchMarketingSubordinatesSuccess = (marketers) => {
  return {
    type: GET_MARKETING_SUBORDINATES_SUCCESS,
    payload: marketers
  }
};

export const fetchMarketingSubordinatesFailure = () => {
  return {
    type: GET_MARKETING_SUBORDINATES_FAILURE,
  }
};


export const fetchMarketingSubordinates = () => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_MARKETERS_SUBORDINATES).then((response) => {
      if (response) {
        dispatch(fetchMarketingSubordinatesSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchMarketingSubordinatesFailure());
    });
  }
};



export const fetchMarketingLeadsSuccess = (marketinglead) => {
  return {
    type: GET_MARKETING_LEAD_SUCCESS,
    payload: marketinglead
  }
};

export const fetchMarketingLeadsFailure = () => {
  return {
    type: GET_MARKETING_LEAD_FAILURE,
  }
};

export const fetchMarketingLeads = () => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_MARKETING_LEAD).then((response) => {
      if (response) {
        dispatch(fetchMarketingLeadsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      // dispatch(fetchError(error.data.error));
      dispatch(fetchMarketingLeadsFailure());
    });
  }
};

export const fetchRecruitmentLeadsSuccess = (marketinglead) => {
  return {
    type: GET_RECRUITMENT_LEAD_SUCCESS,
    payload: marketinglead
  }
};

export const fetchRecruitmentLeadsFailure = () => {
  return {
    type: GET_RECRUITMENT_LEAD_FAILURE,
  }
};

export const fetchRecruitmentLeads = () => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_RECRUITMENT_LEAD).then((response) => {
      if (response) {
        dispatch(fetchRecruitmentLeadsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchRecruitmentLeadsFailure());
    });
  }
};

export const fetchEmployersSuccess = (employers) => {
  return {
    type: GET_EMPLOYERS_SUCCESS,
    payload: employers
  }
};

export const fetchEmployersFailure = () => {
  return {
    type: GET_MARKETERS_FAILURE,
  }
};


export const fetchEmployers = () => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_EMPLOYERS).then((response) => {
      if (response) {
        dispatch(fetchEmployersSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchEmployersFailure());
    });
  }
};


export const fetchDepartmentsSuccess = (departments) => {
  return {
    type: GET_DEPARTMENTS_SUCCESS,
    payload: departments
  }
};

export const fetchDepartmentsFailure = () => {
  return {
    type: GET_DEPARTMENTS_FAILURE,
  }
};


export const fetchDepartments = () => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_DEPARTMENTS).then((response) => {
      if (response) {
        dispatch(fetchDepartmentsSuccess(response.results));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchDepartmentsFailure());
    });
  }
};


export const addAttachmentSuccess = () => {
  return {
    type: GET_ATTACHMENT_SUCCESS,
  }
};

export const addAttachmentFailure = () => {
  return {
    type: GET_ATTACHMENT_FAILURE,
  }
};


export const addAttachment = (formData, functionCall, callSupplement) => {
  const headers = {
    "Content-Type": "multipart/form-data"
   };
  return(dispatch) => {
  dispatch(fetchStart());
  userService.post(ADD_ATTACHMENT, formData, headers).then((response) => {
      if (response) {
        dispatch(handleRemoveDisableSubmitButton('add_new_document'))
        dispatch(addAttachmentSuccess());
        dispatch(fetchSuccess());
        dispatch(functionCall(callSupplement));
      }
    }).catch(function (error) {
      // dispatch(fetchError(error.data.error));
      console.log("addAttachmentError",error)
      dispatch(handleRemoveDisableSubmitButton('add_new_document'))
      dispatch(addAttachmentFailure());
    });
  }
};


export const changeAttachmentStatus = (id=null,status=null, consultantSlug=null ) => {
  const apiEndpoint = ATTACHMENT_STATUS.replace('id', id);
  return(dispatch) => {
  dispatch(fetchStart());
  userService.put(apiEndpoint, status).then((response) => {
      if (response) {
        dispatch(showMessage(`Attachment status updated successfully!`));
        dispatch(fetchSuccess());
        dispatch(fetchAttachments(consultantSlug));
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  }
};

export const fetchTasksSuccess = (response) => {
  return {
    type: FETCH_TASKS_SUCCESS,
    payload: response
  }
};

export const fetchTasksFailure = () => {
  return {
    type: FETCH_TASKS_FAILURE,
  }
};


export const fetchTasks = (user) => {
  return(dispatch) => {
  dispatch(fetchStart());
    var endPoint = FETCH_TASKS.concat("my/")
    if (user && user.level && user.level <= levelsConst['Manager']){
      endPoint = FETCH_TASKS
    }

  userService.get(endPoint).then((response) => {
      if (response) {
        if (response.count > 0) {
          dispatch(fetchTasksSuccess(response));
          dispatch(fetchSuccess());
        }
        else {
          dispatch(fetchTasksFailure())
        }

      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchTasksFailure());
    });
  }
};



export const fetchRecruiterConsultants = (page=1, id=null ,from_date ,to_date, status=null, byEmployee=true ,filter_by = {include_self:false ,marketing_consultants: false ,page_size:null})  => {
  return(dispatch) => {
  dispatch(fetchConsultants(id, byEmployee, {page:page, status:status,to_date:to_date ,from_date:from_date ,include_self: filter_by.include_self, marketing_consultants: filter_by.marketing_consultants ,page_size: filter_by.page_size}));
  }
};



export const fetchLevelsSuccess = (levels) => {
  return {
    type: GET_LEVELS_SUCCESS,
    payload: levels
  }
};

export const fetchLevelsFailure = () => {
  return {
    type: GET_LEVELS_FAILURE,
  }
};


export const fetchLevels = () => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_LEVELS).then((response) => {
      if (response) {
        dispatch(fetchLevelsSuccess(response.results));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchLevelsFailure());
    });
  }
};

// employee lists by levels and department with manager

export const fetchEmployeesListSuccess = (employeeListData) => {
  return {
    type: FETCH_EMPLOYEE_LIST_SUCCESS,
    payload: employeeListData
  }
};

export const fetchEmployeesListFailure = () => {
  return {
    type: FETCH_EMPLOYEE_LIST_FAILURE
  }
};

export const fetchEmployeesList = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(EMPLOYEE_LIST).then((response) => {
      if (response) {
        dispatch(fetchEmployeesListSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchEmployeesListFailure());
    });
  }
};
// support ids of engineers for offer in-house engineers
export const fetchOfferSupportId = (response) => {
  return {
    type: FETCH_SUPPORT_ID_LIST_SUCCESS,
    payload: response
  }
}

export const fetchSupportIdList = (filterBy={all:false}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_SUPPORT_ID_LIST,filterBy).then((response) => {
      if (response) {
        dispatch(fetchOfferSupportId(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  }
}

export const fetchAllMarketer = (response) => {
  return {
    type: FETCH_MARKETER_LIST_SUCCESS,
    payload: response
  }
}

export const fetchAllMarketerList = (filterBy={all:false, department: null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_MARKETERS,filterBy).then((response) => { // ALL_EMPLOYEES perviously used this Andpoint because the data is not getting fetched for the accounts person 
      if (response) {
        dispatch(fetchAllMarketer(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  }
}

export const fetchMarketingStandupListSuccess = (response) => {
  return {
    type: FETCH_MARKETING_STANDUP_LIST_SUCCESS,
    payload: response
  }
}

export const fetchMarketerStandupList = (filterBy={marketes:null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    var endPoint = filterBy.marketes ? GET_MARKETING_STANDUP_LIST.concat(`?marketes=${filterBy.marketes}`) : GET_MARKETING_STANDUP_LIST
    userService.get(endPoint).then((response) => {
      if (response) {
        dispatch(fetchMarketingStandupListSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  }
}

export const fetchRecruitersAddedConsultantCountListSuccess = (response) => {
  return {
    type: FETCH_RECRUITERS_ADDED_CONSULTANT_COUNT_LIST_SUCCESS,
    payload: response
  }
}

export const fetchRecruitersAddedConsultantCountList = (fetch_by={from_date:null, to_date:null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_RECRUITERS_ADDED_CONSULTANT_COUNT_LIST ,fetch_by).then((response) => {
      if (response) {
        dispatch(fetchRecruitersAddedConsultantCountListSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  }
}

