import {
  ADD_CONSULTANT_FAILURE,
  ADD_CONSULTANT_SUCCESS,
  CONSULTANT_INIT,
  DELETE_ATTACHMENT_SUCCESS,
  DELETE_CONSULTANT_SUCCESS,
  EDIT_COMMENT_SUCCESS,
  EDIT_CONSULTANT_FAILURE,
  EDIT_CONSULTANT_SUCCESS,
  FETCH_ATTACHMENTS_FAILURE,
  FETCH_ATTACHMENTS_SUCCESS,
  FETCH_COMMENTS_FAILURE,
  FETCH_COMMENTS_SUCCESS,
  FETCH_MARKETING_PROFILE_FAILURE,
  FETCH_MARKETING_PROFILE_LIST_FAILURE,
  FETCH_MARKETING_PROFILE_LIST_SUCCESS,
  FETCH_MARKETING_PROFILE_SUCCESS,
  FETCH_MEMBERS_FAILURE,
  FETCH_MEMBERS_SUCCESS,
  FETCH_ONE_MARKETING_PROFILE_FAILURE,
  FETCH_ONE_MARKETING_PROFILE_SUCCESS,
  FETCH_SOURCE_FAILED,
  FETCH_SOURCE_SUCCESS,
  FETCH_TEAM_MARKETING_PROFILE_FAILURE,
  FETCH_TEAM_MARKETING_PROFILE_SUCCESS,
  FETCH_VISA_FAILED,
  FETCH_VISA_SUCCESS,
  GET_CONSULTANT_FAILURE,
  GET_CONSULTANT_SUCCESS,
  GET_CONSULTANTS_FAILURE,
  GET_CONSULTANTS_SUCCESS,
  GET_MARKETING_CONSULTANTS_FAILURE,
  GET_MARKETING_CONSULTANTS_SUCCESS,
  GET_SCREENING_CONSULTANTS_FAILURE,
  GET_SCREENING_CONSULTANTS_SUCCESS,
  GET_SEARCHED_CONSULTANT_FAILURE,
  GET_SEARCHED_CONSULTANT_SUCCESS,
  GET_TEAM_CONSULTANTS_FAILURE,
  GET_TEAM_CONSULTANTS_SUCCESS,
  FETCH_MARKETING_PROFILE_LIST_By_SKILLS_SUCCESS,
  FETCH_CONTRACT_CONSULTANT_LIST_SUCCESS,
  FETCH_ACCOUNT_DETAILS_LIST_SUCCESS,
  FETCH_CONSULTANT_ACCOUNT_DETAILS_LIST_SUCCESS,
  FETCH_CONSULTANT_ASSIGNED_PAYEE_LIST_SUCCESS,
  FETCH_CONSULTANT_ACCOUNT_OBJECT_SUCCESS,
  FETCH_CONSULTANT__ADDTIONAL_INFO_FORM_VALUE_SUCCESS,
  FETCH_CONSULTANT_BASIC_DETAILS_BY_ID_SUCCESS,
  GET_ON_OFFER_CONSULTANTS_SUCCESS
} from "../../constants/ActionTypes";


const INIT_STATE = {
  addConsultant: false,
  consultant: null,
  consultants: null,
  teamConsultants: null,
  consultantMembers: null,
  consultantAttachments: null,
  consultantComments: null,
  totalConsultants: 0,
  teamTotalConsultants: 0,
  visaOptions: null,
  sourceOptions: null,
  marketingProfiles: null,
  marketingProfilesCount: 0,
  marketingProfileList: null,
  marketingProfileListCount: 0,
  marketingProfileListBySkills:[],
  marketingProfileListBySkillsCount: 0,
  teamMarketingProfile:null,
  teamMarketingProfileCount: 0,
  currentMarketingProfile: null,
  screeningConsultants: null,
  marketingConsultants:null,
  searchedConsultant:null,
  contractConsultantList:null,
  totalContractConsultantCount:0,
  accountDetailsList:null,
  totalAccountDetailsListCount:0,
  accountConsultantAccountDetailsList:null,
  totalAccountConsultantAccountDetailsList:0,
  consultantAdditionalInfoValue:null,
  consultantBasicDetilsById:null,
  onOfferConsultants:null,
  onOfferTotalConsultants:0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONSULTANT_INIT:{
      return {
        consultant: null
      }
    }

    case GET_CONSULTANTS_SUCCESS:{
      return {
        ...state,
        consultants: action.payload.results,
        totalConsultants: action.payload.count,
      }
    }
    case GET_CONSULTANTS_FAILURE:{
      return {
        ...state,
        consultants: null,
        totalConsultants: 0,
      }
    }
    case GET_TEAM_CONSULTANTS_SUCCESS:{
      return {
        ...state,
        teamConsultants: action.payload.results,
        teamTotalConsultants: action.payload.count,
      }
    }
    case GET_ON_OFFER_CONSULTANTS_SUCCESS:{
      return {
        ...state,
        onOfferConsultants: action.payload.results,
        onOfferTotalConsultants: action.payload.count,
      }
    }
    case GET_TEAM_CONSULTANTS_FAILURE:{
      return {
        ...state,
        teamConsultants: null,
        teamTotalConsultants: 0,
      }
    }
    case GET_CONSULTANT_SUCCESS:{
      return {
        ...state,
        consultant: action.payload
      }
    }
    case GET_CONSULTANT_FAILURE:{
      return {
        ...state,
        consultant: null
      }
    }
    case FETCH_MEMBERS_SUCCESS:{
      return {
        ...state,
        consultantMembers: action.payload
      }
    }
    case FETCH_MEMBERS_FAILURE:{
      return {
        ...state,
        consultantMembers: null
      }
    }

    case FETCH_MARKETING_PROFILE_SUCCESS:{
      return {
        ...state,
        marketingProfiles: action.payload.results,
        marketingProfilesCount:action.payload.count
      }
    }
    case FETCH_MARKETING_PROFILE_FAILURE:{
      return {
        ...state,
        marketingProfiles: null
      }
    }

    case FETCH_MARKETING_PROFILE_LIST_SUCCESS:{
      return {
        ...state,
        marketingProfileList: action.payload.results,
        marketingProfileListCount: action.payload.count
      }
    }
    case FETCH_MARKETING_PROFILE_LIST_FAILURE:{
      return {
        ...state,
        marketingProfileList: null
      }
    }
    case FETCH_MARKETING_PROFILE_LIST_By_SKILLS_SUCCESS:{
      return {
        ...state,
        marketingProfileListBySkills: action.payload.results,
        marketingProfileListBySkillsCount: action.payload.count
      }
    }
    case FETCH_TEAM_MARKETING_PROFILE_SUCCESS:{
      return {
        ...state,
        teamMarketingProfile: action.payload.results,
        teamMarketingProfileCount: action.payload.count
      }
    }
    case FETCH_TEAM_MARKETING_PROFILE_FAILURE:{
      return {
        ...state,
        teamMarketingProfile: null
      }
    }

    case FETCH_ONE_MARKETING_PROFILE_SUCCESS:{
      return {
        ...state,
        currentMarketingProfile: action.payload
      }
    }
    case FETCH_ONE_MARKETING_PROFILE_FAILURE:{
      return {
        ...state,
        currentMarketingProfile: null
      }
    }

    case FETCH_ATTACHMENTS_SUCCESS:{
      return {
        ...state,
        consultantAttachments: action.payload
      }
    }
    case FETCH_ATTACHMENTS_FAILURE:{
      return {
        ...state,
        consultantAttachments: null
      }
    }
    case FETCH_COMMENTS_SUCCESS:{
      return {
        ...state,
        consultantComments: action.payload
      }
    }
    case FETCH_COMMENTS_FAILURE:{
      return {
        ...state,
        consultantComments: null
      }
    }


    case ADD_CONSULTANT_SUCCESS: {
    return {
        ...state,
        consultant: action.payload,
        addConsultant: false
    }
    }
    case ADD_CONSULTANT_FAILURE: {
    return {
        ...state,
        consultant: null,
        addConsultant: false
    }
    }
    case EDIT_CONSULTANT_SUCCESS: {
    return {
        ...state,
        // consultant: action.payload,
        // addConsultant: false
      }
    }
    case EDIT_CONSULTANT_FAILURE:
    case DELETE_CONSULTANT_SUCCESS: {
    return {
        ...state,
        consultant: null,
        addConsultant: false
      }
    }
    case DELETE_ATTACHMENT_SUCCESS: {
    return {
        ...state,
        consultantAttachments:[],
      }
    }
    case EDIT_COMMENT_SUCCESS: {
    return {
        ...state,
        consultantAttachments:[],
      }
    }
    case FETCH_SOURCE_SUCCESS:{
      return {
        ...state,
        sourceOptions: action.payload
      }
    }
    case FETCH_VISA_SUCCESS:{
      return {
        ...state,
        visaOptions: action.payload
      }
    }
    case GET_SCREENING_CONSULTANTS_SUCCESS:{
      return {
        ...state,
        screeningConsultants: action.payload,
      }
    }
    case GET_SCREENING_CONSULTANTS_FAILURE:{
      return {
        ...state,
        screeningConsultants: null,
      }
    }
    case GET_MARKETING_CONSULTANTS_SUCCESS:{
      return {
        ...state,
        marketingConsultants: action.payload,
      }
    }
    case GET_MARKETING_CONSULTANTS_FAILURE:{
      return {
        ...state,
        marketingConsultants: null,
      }
    }
    case GET_SEARCHED_CONSULTANT_SUCCESS:{
      return {
        ...state,
        searchedConsultant: action.payload,
      }
    }
    case GET_SEARCHED_CONSULTANT_FAILURE:{
      return {
        ...state,
        searchedConsultant: null,
      }
    }
    case FETCH_CONTRACT_CONSULTANT_LIST_SUCCESS:{
      return {
        ...state,
        contractConsultantList: action.payload.results,
        totalContractConsultantCount: action.payload.count,
      }
    }
    case FETCH_ACCOUNT_DETAILS_LIST_SUCCESS:{
      return {
        ...state,
        accountDetailsList: action.payload.results,
        totalAccountDetailsListCount: action.payload.count,
      }
    }
    case FETCH_CONSULTANT_ACCOUNT_DETAILS_LIST_SUCCESS:{
      return {
        ...state,
        consultantAccountDetailsList: action.payload.results,
        totalConsultantAccountDetailsListCount: action.payload.count,
      }
    }
    case FETCH_CONSULTANT_ACCOUNT_OBJECT_SUCCESS:{
      return {
        ...state,
        accountConsultantAccountDetailsList: action.payload.results,
        totalAccountConsultantAccountDetailsList:  action.payload.count
      }
    }
    case FETCH_CONSULTANT_ASSIGNED_PAYEE_LIST_SUCCESS:{
      return {
        ...state,
        consultantOffersPayeeList: action.payload,
      }
    }
    case FETCH_CONSULTANT__ADDTIONAL_INFO_FORM_VALUE_SUCCESS:{
      return {
        ...state,
        consultantAdditionalInfoValue: action.payload,
      }
    }

    case FETCH_CONSULTANT_BASIC_DETAILS_BY_ID_SUCCESS:{
      return {
        ...state,
        consultantBasicDetilsById: action.payload,
      }
    }
    case FETCH_VISA_FAILED:
    case FETCH_SOURCE_FAILED:
      return{
        ...state
      };
    default:
      return state;
  }
  }
