import React from 'react';
import enMessages from "../../src/lngProvider/locales/en_US";

const InjectMessage = ({ id, ...props }) => {
  return <span {...props}>{enMessages && enMessages[id]}</span>;
};

export default InjectMessage;

// import React from "react";
// import {FormattedMessage, injectIntl} from "react-intl";

// const InjectMassage = props => <FormattedMessage {...props} />;
// export default injectIntl(InjectMassage, {
//   withRef: false
// });
