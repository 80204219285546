import React from "react";
import {Navigate, Redirect, Route, Switch, useParams} from "react-router";
import {Routes} from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";
import {PropsRoute} from "../../util/routeRestriction";

const AsyncRecruiterStandup = asyncComponent(() => import('./standup/index'));
const AsyncConsultant = asyncComponent(() => import('./consultant'));
const AsyncEditConsultant = asyncComponent(() => import('./consultant/edit'));
const AsyncScreening = asyncComponent(() => import('./screening'));
const AsyncScreeningInvite = asyncComponent(() => import('./screening/invite'));
const AsyncRecruiter = asyncComponent(() => import('./recruiter'));
const AsyncRecruiterEdit = asyncComponent(() => import('./recruiter/edit'));

const Recruitment = (props) => {
   const { username } = useParams();

 return <Routes>
    {/* <Redirect exact from={`${props.match.url}/recruitment`} to={`${props.match.url}`}/> */}
    <Route path={`${username}/recruitment`} element={<Navigate to={`${username}`} />} />
    <Route path="standup" element={
       <PropsRoute path={`/${username}recruitment/standup`} dispatch={props.dispatch}
       element={<AsyncRecruiterStandup/>} user={props.user}/>
    }></Route>
    <Route path="consultant/list" element={
       <PropsRoute path={`/${username}recruitment/consultant/list`} dispatch={props.dispatch}
      element={<AsyncConsultant/>} user={props.user}/>
                }></Route>
    <Route path="consultant/edit/:consultantId" element={
                  <PropsRoute path={`/${username}recruitment/consultant/edit/:consultantId`} dispatch={props.dispatch}
                  element={<AsyncEditConsultant/>} user={props.user}/>
    }></Route>
    <Route path="screening/list" element={
      <PropsRoute path={`/${username}recruitment/screening/list`} dispatch={props.dispatch}
                   element={<AsyncScreening/>} user={props.user}/>
    }></Route>
    <Route path="screening/invite/:screener" element={
      <PropsRoute path={`/${username}recruitment/screening/invite/:screener`} dispatch={props.dispatch}
                   element={<AsyncScreeningInvite/>} user={props.user}/>
    }></Route>
    <Route path="recruiter/list" element={
      <PropsRoute path={`/${username}recruitment/recruiter/list`} dispatch={props.dispatch}
                   element={<AsyncRecruiter/>} user={props.user}/>
    }></Route>
   <Route path="recruiter/edit/:recruiterId" element={
      <PropsRoute path={`/${username}recruitment/recruiter/edit/:recruiterId`} dispatch={props.dispatch}
                  element={<AsyncRecruiterEdit/>} user={props.user}/>
                }></Route>
   
  </Routes>
};

export default Recruitment;
