import React, {useState} from "react";
import {Table, Button, Modal, Form, Input, Typography} from "antd";
import moment from "moment";
import CheckCircleOutlined from "@ant-design/icons/lib/icons/CheckCircleOutlined";
import CloseCircleOutlined from "@ant-design/icons/lib/icons/CloseCircleOutlined";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import { useDispatch } from "react-redux";
import { fetchTeamSubmissions, handlePracticesStatus, handleTransferRequest } from "../../appRedux/actions";
import { CheckOutlined } from "@ant-design/icons";
import EditOfferForm from "../Offer/EditOfferForm";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useNavigate } from 'react-router-dom';

const NotificationItem = ({notification={},user ,setHandleNotificationPopoverOpen = () => {}}) => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const navigate = useNavigate();
  const { Text } = Typography;
  const {image, badge, title, created, message, redirect_url, model, content_object, object_id, is_read} = notification;



  const dateObj = new Date(created);
  const createdTime = dateObj.toLocaleString('en-US', { month: 'numeric', day: 'numeric',  year: 'numeric', hour: '2-digit', minute: '2-digit' });
  const [visible, setVisible] = useState(false);
  const [comment, setComment] = useState(content_object?content_object.fields?content_object.fields.comment:"":"");
  const [modalVisible, setModalVisible] = useState(false);

  const handleApproveTransfer = ()=>{
    const obj = {
      'request_id': object_id,
      'status':'a' 
    }
    dispatch(handleTransferRequest(obj));
  }

  
  const handleRejectTransfer = ()=>{
    const obj = {
      'request_id': object_id,
      'status':'r' 
    }
    dispatch(handleTransferRequest(obj));
  }

  const showModal = () => {
      setModalVisible(true);
      content_object.fields['id'] = content_object.pk
    };
  const handleViewPractices = ()=>{
    setVisible(true);
   }

  const handleApprove = () => {
    setVisible(false);
     const obj = {
      "status":"a",
      "comment":comment
    }
    dispatch(handlePracticesStatus(obj, content_object.fields.consultant, object_id));
  
  };

  const handleReject = () => {
    setVisible(false);
    const obj = {
      "status":"r",
    }
    dispatch(handlePracticesStatus(obj, content_object.fields.consultant, object_id ));
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const redirectToSubmissionPage = () => {
    if(user && user.username){
      const userName = user && user.username
      const redirectTo = `/${userName}/marketing/submission/list` 
      // history.push(redirectTo)
      navigate(redirectTo)
      localStorage.setItem('openTab','teamList')
      dispatch(fetchTeamSubmissions({page: 1}))
    }
  }

  return (
    <>
    {
      // showing those notification only whose status is 'p' or the normal notification only not the other with the status other then 'p'
      // model !== 'consultanttransferrequest' &&  model !== 'practice' ? 
      // <li className="gx-media" >
      //     <div className="gx-media-body" style={is_read?{color:'darkgray'}:{}}>
      //       <div className="gx-flex-row gx-justify-content-between gx-align-items-center">
      //         <h5 className="gx-text-capitalize gx-user-name gx-mb-0"><span className="gx-link" style={is_read?{color:'darkgray'}:{}}>{title}</span></h5>
      //         <span className="gx-meta-date" style={{color:'grey'}}><small>{createdTime}</small></span>
      //       </div>
      //       <p className="gx-fs-sm">{message}</p>
      //         {model == 'practice' && <div>
      //         <span className="gx-btn gx-btn-sm gx-top2 gx-text-muted" onClick={handleViewPractices}><EyeOutlined /> View More</span>
      //         {is_read && <span> <CheckOutlined /></span>}
      //           </div>}
      //     </div>
      //   </li> 
      //   : 
        // notification && notification.content_object && notification.content_object.fields && notification.content_object.fields.status && (notification.content_object.fields.status === 'a') ? 
      <li className="gx-media" >
      {/* <div className="gx-user-thumb gx-mr-3">
        <Avatar className="gx-size-40"
                alt={image}
                src={image}/>
        {badge > 0 ? <span className="gx-badge gx-badge-danger gx-text-white gx-rounded-circle">{badge}</span> : null}
      </div> */}
      <div className="gx-media-body" style={is_read?{color:'darkgray'}:{}}>
        <div className="gx-flex-row gx-justify-content-between gx-align-items-center">
          <h5 className="gx-text-capitalize gx-user-name gx-mb-0"><span className="gx-link" style={is_read?{color:'darkgray'}:{}}>{title}</span></h5>
        </div>
          <span className="gx-meta-date" style={{color:'grey'}}><small>{createdTime}</small></span>

        <p className="gx-fs-sm">{message}</p>
          {
            title === 'submission created successfully' && <span className="gx-btn gx-btn-sm gx-top2 gx-text-muted" onClick={redirectToSubmissionPage} >
              <EyeOutlined /> View More
            </span>
          }
          {model=='consultanttransferrequest' && content_object && content_object.fields && content_object.fields.status ==='p' &&  <div>
            <Button type="primary" onClick={handleApproveTransfer} ><CheckCircleOutlined /> Approve </Button>
            <Button type="danger" onClick={handleRejectTransfer}><CloseCircleOutlined /> Reject</Button>
            {is_read && <span> <CheckOutlined/></span>}
          </div>}
          {model == 'practice' && <div>
          <span className="gx-btn gx-btn-sm gx-top2 gx-text-muted" onClick={handleViewPractices}><EyeOutlined /> View More</span>
          {is_read && <span> <CheckOutlined /></span>}
            </div>}
          {model ==='offer' && content_object!=null && <div onClick={()=>setHandleNotificationPopoverOpen(false)}>
          <span className="gx-btn gx-btn-sm gx-top2 gx-text-muted" onClick={showModal} ><EyeOutlined /> View More</span>
            </div>}
      </div>
    </li>
  // :null
  }
    <Modal
      title={title}
      open={visible}
      key={title}
      onCancel={()=>{setVisible(false)}}
      footer={[
        <>
       {content_object && content_object.fields && content_object.fields.status =='p' &&  <Button key="approve" type="primary" onClick={handleApprove}>
          Approve
        </Button>}
        { content_object && content_object.fields && content_object.fields.status =='p' && <Button key="reject" danger onClick={handleReject}>
          Reject
        </Button>}
        </>, 
         <Button key="cancel" onClick={()=>{setVisible(false)}}>
         Cancel
       </Button>
      ]}
      zIndex={2000} // Ensuring this modal is on top of any other modal
    >
      <Text>{message}</Text>
      <Input.TextArea
        value={comment}
        style={{ marginTop: '20px' }}
        onChange={handleCommentChange}
      />
    </Modal>
       {modalVisible && <EditOfferForm
        visible={modalVisible}
        setVisible={setModalVisible}
        dispatch={dispatch}
        user={user}
        submissions={[]}
        record = {content_object?content_object.fields:{}}
        title='Approve Offer'
        showSelectSubmission ={false}
     />}
    </>
  );
};

export default NotificationItem;
