import React from "react";
import {Provider} from 'react-redux'
// import {ConnectedRouter} from 'connected-react-router'
// import {Route, Switch} from "react-router-dom";
import "assets/vendors/style";
import configureStore, {history} from './appRedux/store';
// import "./firebase/firebase";
import App from "./containers/App/index";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const store = configureStore(/* provide initial state if any */);


const NextApp = () =>
  <Provider store={store}>
    {/* <Router history={history}> */}
    <Router >
      <Routes>
        <Route path="/*" element={<App/>}/>
      </Routes>
    </Router>
  </Provider>

  // <Provider store={store}>
  //   <ConnectedRouter history={history}>
  //     <Switch>
  //       <Route path="/" component={App}/>
  //     </Switch>
  //   </ConnectedRouter>
  // </Provider>;


export default NextApp;
