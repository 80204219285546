import React from "react";
import {Navigate, Redirect, Route, Switch, useParams} from "react-router";
import {Routes} from "react-router-dom";
import {PropsRoute} from "../../util/routeRestriction";
import asyncComponent from "../../util/asyncComponent";

const AsyncProjectCoordinators = asyncComponent(() => import('../projectCoordination/projectCoordinators/index'));
const AsyncMarketerList = asyncComponent(() => import('../marketing/marketer/index'));
const AsyncRecruiter = asyncComponent(() => import('../recruitment/recruiter/index'));
const AsyncRecruiterEdit = asyncComponent(() => import('../recruitment/recruiter/edit'));
const AsyncMarketerEdit = asyncComponent(() => import('../marketing/marketer/edit'));
const AsyncProjectCoordinatorEdit = asyncComponent(() => import('../projectCoordination/projectCoordinators/edit'));
const AsyncEngieersList = asyncComponent(() => import('../engineer/engineers/index'));
const AsyncEngieerEdit = asyncComponent(() => import('../engineer/engineers/edit'));
const AsyncContractPerson = asyncComponent(() => import('../contract/contractPerson/index'));
const AsyncContractPersonEdit = asyncComponent(() => import('../contract/contractPerson/edit'));
const AsyncEmployeeEdit = asyncComponent(() => import('../user/employee/index'));

const HumanResource = (props) => {
  const { username } = useParams();
  
  return <Routes>
    {/* <Redirect exact from={`${props.match.url}/human_resource`} to={`${props.match.url}`}/> */}
    <Route path={`${username}/human_resource`} element={<Navigate to={`${username}}`} />} />
    <Route path="employee/list" element={
      <PropsRoute path={`${username}/employee/list`} 
        dispatch={props.dispatch} element={<AsyncEmployeeEdit/>} user={props.user}/>
    }></Route>
    <Route path="project_coordinators/edit/:project_coordinatorsId" element={
      <PropsRoute path={`${username}/project_coordinators/edit/:project_coordinatorsId`} 
        dispatch={props.dispatch} element={<AsyncProjectCoordinatorEdit/>} user={props.user}/>
    }></Route>
    <Route path="marketer/edit/:marketerId" element={
      <PropsRoute path={`${username}/marketer/edit/:marketerId`} dispatch={props.dispatch}
        element={<AsyncMarketerEdit/>} user={props.user}/>
    }></Route>
    <Route path="marketer/list" element={
      <PropsRoute path={`${username}/marketer/list`} dispatch={props.dispatch}
        element={<AsyncMarketerList/>} user={props.user}/>
    }></Route>
    <Route path="recruiter/list" element={
      <PropsRoute path={`${username}/recruiter/list`} dispatch={props.dispatch}
        element={<AsyncRecruiter/>} user={props.user}/>
    }></Route>
    <Route path="project_coordinators" element={
      <PropsRoute path={`${username}/project_coordinators`} dispatch={props.dispatch}
        element={<AsyncProjectCoordinators/>} user={props.user}/>
    }></Route>
    <Route path="recruiter/edit/:recruiterId" element={
      <PropsRoute path={`${username}/recruiter/edit/:recruiterId`} dispatch={props.dispatch}
        element={<AsyncRecruiterEdit/>} user={props.user}/>
    }></Route>
    <Route path="engineers/list" element={
      <PropsRoute path={`${username}/engineers/list`} dispatch={props.dispatch}
        element={<AsyncEngieersList/>} user={props.user}/>
    }></Route>
    <Route path="engineers/edit/:engineerId" element={
      <PropsRoute path={`${username}/engineers/edit/:engineerId`} 
        dispatch={props.dispatch} element={<AsyncEngieerEdit/>} user={props.user}/>
    }></Route>
    <Route path="contract-person/list" element={
      <PropsRoute path={`${username}/contract-person/list`} dispatch={props.dispatch}
        element={<AsyncContractPerson/>} user={props.user}/>
    }></Route>
    <Route path="contract-person/edit/:contractPersonId" element={
      <PropsRoute path={`${username}/contract-person/edit/:contractPersonId`} 
        dispatch={props.dispatch} element={<AsyncContractPersonEdit/>} user={props.user}/>
    }></Route>
  </Routes>
}

export default HumanResource;


// const HumanResource = (props) => (
  
//   <Routes>
//     {/* <Redirect exact from={`${props.match.url}/human_resource`} to={`${props.match.url}`}/> */}
//     <Route path={`${props.match.url}/human_resource`} element={<Navigate to={`${props.match.url}`} />} />
//         <PropsRoute path={`${props.match.url}/employee/list`} 
//           dispatch={props.dispatch} component={AsyncEmployeeEdit} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/project_coordinators/edit/:project_coordinatorsId`} 
//           dispatch={props.dispatch} component={AsyncProjectCoordinatorEdit} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/marketer/edit/:marketerId`} dispatch={props.dispatch}
//           component={AsyncMarketerEdit} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/marketer/list`} dispatch={props.dispatch}
//           component={AsyncMarketerList} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/recruiter/list`} dispatch={props.dispatch}
//           component={AsyncRecruiter} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/project_coordinators`} dispatch={props.dispatch}
//           component={AsyncProjectCoordinators} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/recruiter/edit/:recruiterId`} dispatch={props.dispatch}
//           component={AsyncRecruiterEdit} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/engineers/list`} dispatch={props.dispatch}
//           component={AsyncEngieersList} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/engineers/edit/:engineerId`} 
//           dispatch={props.dispatch} component={AsyncEngieerEdit} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/contract-person/list`} dispatch={props.dispatch}
//           component={AsyncContractPerson} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/contract-person/edit/:contractPersonId`} 
//           dispatch={props.dispatch} component={AsyncContractPersonEdit} user={props.user}/>
//   </Routes>
// );

// export default HumanResource;