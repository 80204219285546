import React from "react";
import {Route, Routes} from "react-router-dom";

import {RoleRestrictedRoute, UserRestrictedRoute} from "../util/routeRestriction"
import Dashboard from "./dashboard";
import UserCalendar from "./calendar";
import Recruitment from "./recruitment";
import Marketing from "./marketing";
import ProjectCoordinator from './projectCoordination/index'
import Engineer from "./engineer"
import Employee from "./user";
import HumanResource from "./humanResource"
import Profile from "./profile";
import Contract from './contract';
import Onboarding from "./onboarding";
import TransferRequest from "./TransferRequest"
import Consultant from "./consultant";
import Accounts from "./accounts";
import Timesheets from "./timesheets";
import '../styles/ui/Uicss/styleCss.less'

const App = ({match, user, location, dispatch}) => {

  // <div className="gx-main-content-wrapper">
  return <div className={location && location.pathname && (location.pathname.includes('accounts/consultant/payroll')||
    location.pathname.includes('accounts/run_payroll/w2')||location.pathname.includes('accounts/run_payroll/1099')||
    location.pathname.includes('accounts/run_payroll/global_payroll')
  ) ? 'marginFive' : ""} style={{padding:'5px'}} >
      <Routes>
        <Route path={`/:username/dashboard/*`} element={
          <UserRestrictedRoute path={`/${user.username}/dashboard`} 
            user={user}
            dispatch={dispatch} 
            username={user.username}
            location={location} 
            element={<Dashboard/>}/>
          }
          ></Route>
          {/* component={Dashboard}/> */} 
        <Route path={`/:username/calendar/*`} element={
          <UserRestrictedRoute path={`/${user.username}/calendar`}// path={`${match.url}:username/calendar`} 
            dispatch={dispatch} 
            user={user}
            username={user.username}
            location={location}
            element={<UserCalendar/>}/>
        }></Route>
          {/* component={UserCalendar}/> */}
        <Route path={`/:username/transfer-requests`} element={
          <UserRestrictedRoute path={`/${user.username}/transfer-requests`}
            dispatch={dispatch} 
            user={user}
            username={user.username}
            location={location} 
            element={<TransferRequest/>}/>
        }></Route>
          {/* component={TransferRequest}/> */}
        <Route path={`/:username/profile/*`} element={
          <UserRestrictedRoute path={`/${user.username}/profile`} 
            user={user} 
            username={user.username}
            location={location} dispatch={dispatch}
            element={<Profile/>}/>
        }></Route>
          {/* component={Profile}/> */}
        <Route path={`/:username/recruitment/*`} element={
          <RoleRestrictedRoute path={`${user.username}:username/recruitment`}
            dispatch={dispatch}
            user={user}
            roles={['Recruiter', 'Admin']}
            element={<Recruitment/>}/>
        }></Route>
          {/* component={Recruitment}/> */}
        {/* <Route path={`${match.url}:username/marketing`} element={ */}
        {/* <Route path={`/${user.username}/marketing/*`} element={ */}
        {/* <Route path={`/:username/:marketing/*`} element={ */}
        <Route path={`/:username/marketing/*`} element={
          <RoleRestrictedRoute path={`/${user.username}/marketing`}
            user={user} 
            dispatch={dispatch}
            roles={['Marketer', 'Admin']}
            element={<Marketing/>}/>
        }></Route>
          {/* component={Marketing}/> */}
        <Route path={`/:username/project_coordination/*`} element={
          <RoleRestrictedRoute path={`/${user.username}/project_coordination`}
            user={user} 
            dispatch={dispatch}
            roles={['Project Coordination', 'Admin']}
            element={<ProjectCoordinator/>}/>
        }></Route>
          {/* component={ProjectCoordinator}/> */}
        <Route path={`/:username/engineering/*`} element={
          <RoleRestrictedRoute path={`${user.username}/engineering`}
            user={user} 
            dispatch={dispatch}
            roles={['Engineering', 'Admin']}
            element={<Engineer/>}/>
        }></Route>
          {/* component={Engineer}/> */}
        <Route path={`/:username/human_resource/*`} element={
          <RoleRestrictedRoute path={`${user.username}/human_resource`}
            user={user} 
            dispatch={dispatch}
            roles={['Human Resource', 'Admin']}
            element={<HumanResource/>}/>
        }></Route>
          {/* component={HumanResource}/> */}
        <Route path={`/:username/contract/*`} element={
          <RoleRestrictedRoute path={`${user.username}:username/contract`}
            user={user} 
            dispatch={dispatch}
            roles={['Contract', 'Admin']}
            element={<Contract/>}/>
        }></Route>
          {/* component={Contract}/> */}
        <Route path={`/:username/accounts/*`} element={
          <RoleRestrictedRoute path={`${user.username}/accounts`}
            user={user} 
            dispatch={dispatch}
            roles={['Accounts', 'Admin']}
            element={<Accounts/>}/>
        }></Route>
        <Route path={`/:username/timesheets/*`} element={
          <RoleRestrictedRoute path={`${user.username}/timesheets`}
            user={user} 
            dispatch={dispatch}
            roles={['Timesheets', 'Admin']}
            element={<Timesheets/>}/>
        }></Route>
          {/* component={Accounts}/> */}
        <Route path={`/:username/consultant/*`} element={
          <RoleRestrictedRoute path={`${user.username}/consultant`}
            user={user} 
            dispatch={dispatch}
            roles={['Consultant', 'Admin']}
            element={<Consultant/>}/>
        }></Route>
          {/* component={Consultant}/> */}
        <Route path={`/:username/employee/*`} element={
          <RoleRestrictedRoute path={`${user.username}/employee`} 
            user={user}
            dispatch={dispatch} 
            roles={['Admin']}
            element={<Employee/>}/>
        }></Route>
          {/* component={Employee}/> */}
      </Routes>
  </div>
};

export default App;


// const App = ({match, user, location, dispatch}) => {
// console.log('App m match :-',match )
// console.log('App m user :-',user )

//   // <div className="gx-main-content-wrapper">
//   return <div className={location && location.pathname && (location.pathname.includes('accounts/consultant/payroll')||
//     location.pathname.includes('accounts/run_payroll/w2')||location.pathname.includes('accounts/run_payroll/1099')
//   ) ? 'marginFive' : "gx-main-content-wrapper"}>
//       <Routes>
//         <Route path={`/:username/dashboard`} element={
//           <UserRestrictedRoute path={`/${user.username}/dashboard`} dispatch={dispatch} user={user}
//                                username={user.username}
//                                location={location} 
//                                element={<Dashboard/>}/>
//           }
//           ></Route>
//           {/* component={Dashboard}/> */} 
//         <Route path={`/:username/calendar/*`} element={
//           <UserRestrictedRoute path={`/${user.username}/calendar`} dispatch={dispatch} user={user}
//                                 username={user.usernamex}
//                                 location={location} 
//                                 element={<UserCalendar/>}/>
//         }></Route>
//           {/* component={UserCalendar}/> */}
//         <Route path={`${match.url}:username/transfer-requests`} element={
//           <UserRestrictedRoute path={`${match.url}:username/transfer-requests`} dispatch={dispatch} user={user}
//                                username={user.username}
//                                location={location} 
//                                element={TransferRequest}/>
//         }></Route>
//           {/* component={TransferRequest}/> */}
//         <Route path={`${match.url}:username/profile`} element={
//           <UserRestrictedRoute path={`${match.url}:username/profile`} user={user} username={user.username}
//                                location={location} dispatch={dispatch}
//                                element={Profile}/>
//         }></Route>
//           {/* component={Profile}/> */}
//         <Route path={`/:username/recruitment/*`} element={
          
//           <RoleRestrictedRoute path={`/${user.username}/recruitment`}
//                                dispatch={dispatch}
//                                user={user} roles={['Recruiter', 'Admin']}
//                                element={<Recruitment/>}/>
//         }></Route>
//           {/* component={Recruitment}/> */}
//         {/* <Route path={`${match.url}:username/marketing`} element={ */}
//         {/* <Route path={`/${user.username}/marketing/*`} element={ */}
//         {/* <Route path={`/:username/:marketing/*`} element={ */}
//         <Route path={`/:username/marketing/*`} element={
//           <RoleRestrictedRoute path={`/${user.username}/marketing`}
//                                dispatch={dispatch}
//                                user={user} roles={['Marketer', 'Admin']}
//                                element={<Marketing/>}/>
//         }></Route>
//           {/* component={Marketing}/> */}
//         <Route path={`${match.url}:username/project_coordination`} element={
//           <RoleRestrictedRoute path={`${match.url}:username/project_coordination`}
//                                dispatch={dispatch}
//                                user={user} roles={['Project Coordination', 'Admin']}
//                                element={ProjectCoordinator}/>
//         }></Route>
//           {/* component={ProjectCoordinator}/> */}
//         <Route path={`${match.url}:username/engineering`} element={
//           <RoleRestrictedRoute path={`${match.url}:username/engineering`}
//                                dispatch={dispatch}
//                                user={user} roles={['Engineering', 'Admin']}
//                                element={Engineer}/>
//         }></Route>
//           {/* component={Engineer}/> */}
//         <Route path={`${match.url}:username/human_resource`} element={
//           <RoleRestrictedRoute path={`${match.url}:username/human_resource`}
//                                dispatch={dispatch}
//                                user={user} roles={['Human Resource', 'Admin']}
//                                element={HumanResource}/>
//         }></Route>
//           {/* component={HumanResource}/> */}
//         <Route path={`${match.url}:username/contract`} element={
//           <RoleRestrictedRoute path={`${match.url}:username/contract`}
//                                dispatch={dispatch}
//                                user={user} roles={['Contract', 'Admin']}
//                                element={Contract}/>
//         }></Route>
//           {/* component={Contract}/> */}
//         <Route path={`/:username/accounts/*`} element={
//           <RoleRestrictedRoute path={`/${user.username}/accounts`}
//                                dispatch={dispatch}
//                                user={user} roles={['Accounts', 'Admin']}
//                                element={<Accounts/>}/>
//         }></Route>
//           {/* component={Accounts}/> */}
//         <Route path={`${match.url}:username/consultant`} element={
//           <RoleRestrictedRoute path={`${match.url}:username/consultant`}
//                                dispatch={dispatch}
//                                user={user} roles={['Consultant', 'Admin']}
//                                element={Consultant}/>
//         }></Route>
//           {/* component={Consultant}/> */}
//         <Route path={`${match.url}:username/employee`} element={
//           <RoleRestrictedRoute path={`${match.url}:username/employee`} dispatch={dispatch} user={user} roles={['Admin']}
//                                element={Employee}/>
//         }></Route>
//           {/* component={Employee}/> */}
//       </Routes>
//   </div>
// };

// export default App;
