import IntlMessages from "util/IntlMessages";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import React from "react";
import {useDispatch} from "react-redux";

import {THEME_TYPE_LITE} from "../../constants/ThemeSetting";
// import { employeeInhouseOffersList } from "../../appRedux/actions/Offer";
import moment from "moment";
import { DeleteKeysFromSession } from "../../routes/recruitment/screening/utils";
import { fetchEmployees, fetchEngineerOffersList, fetchProjectCoordinatorsOffersList, fetchSupportPersonsList } from "../../appRedux/actions";
import { levelsConst } from "../../constants/Constants";
import dayjs from "dayjs";

const EngineerSidebar = ({navStyleClass, defaultOpenKeys, selectedKeys, themeType, user}) => {
  const currentDate = new Date();
  const defaultStartDate = dayjs(`${currentDate.getFullYear()}-${currentDate.getMonth()+1}-01`);
  const defaultEndDate = dayjs();  
  const dispatch = useDispatch();

  const keysToDeleteFromSession = ['pageSize','employeePageNo','profilePageNo','submissionPageNo',
    'assessmentPageNo','clientPagination','pocPageNo','ipPageNo','transferRequestPageNo','offersPageNo',
    'supportPersonsoffersPageNo','joiningPendingTablePageNumber','ongoingTablePageNumber','supportInvoicePageNo']
    
  const fetchDashboardData = () => {
    dispatch(fetchEngineerOffersList({project_status:'j'}))
    dispatch(fetchEngineerOffersList({project_status:'o'}))
    // dispatch(fetchSupportPersonsList({pagination:false}))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  // const fetchSupportPerson = () => {
  //   dispatch(fetchSupportPersonsList({pagination:true}))
  //   DeleteKeysFromSession(keysToDeleteFromSession)
  // }

  const fetchEngineersList = () => {
    dispatch(fetchEmployees(1 ,null ,'engineering'))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }


  return (
        <Menu defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]} theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'} mode="inline">
          <Menu.Item key="engineering.recruitment">
            <Link to={"/username/dashboard".replace('username', user.username)}
                  onClick={()=>fetchDashboardData()}>
              <span><i className="icon icon-auth-screen"/><IntlMessages id="sidebar.dashboard"/></span>
              </Link>
          </Menu.Item>
          { user && user.level && user.level <= levelsConst['Associate Team Lead'] && 
            <Menu.Item key="engineering.engineers">
              <Link to={"/".concat(user.username.concat("/engineering/engineers/list"))} onClick={()=>fetchEngineersList()}> 
              <i className="icon icon-card"/><IntlMessages id='sidebar.listType.engineers' /></Link>
            </Menu.Item>
          } 
          {/* <Menu.Item key="engineer.support_person">
            <Link to={"/".concat(user.username.concat("/engineer/support_person"))} onClick={()=>fetchSupportPerson()}> 
              <i className="icon icon-card"/><span>Support Person</span></Link>
          </Menu.Item> */}
        </Menu>
);
};
export default EngineerSidebar;
