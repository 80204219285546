import React, {useEffect} from "react";
import {Button, Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {userSignIn,setSignInError} from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import {getUser} from "../appRedux/actions";
import { Link, useNavigate } from "react-router-dom";


const SignIn = (props) => {

  const dispatch = useDispatch() ;
  const navigate = useNavigate()
  const token = useSelector(({auth}) => auth.token);
  const loggedInUser = useSelector(({user}) => user.loggedInUser);
  let invalidCredentials = useSelector(state => state.auth.invalidCredentials);
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    dispatch(userSignIn(values));
  };

  const handlePasswordChange = () => {
    dispatch(setSignInError())
  };
  const handleDeleteCaches = async () => {
    try {
    const cacheNames = await caches.keys();
    const deletePromises = cacheNames.map(cacheName => caches.delete(cacheName));
    await Promise.all(deletePromises);
    } catch (error) {
    console.error('Error deleting caches:', error);
    }
    };

  useEffect(() => {
    handleDeleteCaches();
    if (token !== null) {
      // props.history.push('/');
      navigate('/')
    }
  }, [token, props.history]);

  

  useEffect(() => {
    if (token !== null && !loggedInUser) {
      dispatch(getUser());
    }
  }, [token, dispatch, loggedInUser]);
  

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              {/*<img src="https://via.placeholder.com/272x395" alt='Neature'/>*/}
            </div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.signIn"/></h1>
              <p><IntlMessages id="app.userAuth.bySigning"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={require("assets/images/logo.png")}/>
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">
              <Form.Item
                rules={[{ required: true, message: 'The input is not valid!' }]} name="username">
                <Input placeholder="Username"/>
              </Form.Item>
              <Form.Item
                className={`${invalidCredentials&&" false ant-form-item-has-error"}`}
                rules= {[{required: true, message: 'Please input your Password!'}]}  name="password">
                <Input type="password" placeholder="Password" onChange={()=>handlePasswordChange()}/>
              </Form.Item>
              {invalidCredentials&&<div className="ant-form-item-explain ant-form-item-explain-error" style={{"margin-bottom":"20px", color:'#f5222d'}}><div role="alert">Sign-in unsuccessful. Please try again.</div></div>}
              {/* <Form.Item>*/}
              {/*  <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>*/}
              {/*  <span className="gx-signup-form-forgot gx-link"><IntlMessages*/}
              {/*    id="appModule.termAndCondition"/></span>*/}
              {/*</Form.Item> */}
              
                <Button style={{backgroundColor:"#003366", color:'white'}} className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn"/>
                </Button> <span>or </span>
              <Link to="/forgot-password">
              <IntlMessages
                id="app.userAuth.forgotPassword"/></Link>
              <p></p>
              <p
                className="gx-text-light gx-fs-sm"> Please enter your username: 'user_name' and password: 'password@123'</p>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
