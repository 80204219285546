import React from "react";
// import {Redirect, Switch} from "react-router";
import { Navigate, Redirect, Route, Switch, useParams } from "react-router";
import { Routes } from 'react-router-dom';
import { PropsRoute } from "../../util/routeRestriction";
import asyncComponent from "../../util/asyncComponent";

// const AsyncProjectCoordinatorSupportPerson = asyncComponent(() => import(''));
const AsyncTimeSheet = asyncComponent(() => import('../engineer/timeSheet/index'));

const Timesheets = (props) => {
    const { username } = useParams();
    console.log('username', username)
    // <Switch>
    return <Routes>
        <Route path={`${username}/timesheets`} element={<Navigate to={`${username}`} />} />
        <Route path="timesheet/:offerId" element={
            <PropsRoute path={`${username}timesheets/timesheet/:offerId`}
                dispatch={props.dispatch} element={<AsyncTimeSheet />} user={props.user} />
        }></Route>
    </Routes>
};

export default Timesheets;
