import React, {useEffect, useState} from 'react'
import { Input, Form, DatePicker, Button, Divider, Col, Row } from "antd";
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
const Addresspage = ({ formDataBackground, setFormDataBackground, form }) => {

    const [formAddresses, setFormAddresses] = useState([{ key: 0 }]);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    var email = searchParams.get('key');
    var token = searchParams.get('token');
    var emailToShow = searchParams.get('ae');
    email = atob(email);
    emailToShow = atob(emailToShow);
    formDataBackground.email= email;
    formDataBackground.token= token;
    formDataBackground.innit_email= emailToShow;

    useEffect(()=>{
        if(formDataBackground.address){
        formDataBackground.address.forEach((addr) => {
          if(addr.move_in){
            addr.move_in = dayjs(addr.move_in);
          }
          if(addr.move_out){
            addr.move_out = dayjs(addr.move_out);
          }
        })
      }
      form.setFieldsValue(formDataBackground)
    },[formDataBackground])



    const handleAddAddress = () => {
        const newKey = formAddresses.length;
        setFormAddresses([...formAddresses, { key: newKey }]);
    };
    const handleRemoveAddress = (keyToRemove) => {
            setFormAddresses(formAddresses.filter(address => address.key !== keyToRemove));
           if(formDataBackground.address) { 
            const updatedAddresses = formDataBackground.address.filter(address => address.key !== keyToRemove);
            setFormDataBackground({ ...formDataBackground, address: updatedAddresses });
            }
};


  return (
    <div> <Form
    layout="inline"
    form={form}
    name="address_update"
    className="gx-modal-box-form-item"
    key={"form"}
    onValuesChange={(changedValues, allValues) => {
      // if (changedValues && Object.keys(changedValues).length > 0) {
      //   // Update only the changed values in formDataBackground
      //   const updatedFormData = {
      //     ...formDataBackground,
      //     ...allValues
      //   };
      //   localStorage.setItem('formDataBackground', JSON.stringify(updatedFormData));
      // }
        setFormDataBackground({ ...formDataBackground, ...allValues });
    }}
>      
     <Row>
     <div style={{width:"100%",textAlign:"center",fontWeight:"bold",fontSize:"20px"}}>Details Required For Background Check</div>
            <div style={{width:"100%",textAlign:"center", fontSize:"15px"}}>List of Addresses you lived in the last 10 years starting from the current address to the previous addresses.</div>
            <Divider/>

            <Col span={24}>
              <Input.Group>
                <Form.Item label='Email Address'
                           rules={[{
                             required: true,
                             message: 'Please enter email!'
                           },{
                            type: 'email',
                            message: 'The input is not a valid email!'
                        }]}
                           name={'innit_email'}>
                  <Input type="email" disabled autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
    </Row>
    {formAddresses.map((address, index) => (
    <Row>
       
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Input.Group>
          <Form.Item label={`Street Address ${index+1}`}
                     rules={[{
                       required: true,
                       message: 'Street address is required!'
                     }]}
                     name={['address', address.key, 'street']}>
            <Input type="text" autoComplete="off"/>
          </Form.Item>
        </Input.Group>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form.Item label='City' rules={[{required: true, message: 'City is required!'}]} name={['address', address.key, 'city']}>
        <Input type="text" autoComplete="off"/>
      </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Input.Group>
          <Form.Item label='State'rules={[{required: true, message: 'State is required!'}]} name={['address', address.key, 'state']}>
          <Input type="text" autoComplete="off"/>
          </Form.Item>
        </Input.Group>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Input.Group>
          <Form.Item label="Zip code" name={['address', address.key, 'zip_code']} rules={[{required: true, message: 'Zip Code is required!'},{max:8 , message: "Characters should be less than or equal to 8"}]}>
          <Input type="number" autoComplete="off"/>
          </Form.Item>
        </Input.Group>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form.Item
          label="Move In Date"
          name={['address', address.key, 'move_in']}
          rules={[
            {
              required: false,
              message: 'Move In Date is required!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const moveOutDateValue = getFieldValue([
                  'address',
                  address.key,
                  'move_out',
                ]);

                if (!value || !moveOutDateValue || dayjs(value).isBefore(moveOutDateValue)) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('Move In Date must be before Move Out Date!'));
              },
            }),
          ]}
        >
          <DatePicker
            format="MM/DD/YYYY"
            autoComplete="off"
            className="ant-input"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
      
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form.Item
          label="Move Out Date"
          name={['address', address.key, 'move_out']}
          rules={[
            {
              required: false,
              message: 'Move Out Date is required!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const moveInDateValue = getFieldValue([
                  'address',
                  address.key,
                  'move_in',
                ]);

                if (!value || !moveInDateValue || dayjs(value).isAfter(moveInDateValue)) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('Move Out Date must be after Move In Date!'));
              },
            }),
          ]}
        >
          <DatePicker
            format="MM/DD/YYYY"
            autoComplete="off"
            className="ant-input"
            style={{ width: '100%' }} 
          />
        </Form.Item>
      </Col>
     { formAddresses.length > 1 && <Button className="gx-mb-0" type='dashed' onClick={() => handleRemoveAddress(address.key)}>
            Remove
      </Button>}
      <Divider/>
    </Row>))}
    <Row>
    <Button type="dashed" onClick={handleAddAddress} style={{ marginBottom: 16 }}>
        + Add Address
    </Button>
    </Row>
  </Form></div>
  )
}

export default Addresspage