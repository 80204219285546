import React, {useEffect} from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {Link, useNavigate} from "react-router-dom";
import styled from "styled-components";

import {useDispatch, useSelector} from "react-redux";
import { resetPassword, showAuthLoader, userSignUp,} from "../appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import {message} from "antd/lib/index";
import CircularProgress from "../components/Extras/CircularProgress";
import { hideMessage } from "appRedux/actions";

const FormItem = Form.Item;
const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #003366 !important;
    border-color: #003366 !important;
  }
`;
const ForgotPassword = (props) => {


  const dispatch = useDispatch();
  // const history = useHistory();
  const navigate = useNavigate();
  const {loader, alertMessage, showMessage, authUser} = useSelector(({auth}) => auth);


  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      navigate('/')
      // history.push('/');
    }
  });

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   props.form.validateFields((err, values) => {
  //     console.log("values", values);
  //     if (!err) {
  //       dispatch(showAuthLoader());
  //       dispatch(userSignUp(values));
  //     }
  //   });
  // };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    dispatch(showAuthLoader());
    dispatch(resetPassword(values));
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={"https://via.placeholder.com/272x395"} alt='Neature'/>
            </div>
            <div className="gx-app-logo-wid">
              <h1>Forgot Password?</h1>
              <p>Enter your email and we'll send you a link to reset your password.</p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={require("assets/images/logo.png")}/>
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              // style={{padding:'auto 0'}}
              className="gx-signin-form gx-form-row0">
              <FormItem name="email" rules={[{
              required: true, type: 'email', message: 'The input is not valid E-mail!',
              }]}>
                  <Input placeholder="Email"/>
              </FormItem>
              <FormItem>
                <Button style={{backgroundColor:"#003366", color:'white'}} className="gx-mb-0" htmlType="submit">
                  Continue
                </Button>
                <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signin"><IntlMessages
                id="app.userAuth.signIn"/></Link>
              </FormItem>
            </Form>
          </div>
          {loader &&
          <div className="gx-loader-view">
            <CircularProgress/>
          </div>
          }
          {showMessage &&
          message.error(alertMessage)}
        </div>
      </div>
    </div>
  );
};


export default ForgotPassword;
