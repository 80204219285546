import React from "react";
import {Navigate, Redirect, Route, Switch, useParams} from "react-router";
import {Routes} from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";
import {PropsRoute} from "../../util/routeRestriction";

const AsyncMarketerOngoingOffers = asyncComponent(() => import('./ongoingOffers/index'));
const AsyncMarketerStandup = asyncComponent(() => import('./standup/index'));
const AsyncConsultant = asyncComponent(() => import('./consultant'));
const AsyncConsultantList = asyncComponent(()=>import('./consultant/edit'));
const AsyncSubmission = asyncComponent(()=>import('./submission'));
const AsyncSubmissionEdit = asyncComponent(() => import('./submission/edit'));
const AsyncProfileList = asyncComponent(() => import('./profiles'));
const AsyncOfferList = asyncComponent(() => import('./offer'));
const AsyncAssessmentList = asyncComponent(() => import('./assessment'));
const AsyncMarketerList = asyncComponent(() => import('./marketer'));
const AsyncScreening = asyncComponent(() => import('./screening'));
const AsyncMarketerEdit = asyncComponent(() => import('./marketer/edit'));
const AsyncIpComponent  = asyncComponent(() => import('./contact/ip'));
const AsyncClientComponent = asyncComponent(() => import('./contact/client'));
const AsyncVendorComponent = asyncComponent(() => import('./contact/vendor'));
const AsyncPocComponent = asyncComponent(() => import('./contact/poc'));
const AsyncContactComponent = asyncComponent(() => import('./contact'));
const AsyncRemoveDuplicates = asyncComponent(() => import('./contact/RemoveDuplicateClients'));
 
const Marketing = (props) => {
  const { username } = useParams();
  console.log('username userparamssssssssssssss',username)
  console.log('props',props)
  console.log('path 30',props.path)
  console.log('username',props && props.user && props.user.username)
  console.log('groups',props && props.user && props.user.groups)
  console.log('groups name',props && props.user && props.user.groups[0])
  return <Routes>
    {/* <Redirect exact from={`${props.match.url}/marketing`} to={`${props.match.url}`}/> */}
    <Route path={`/${username}/marketing`} element={<Navigate to={`${username}/dashboard/`} />} />
      <Route path="standup" element={
        <PropsRoute path={`/${username}marketing/standup`} dispatch={props.dispatch} 
          element={<AsyncMarketerStandup/>} user={props.user}
        />
      }></Route>
      <Route path="consultant/list" element={
        <PropsRoute path={`/${username}marketing/consultant/list`} dispatch={props.dispatch}
        element={<AsyncConsultant/>} user={props.user}/>
      }></Route>
    <Route path="ongoingoffers" element={
      <PropsRoute path={`${username}marketing/ongoingoffers`} dispatch={props.dispatch}
      element={<AsyncMarketerOngoingOffers/>} user={props.user}/>
    }></Route>
    <Route path="consultant/edit/:consultantId" element={
      <PropsRoute path={`${username}marketing/consultant/edit/:consultantId`} dispatch={props.dispatch}
        element={<AsyncConsultantList/>} user={props.user}/>
    }></Route>
    <Route path="contact/list" element={
      <PropsRoute path={`${username}marketing/contact/list`} dispatch={props.dispatch}
                element={<AsyncContactComponent/>} user={props.user}/>
    }></Route>
    <Route path="vendor/list"  element={
      <PropsRoute path={`${username}marketing/vendor/list`} dispatch={props.dispatch}
      element={<AsyncVendorComponent/>} user={props.user}/>
    }></Route>
    <Route path="poc/list"     element={
       <PropsRoute path={`${username}marketing/poc/list`} dispatch={props.dispatch}
       element={<AsyncPocComponent/>} user={props.user}/>
    }></Route>
    <Route path="client/list"  element={
       <PropsRoute path={`${username}marketing/client/list`} dispatch={props.dispatch}
       element={<AsyncClientComponent/>} user={props.user}/>
    }></Route>
    <Route path="partner/list" element={
      <PropsRoute path={`${username}marketing/partner/list`} dispatch={props.dispatch}
      element={<AsyncIpComponent/>} user={props.user}/>
    }></Route>
    <Route path="submission/list" element={
       <PropsRoute path={`${username}marketing/submission/list`} dispatch={props.dispatch}
       element={<AsyncSubmission/>} user={props.user}/>
    }></Route>
    <Route path="submission/edit/:submissionId" element={
       <PropsRoute path={`${username}marketing/submission/edit/:submissionId`} dispatch={props.dispatch}
       element={<AsyncSubmissionEdit/>} user={props.user}/>
    }></Route>
    <Route path="profiles/list" element={
       <PropsRoute path={`${username}marketing/profiles/list`} dispatch={props.dispatch}
       element={<AsyncProfileList/>} user={props.user}/>
    }></Route>
    <Route path="mergeClient" element={
      <PropsRoute path={`${username}marketing/mergeClient`} dispatch={props.dispatch}
        element={<AsyncRemoveDuplicates/>} user={props.user}/>
    }></Route>
    <Route path="offer/list" element={
      <PropsRoute path={`${username}marketing/offer/list`} dispatch={props.dispatch}
      element={<AsyncOfferList/>} user={props.user}/>
    }></Route>
    <Route path="assessment/list" element={
       <PropsRoute path={`${username}marketing/assessment/list`} dispatch={props.dispatch}
       element={<AsyncAssessmentList/>} user={props.user}/>
    }></Route>
    <Route path="calendar/invite/:screener" element={
       <PropsRoute path={`${username}marketing/calendar/invite/:screener`} dispatch={props.dispatch}
       element={<AsyncScreening/>} user={props.user}/>
    }></Route>
    <Route path="marketer/list" element={
       <PropsRoute path={`${username}marketing/marketer/list`} dispatch={props.dispatch}
       element={<AsyncMarketerList/>} user={props.user}/>
    }></Route>
    <Route path="marketer/edit/:marketerId" element={
        <PropsRoute path={`${username}marketing/marketer/edit/:marketerId`} dispatch={props.dispatch}
        element={<AsyncMarketerEdit/>} user={props.user}/> 
    }></Route>
    
  </Routes>
}

export default Marketing;


 
// const Marketing = (props) => {
//   console.log('props',props)
//   console.log('username',props && props.user && props.user.username)
//   console.log('groups',props && props.user && props.user.groups)
//   console.log('groups name',props && props.user && props.user.groups[0])
//   return <Routes>
//     {/* <Redirect exact from={`${props.match.url}/marketing`} to={`${props.match.url}`}/> */}
//     <Route path={`${props.match.url}/marketing`} element={<Navigate to={`${props.match.url}`} />} />
//     <PropsRoute path={`${props.match.url}/standup`} dispatch={props.dispatch}
//                 component={AsyncMarketerStandup} user={props.user}/>
//     <PropsRoute path={`${props.match.url}/consultant/list`} dispatch={props.dispatch}
//                 component={AsyncConsultant} user={props.user}/>
//     <PropsRoute path={`${props.match.url}/ongoingoffers`} dispatch={props.dispatch}
//                 component={AsyncMarketerOngoingOffers} user={props.user}/>
//     <PropsRoute path={`${props.match.url}/consultant/edit/:consultantId`} dispatch={props.dispatch}
//                 component={AsyncConsultantList} user={props.user}/>

//     <PropsRoute path={`${props.match.url}/contact/list`} dispatch={props.dispatch}
//                 component={AsyncContactComponent} user={props.user}/>
//     <PropsRoute path={`${props.match.url}/vendor/list`} dispatch={props.dispatch}
//                 component={AsyncVendorComponent} user={props.user}/>
//     <PropsRoute path={`${props.match.url}/poc/list`} dispatch={props.dispatch}
//                 component={AsyncPocComponent} user={props.user}/>
//     <PropsRoute path={`${props.match.url}/client/list`} dispatch={props.dispatch}
//                 component={AsyncClientComponent} user={props.user}/>
//     <PropsRoute path={`${props.match.url}/partner/list`} dispatch={props.dispatch}
//                 component={AsyncIpComponent} user={props.user}/>

//     <PropsRoute path={`${props.match.url}/submission/list`} dispatch={props.dispatch}
//                 component={AsyncSubmission} user={props.user}/>
//     <PropsRoute path={`${props.match.url}/submission/edit/:submissionId`} dispatch={props.dispatch}
//                 component={AsyncSubmissionEdit} user={props.user}/>

//     <PropsRoute path={`${props.match.url}/profiles/list`} dispatch={props.dispatch}
//                 component={AsyncProfileList} user={props.user}/>

//     <PropsRoute path={`${props.match.url}/mergeClient`} dispatch={props.dispatch}
//                 component={AsyncRemoveDuplicates} user={props.user}/>
               
//     <PropsRoute path={`${props.match.url}/offer/list`} dispatch={props.dispatch}
//                 component={AsyncOfferList} user={props.user}/>

//     <PropsRoute path={`${props.match.url}/assessment/list`} dispatch={props.dispatch}
//                 component={AsyncAssessmentList} user={props.user}/>

//     <PropsRoute path={`${props.match.url}/calendar/invite/:screener`} dispatch={props.dispatch}
//                 component={AsyncScreening} user={props.user}/>

//     <PropsRoute path={`${props.match.url}/marketer/list`} dispatch={props.dispatch}
//                 component={AsyncMarketerList} user={props.user}/>
                
//     <PropsRoute path={`${props.match.url}/marketer/edit/:marketerId`} dispatch={props.dispatch}
//                 component={AsyncMarketerEdit} user={props.user}/>
//   </Routes>
// }

// export default Marketing;
