import {Navigate, Redirect, Route, Routes} from "react-router";
import React from "react";


// export const RestrictedRoute = ({element: Component, location, authUser, dispatch, isAuthenticated, ...rest}) => 
//   <Route
//     {...rest}
//     // render={props => isAuthenticated ? <Component dispatch={dispatch} user={authUser} {...props} /> :
//     //   <Redirect to={{pathname: '/signin', state: {from: location}}} />
//     // }
//   />;

export const RestrictedRoute = ({element, isAuthenticated, ...props}) => {
  return isAuthenticated ? React.cloneElement(element, {...props} )  : <Navigate to="/signin" />;
};

// export const RoleRestrictedRoute = ({component: Component, dispatch, user, roles, ...props}) =>
//   <Route
//     {...props}
//     // render={props => user.groups.some(r => roles.indexOf(r) >= 0) ? <Component user={user} dispatch={dispatch} {...props} /> :
//     //   <Redirect to={{ pathname: `/dashboard/username`.replace('username', user.username) }} />
//     // }
//     element={user.groups.some(r => roles.includes(r)) ? 
//       <Component user={user} dispatch={dispatch} {...props} /> : 
//       <Navigate to={`/dashboard/${user.username}`} />
//     }
//   />;
export const RoleRestrictedRoute = ({element, ...props}) =>{
  return props.user.groups.some(r => props.roles.includes(r)) ? React.cloneElement(element, {...props}) 
  :
  <Navigate to={`/dashboard/${props.user.username}`} />
};

// export const UserRestrictedRoute = ({component: Component, user, dispatch, username, location, ...props}) =>
//   <Route
//     {...props}
//     element={props => username === location.pathname.split("/")[1] ? <Component dispatch={dispatch} user={user} {...props} /> : <></>
//     // render={props => username === location.pathname.split("/")[1] ? <Component dispatch={dispatch} user={user} {...props} /> : <></>
//     //  console.log('')
//       // alert(`${username}--${location}---${location.pathname}---${location.pathname.split("/")[1]} not allowed to view this page.`)
//     }
//   />;

export const UserRestrictedRoute = ({element, ...props}) =>{
  return props.username === props.location.pathname.split("/")[1] ? React.cloneElement(element, {...props})
    : 
  <Navigate to="/signin" />
}

// export const PropsRoute = ({component: Component, dispatch, user, ...props}) =>
//    <Route
//      {...props}
//     //  render={ props => <Component dispatch={dispatch} user={user} {...props} /> }
//     element={ props => <Component dispatch={dispatch} user={user} {...props} /> }
//    />;

export const PropsRoute = ({element, ...props}) =>{
  return React.cloneElement(element, {...props})
}

// export const UnrestrictedRoute = ({component: Component}) => 
//    <Route 
//      render={props => <Component {...props} />}
//    />;

