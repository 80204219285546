import React, {memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
// import URLSearchParams from 'url-search-params'
// import {Route, Switch, useLocation, useNavigate, useRouteMatch} from "react-router";
import {Route, Switch, useLocation} from "react-router";
import { useMatch, Routes, useNavigate } from 'react-router-dom';
import {ConfigProvider} from 'antd';
// import {IntlProvider} from "react-intl";

import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import ForgotPassword from "../ForgotPassword";
import ForgotSuccess from "../ForgotSuccess";
import {setInitUrl} from "../../appRedux/actions/Auth";
import {RestrictedRoute} from "../../util/routeRestriction"
import {onLayoutTypeChange, onNavStyleChange, setThemeType} from "../../appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import {getUser} from "../../appRedux/actions";
import Onboarding from "../../routes/onboarding";
import Background from "../../routes/background";
import AdditionalInformation from "../../routes/consultant/additionalInformation";
import ApproveInvoicePage from "../../routes/approveInvoice";
import ApproveConsultantInvoicePage from "../../routes/approveConsultantInvoice";


const App = (props) => {

  const dispatch = useDispatch();
  const {locale, navStyle, layoutType} = useSelector(({settings}) => settings);
  const {initURL, isAuthenticated, authUser} = useSelector(({auth}) => auth);
  const loggedInUser = useSelector(({user}) => user.loggedInUser);

  const location = useLocation();
  // const history = useHistory();
  const navigate = useNavigate();
  // const match = useRouteMatch();
  const match = useMatch('/*');

  useEffect(() => {
    if(authUser && !loggedInUser){
      dispatch(getUser());
    }
  }, [dispatch, authUser, loggedInUser]);


  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const { URLSearchParams } = window;
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  });


  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  useEffect(() => {
    // if (location.pathname === '/') {
      if (location.pathname === '/' || location.pathname === '/login') {
      if (!isAuthenticated) {
        navigate('/signin')
        // history.push('/signin');
      } else if (initURL === '' || initURL === '/' || initURL === '/signin' || initURL === '/login') {
        let path = '/username/dashboard'.replace('username', authUser.username);
        if(authUser.groups.some(r => ['Project Coordination'].indexOf(r) >= 0)){ // this is added for the project coordinator only
          path = '/username/dashboard'.replace('username', authUser.username);
        }
        if(authUser.groups.some(r => ['Human Resource'].indexOf(r) >= 0)){
          path = '/username/human_resource/marketer/list'.replace('username', authUser.username);
        }
        if (authUser.groups.some(r => ['Admin'].indexOf(r) >= 0)) {
          path = '/username/dashboard'.replace('username', authUser.username); // /recruitment
        }
        navigate(path)
        // history.push(path);
      } else {
        const startsWithPasswordReset = initURL.startsWith('/password-reset/confirm/');
        if(startsWithPasswordReset && isAuthenticated && authUser && authUser.username){
          let path = '/username/dashboard'.replace('username', authUser.username);
          navigate(path)
          // history.push(path);
        }
      // }else{
      //   navigate(initURL)
      //   // history.push(initURL);
      // }
        else { // added this to correct the redirection if diffrent user is logged in while one is already loggedIn in other tab
          if (initURL && authUser && authUser.username) {
            let urlParts = initURL.split("/");
            
            if (urlParts[1] && urlParts[1] !== authUser.username) {
              urlParts[1] = authUser.username; // Replace the username
              var updatedURL = urlParts.join("/"); // Store the updated URL
            } else {
              var updatedURL = initURL; // Keep the original URL if no change is needed
            }
            navigate(updatedURL);
          } else {
            navigate(initURL);
          }
        }
      }
    }
  }, [isAuthenticated, authUser, initURL, location]);
// }, [isAuthenticated, authUser, initURL, location, history]);

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      {/* <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}> */}

        {/* <Switch> */}
        <Routes>
          <Route path='/signin' element={<SignIn/>}/>
          <Route path='/signup' element={<SignUp/>}/>
          <Route path='/forgot-password' element={<ForgotPassword/>}/>
          {/* :path/* */}
          <Route path="/password-reset/confirm/" element={<ForgotSuccess/>} /> 
          <Route path='/onboarding-form/' element={<Onboarding/>}/>
          <Route path='/background-verification/' element={<Background/>}/>
          <Route path='/additional-information-form/' element={<AdditionalInformation/>}/>
          <Route path='/invoices/:path/*' element={<ApproveInvoicePage/>}/>
          <Route path='/payroll/:path/*' element={<ApproveConsultantInvoicePage/>}/>

          <Route path='/*' element={
            // <RestrictedRoute path={`${match.url}`}
            <RestrictedRoute path={'/'}
              isAuthenticated={isAuthenticated}
              authUser={loggedInUser ? loggedInUser : authUser}
              user={loggedInUser ? loggedInUser : authUser}
              location={location}
              dispatch={dispatch}
              element={<MainApp/>}
            />
          }/>
        </Routes>
        {/* </Switch> */}

      {/* </IntlProvider> */}
    </ConfigProvider>
  )
};

export default memo(App);
