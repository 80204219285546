import React from "react";
import {Navigate, Redirect, Route, Switch, useParams} from "react-router";
import {Routes} from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";
import {PropsRoute} from "../../util/routeRestriction";

const AsyncEmployeeComponent = asyncComponent(() => import('./employee'));
const AsyncEmployeeEditComponent = asyncComponent(() => import('./employee/edit'));

const Employee = (props) => {
  const { username } = useParams();
  return <Routes>
    {/* <Redirect exact from={`${props.match.url}/employee`} to={`${props.match.url}`}/> */}
    <Route path={`${username}/employee`} element={<Navigate to={`${username}`} />} />
    <Route path="list" element={
      <PropsRoute path={`${username}employee/list`} dispatch={props.dispatch}
        element={<AsyncEmployeeComponent/>} user={props.user}/>
    }></Route>
    <Route path="edit/:employeeId" element={
      <PropsRoute path={`${username}employee/edit/:employeeId`} dispatch={props.dispatch}
        element={<AsyncEmployeeEditComponent/>} user={props.user}/>
    }></Route>
  </Routes>
}

export default Employee;

// const Employee = (props) => (

//   <Routes>
//     {/* <Redirect exact from={`${props.match.url}/employee`} to={`${props.match.url}`}/> */}
//     <Route path={`${props.match.url}/employee`} element={<Navigate to={`${props.match.url}`} />} />
//     <PropsRoute path={`${props.match.url}/list`} dispatch={props.dispatch}
//                 component={AsyncEmployeeComponent} user={props.user}/>
//     <PropsRoute path={`${props.match.url}/edit/:employeeId`} dispatch={props.dispatch}
//                 component={AsyncEmployeeEditComponent} user={props.user}/>
//   </Routes>
// );

// export default Employee;