import IntlMessages from "util/IntlMessages";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import React from "react";
import { useDispatch } from "react-redux";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import moment from "moment";
import { DeleteKeysFromSession } from "../../routes/recruitment/screening/utils";
import { fetchConsultantAccountDetailsList, fetchConsultantOffersList, fetchEmployees, fetchSupportPersonsList, fetchTimeSheetAttachmentList } from "../../appRedux/actions";
import { fetchAccountConsultantList, fetchAccountReceivableList, fetchAccountsOffersList, fetchReimbursementList, fetchTransactionList } from "../../appRedux/actions/Accounts";
import { levelsConst } from "../../constants/Constants";

const TimesheetsSidebar = ({ navStyleClass, defaultOpenKeys, selectedKeys, themeType, user }) => {
    const dispatch = useDispatch();

    const keysToDeleteFromSession = ['pageSize', 'employeePageNo', 'profilePageNo', 'submissionPageNo',
        'assessmentPageNo', 'clientPagination', 'pocPageNo', 'ipPageNo', 'transferRequestPageNo', 'offersPageNo',
        'supportPersonsoffersPageNo', 'ongoingTablePageNumber', 'accountDetatailPage', 'pagination', 'transactionListPage',
        'supportInvoicePageNo'
    ]

    const fetchDashboardData = () => {
        dispatch(fetchAccountsOffersList({ page: 1, status: 'ongoing' }))
        dispatch(fetchAccountsOffersList({ page: 1, status: 'closed' }))
        // dispatch(fetchTimeSheetAttachmentList({ status: 'pending', attachment_type: 'vendor', page: 1 }))
        dispatch(fetchTimeSheetAttachmentList({ status: 'pending', page: 1 }))
        // dispatch(fetchTimeSheetAttachmentList({ status:'pending', attachment_type:'pay_stub', page:1 }))
        DeleteKeysFromSession(keysToDeleteFromSession)
    }

    return (
        <Menu triggerSubMenuAction="hover" defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'} mode="inline"
        >
            <Menu.Item key="account.dashboad">
                <Link to={"/username/dashboard".replace('username', user.username)}
                    onClick={() => fetchDashboardData()}> <span>
                        <i className="icon icon-auth-screen" /><IntlMessages id="sidebar.dashboard" /></span>
                </Link>
            </Menu.Item>
        </Menu>
    );
};
export default TimesheetsSidebar;
