import React, {useState} from "react";
import { Input, Form, DatePicker, Divider, Col, Radio, Upload, message, Select } from "antd";
import InboxOutlined from "@ant-design/icons/lib/icons/InboxOutlined";
import moment from "moment";
import dayjs from "dayjs";

function Page2({ formData, setFormData, form }) {
    const { Dragger } = Upload;
    const {Option} = Select;
    // const [form] = Form.useForm();
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [fileList, setFileList] = useState(formData.state_id?formData.state_id.fileList:[]);

    const handleRadioChange = (e) => {
      console.log(e.target.value )
        if (e.target.value === 'Other') {
            setIsOtherSelected(true);
        } else {
            setIsOtherSelected(false);
        }
        if (formData && (formData.gc_copy || formData.gc_ead_copy || formData.opt_copy || formData.h4_ead_copy || formData.cpt_copy || formData.other_copy)) {
          const { gc_ead_copy, opt_copy, h4_ead_copy, cpt_copy, other_copy ,gc_copy } = formData;
          if (gc_copy) delete formData.gc_copy;
          if (gc_ead_copy) delete formData.gc_ead_copy;
          if (opt_copy) delete formData.opt_copy;
          if (h4_ead_copy) delete formData.h4_ead_copy;
          if (cpt_copy) delete formData.cpt_copy;
          if (other_copy) delete formData.other_copy;
          setFormData({ ...formData });
          form.resetFields([ 'gc_copy' ,'gc_ead_copy', 'opt_copy', 'h4_ead_copy', 'cpt_copy', 'other_copy' ]);
          localStorage.setItem('formData', JSON.stringify({ ...formData }));
        }
    };

    const handleBeforeUpload = (file) => {
        return false;
    };
    const acceptableFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'pdf'];
    const filterFilesByType = (fileList) => {
      return fileList.map(file => {
          const fileExtension = file.name.split('.').pop().toLowerCase();
          const isAcceptableFileType = acceptableFileTypes.includes(fileExtension);
  
          if (!isAcceptableFileType) {
              message.error(`Unsupported file type: ${file.name}`);
              return null;
          }
  
          return file;
      }).filter(Boolean); 
  };
  

    const handleFileChange = (info) => {
        let updatedFileList = [...info.fileList];

        updatedFileList = updatedFileList.filter((file) => {
            if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
            return file.status !== 'error';
        });
        updatedFileList = filterFilesByType(updatedFileList);
        setFileList(updatedFileList);
        form.validateFields(['state_id']).catch(() => {});
    };
    // 
    const [fileListDLFront, setFileListDLFront] = useState(formData.driving_license_front?formData.driving_license_front.fileList:[]);
    // const [fileListDLBack, setFileListDLBack] = useState(formData.driving_license_back?formData.driving_license_back.fileList:[]);

    const handleFileChangeDLFront = (info) => {
        let updatedFileList = [...info.fileList];
        updatedFileList = updatedFileList.filter((file) => {
            if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
            return file.status !== 'error';
        });
        updatedFileList = filterFilesByType(updatedFileList);
        setFileListDLFront(updatedFileList);
    };

    // const handleFileChangeDLBack = (info) => {
    //     let updatedFileList = [...info.fileList];
    //     updatedFileList = updatedFileList.filter((file) => {
    //         if (file.status === 'error') {
    //             message.error(`${file.name} file upload failed.`);
    //         }
    //         return file.status !== 'error';
    //     });
    //     setFileListDLBack(updatedFileList);
    // };

    const uploadProps = {
        multiple: false,
        fileList,
        beforeUpload: handleBeforeUpload,
        onChange: handleFileChange,
    };
  return (
    <div>
        <Form
          layout="inline"
          form={form}
          initialValues={formData}
          onValuesChange={(changedValues, allValues) => {
            setFormData({ ...formData, ...allValues })
            }}
          className="gx-modal-box-form-item">
            <div style={{width:"100%",textAlign:"center",fontWeight:"bold",fontSize:"15px"}}>Education Details</div>
            <Divider/>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label="Bachelor's Degree"
                           rules={[{
                             required: true,
                             message: 'Please enter degree name!'
                           }]}
                           name={'degree_1'}>
                  <Input type="text" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label="Institute"
                           name={'institute_1'} 
                          //  rules={[{
                          //   required: true,
                          //   message: 'Please enter insitute name!'
                          // }]}
                          >
                  <Input type="text" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label="University"
                           name={'university_1'}
                           rules={[{
                            required: true,
                            message: 'Please enter university name!'
                          }]}>
                  <Input type="text" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label="Area of Study"
                           name={'area_of_study_1'}
                          //  rules={[{
                          //   required: true,
                          //   message: 'Please enter Area of Study!'
                          // }]}
                          >
                  <Input type="text" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col> 
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Started On"
                name={'start_year_1'}
                rules={[
                  {
                    required: true,
                    message: 'Please enter start year!'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || !getFieldValue("end_year_1") || dayjs(value).isBefore(getFieldValue("end_year_1"))) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error('Start date must be before end date!'));
                    },
                  }),
                ]}

              >
                <DatePicker
                  format="MM/DD/YYYY"
                  autoComplete="off"
                  className="ant-input"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Ended On"
                name={'end_year_1'}
                rules={[{
                  required: true,
                  message: 'Please enter end year!'
                },  ({ getFieldValue }) => ({
                  validator(_, value) {

                    if (!value || !getFieldValue("start_year_1") || dayjs(value).isAfter(getFieldValue("start_year_1"))) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error('Start date must be before end date!'));
                  },
                }),]}
              >
                <DatePicker
                  format="MM/DD/YYYY"
                  autoComplete="off"
                  className="ant-input"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Input.Group>
              <Form.Item label='Visa at US entry' 
                        name="visa_at_us_entry">
                <Input type="text" autoComplete="off"/>
              </Form.Item>
            </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Input.Group>
              <Form.Item label='Visa source'  
                        name="current_visa_source">
                <Input type="text" autoComplete="off"/>
              </Form.Item>
            </Input.Group>
            </Col>
            <Divider/>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label="Master's Degree"
                           name={'degree_2'}
                           rules={[
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const isSecondEducationFilled =
                                  getFieldValue('degree_2') ||
                                  getFieldValue('university_2') ||
                                  getFieldValue('start_year_2') ||
                                  getFieldValue('end_year_2');
            
                                if (isSecondEducationFilled && !value) {
                                  return Promise.reject('Degree is required');
                                }
            
                                return Promise.resolve();
                              },
                            }),
                          ]}
                           >
                            
                  <Input type="text" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label="Institute"
                           name={'institute_2'} 
                          >
                  <Input type="text" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label="University"
                           name={'university_2'}
                           rules={[
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const isSecondEducationFilled =
                                  getFieldValue('degree_2') ||
                                  getFieldValue('university_2') ||
                                  getFieldValue('start_year_2') ||
                                  getFieldValue('end_year_2');
            
                                if (isSecondEducationFilled && !value) {
                                  return Promise.reject('university is required');
                                }
            
                                return Promise.resolve();
                              },
                            }),
                          ]}
                          >
                  <Input type="text" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input.Group>
                <Form.Item label="Area of Study"
                           name={'area_of_study_2'}
                           
                          >
                  <Input type="text" autoComplete="off"/>
                </Form.Item>
              </Input.Group>
            </Col> 
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Started On"
                name={'start_year_2'}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const isSecondEducationFilled =
                        getFieldValue('degree_2') ||
                        getFieldValue('university_2') ||
                        getFieldValue('start_year_2') ||
                        getFieldValue('end_year_2');
                      if(dayjs(value).isBefore(getFieldValue("end_year_1"))){
                        return Promise.reject("Master's Degree Start date must be after Ended On of the Bachelor's date!");
                      }
                      if (isSecondEducationFilled && !value) {
                        return Promise.reject('Start year is required');
                      }
  
                      return Promise.resolve();
                    },
                  }),
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || !getFieldValue("end_year_2") || dayjs(value).isBefore(getFieldValue("end_year_2"))) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error('Start date must be before end date!'));
                    },
                  }),
                ]}
              >
                <DatePicker
                  format="MM/DD/YYYY"
                  autoComplete="off"
                  className="ant-input"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Ended On"
                name={'end_year_2'}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const isSecondEducationFilled =
                        getFieldValue('degree_2') ||
                        getFieldValue('university_2') ||
                        getFieldValue('start_year_2') ||
                        getFieldValue('end_year_2');
  
                      if (isSecondEducationFilled && !value) {
                        return Promise.reject('End year is required');
                      }
  
                      return Promise.resolve();
                    },
                  })
                  ,  ({ getFieldValue }) => ({
                    validator(_, value) {
  
                      if (!value || !getFieldValue("start_year_2") || dayjs(value).isAfter(getFieldValue("start_year_2"))) {
                        return Promise.resolve();
                      }
  
                      return Promise.reject(new Error('Start date must be before end date!'));
                    },
                  }),
                  
                ]}
              >
                <DatePicker
                  format="MM/DD/YYYY"
                  autoComplete="off"
                  className="ant-input"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Select Attachment Type" name={'attachment_type'}
              rules={[{
                required: true,
                message: 'Please select attachment type!'
              }]}>
                <Select>
                  <Option key="state_id" value="state_id"> State Id </Option>
                  <Option key="driving_license" value = "driving_license">Driving License</Option>
                </Select>
              </Form.Item>
            </Col >
            {form.getFieldValue('attachment_type') ==="state_id" && <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Attach State ID" name='state_id' 
                      rules={[{
                             required: true,
                             message: 'Please upload state Id!'
                           }]}>
                            
                <Dragger {...uploadProps} fileList={fileList} onChange={handleFileChange}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag files to this area to upload</p>
                </Dragger>
              </Form.Item>
              </Col>}
              {form.getFieldValue('attachment_type') === "driving_license" && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="Driving License (Front)" name='driving_license_front' rules={[{
                  required: true,
                  message: 'Please upload DL front!'
                }]}>
                  <Dragger {...uploadProps} fileList={fileListDLFront} onChange={handleFileChangeDLFront}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  </Dragger>
                </Form.Item>
              </Col>
            )}

            {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="Driving License (Back)" name='driving_license_back' rules={[{
                             required: true,
                             message: 'Please upload DL Back!'
                           }]}>
                    <Dragger {...uploadProps} fileList={fileListDLBack} onChange={handleFileChangeDLBack}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>
                </Form.Item>
            </Col> */}
             <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Input.Group>
                    <Form.Item label="Visa Status" name={'visa'} rules={[{
                             required: true,
                             message: 'Please enter Visa Status!'
                           }]}>
                    <Radio.Group
                        options={["USC", "GC", "GC EAD", "H4 EAD", "OPT", "CPT", "Other"]}
                        onChange={handleRadioChange}
                    />
                    </Form.Item>
                    {isOtherSelected && (
                    <Form.Item label="Please specify" name={'othervisa'} rules={[{
                      required: true,
                      message: 'Please enter Visa Status!'
                    }]}>
                        <Input placeholder="Enter your visa status" />
                    </Form.Item>
                    )}
                </Input.Group>
            </Col>


            <Divider />
            <Col span={2}>
                          
            </Col>
        </Form>
    </div>
  );
}

export default Page2;
