import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import HomeOutlined from "@ant-design/icons/lib/icons/HomeOutlined";
import { toTitleCase } from "../../util/formCreate";

const BreadCrumb = ({ user, location }) => {
  const locationList = location.pathname.split("/");
  const last = locationList[locationList.length - 1];
  const lastList = last.includes(".") ? last.split(".") : last.split("_");
  const lastDisplay = toTitleCase(lastList);

  const getUrl = (path) => {
    return path.replace("username", user.username);
  };

  const breadcrumbNameMap = {
    dashboard: {
      display: "Home",
      url: getUrl("/username/dashboard"),
      className: "gx-link",
    },

    recruitment: {
      display: "Recruitment",
      url: getUrl("/username/dashboard/recruitment"),
      className: "gx-link",
    },
    marketing: {
      display: "Marketing",
      url: getUrl("/username/dashboard/marketing"),
      className: "gx-link",
    },

    profile: {
      display: "Profile",
      url: getUrl("/username/profile/view"),
      className: "gx-link",
    },
    "profile/view": { display: "View", url: getUrl("/username/profile/view") },
    "profile/edit": { display: "Edit", url: getUrl("/username/profile/edit") },

    consultant: {
      display: "Consultant",
      url: getUrl("/username/recruitment/consultant/list"),
      className: "gx-link",
    },
    "recruitment/standup": {
      display: "Standup",
      url: getUrl("/username/recruitment/standup"),
    },
    "consultantkjhg/list": {
      display: "Consultant List",
      url: getUrl("/username/recruitment/consultant/list"),
    },
    "accounts/consultant/list": {
      display: "Consultant",
      url: getUrl("/username/accounts/consultant/list"),
    },
    "consultant/list": {
      display: "list",
      url: getUrl("/username/accounts/consultant/list"),
    },
    "marketing/consultant/list": {
      display: "Consultant",
      url: getUrl("/username/marketing/consultant/list"),
    },
    consultant: {
      display: "Consultant",
      url: getUrl("/username/accounts/consultant/view/consultantSlug").replace(
        "consultantSlug",
        last
      ),
    },
    "consultant/view": {
      display: "view",
      url: getUrl("/username/accounts/consultant/view/consultantSlug").replace(
        "consultantSlug",
        last
      ),
    },
    "consultant/edit": {
      display: "Edit",
      url: getUrl(
        "/username/recruitment/consultant/edit/consultantSlug"
      ).replace("consultantSlug", last),
      className: "gx-link",
    },

    recruiter: {
      display: "Recruiter",
      url: getUrl("/username/recruitment/recruiter/list"),
      className: "gx-link",
    },

    "recruiter/list": {
      display: "List",
      url: getUrl("/username/recruitment/recruiter/list"),
    },
    "recruiter/edit": {
      display: "Edit",
      url: getUrl("/username/recruitment/recruiter/edit/recruiterId").replace(
        "recruiterId",
        last
      ),
      className: "gx-link",
    },
    marketer: {
      display: "Marketer",
      url: getUrl("/username/marketing/marketer/list"),
      className: "gx-link",
    },

    "marketer/list": {
      display: "List",
      url: getUrl("/username/marketing/marketer/list"),
    },
    "marketer/edit": {
      display: "Edit",
      url: getUrl("/username/marketing/marketer/edit/marketerId").replace(
        "marketerId",
        last
      ),
      className: "gx-link",
    },

    employee: {
      display: "Employee",
      url: getUrl("/username/employee/list"),
      className: "gx-link",
    },
    "transfer-requests": {
      display: "Transfer Requests",
      customPath: ["Transfer Requests"],
      url: getUrl("/username/transfer-requests"),
      className: "gx-link",
    },
    "employee/list": {
      display: "List",
      url: getUrl("/username/employee/list"),
    },
    "employee/edit": {
      display: "Edit",
      url: getUrl("/username/employee/edit/employeeId").replace(
        "employeeId",
        last
      ),
      className: "gx-link",
    },
    human_resource: {
      display: "Edit",
      url: getUrl("/username/human_resource/employee/edit/"),
      className: "gx-link",
    },
    "human_resource/employee": {
      display: "Edit",
      url: getUrl("/username/human_resource/employee/edit/"),
      className: "gx-link",
    },
    "human_resource/employee/list": {
      display: "Edit",
      url: getUrl("/username/human_resource/employee/edit/"),
      className: "gx-link",
    },
    screening: {
      display: "Screening",
      url: getUrl("/username/recruitment/screening/list"),
      className: "gx-link",
    },
    "screening/list": {
      display: "List",
      url: getUrl("/username/recruitment/screening/list"),
    },
    "screening/invite": {
      display: "Calendar",
      url: getUrl("/username/recruitment/screening/invite/screener").replace(
        "screener",
        last
      ),
      className: "gx-link",
    },
    submission: {
      display: "Submission",
      url: getUrl("/username/marketing/submission/list"),
      className: "gx-link",
    },
    standup: {
      display: "Standup",
      url: getUrl("/username/marketing/standup"),
    },
    offer: {
      display: "Offer",
      url: getUrl("/username/marketing/offer/list"),
      className: "gx-link",
    },
    ongoingoffers: {
      display: "Ongoing Offers",
      url: getUrl("/username/marketing/ongoingoffers"),
      className: "gx-link",
    },

    "offer/list": {
      display: "List",
      url: getUrl("/username/marketing/offer/list"),
    },
    assessment: {
      display: "Assessment",
      url: getUrl("/username/marketing/assessment/list"),
      className: "gx-link",
    },
    profiles: {
      display: "Marketing Profiles",
      url: getUrl("/username/marketing/profiles/list"),
      className: "gx-link",
    },
    "profiles/list": {
      display: "List",
      url: getUrl("/username/marketing/profiles/list"),
    },

    assessment: {
      display: "Assessment",
      url: getUrl("/username/marketing/assessment/list"),
      className: "gx-link",
    },
    "assessment/list": {
      display: "List",
      url: getUrl("/username/marketing/assessment/list"),
    },

    "submission/list": {
      display: "List",
      url: getUrl("/username/marketing/submission/list"),
    },
    "submission/edit": {
      display: "Edit",
      url: getUrl("/username/marketing/submission/edit/submissionId").replace(
        "submissionId",
        last
      ),
      className: "gx-link",
    },

    calendar: {
      display: "Calendar",
      url: getUrl("/username/marketing/calendar/invite/screener").replace(
        "screener",
        last
      ),
      className: "gx-link",
    },
    view: {
      display: "Calendar",
      url: getUrl("/username/calendar"),
      className: "gx-link",
    },
    "calendar/invite": {
      display: "Invite",
      url: getUrl("/username/marketing/calendar/invite/screener").replace(
        "screener",
        last
      ),
      className: "gx-link",
    },

    contact: {
      display: "Clients",
      url: getUrl("/username/marketing/contact/list"),
      className: "gx-link",
    },
    "contact/list": {
      display: "List",
      url: getUrl("/username/marketing/contact/list"),
    },
    vendor: {
      display: "Vendor",
      url: getUrl("/username/marketing/vendor/list"),
      className: "gx-link",
    },
    "vendor/list": {
      display: "List",
      url: getUrl("/username/marketing/vendor/list"),
    },
    poc: {
      display: "POCs",
      url: getUrl("/username/marketing/poc/list"),
      className: "gx-link",
    },
    "poc/list": {
      display: "List",
      url: getUrl("/username/marketing/poc/list"),
    },

    partner: {
      display: "Implementation Partner",
      url: getUrl("/username/marketing/partner/list"),
      className: "gx-link",
    },
    "partner/list": {
      display: "List",
      url: getUrl("/username/marketing/partner/list"),
    },

    client: {
      display: "Client",
      url: getUrl("/username/marketing/client/list"),
      className: "gx-link",
    },
    "client/list": {
      display: "List",
      url: getUrl("/username/marketing/client/list"),
    },
    mergeClient: {
      display: "Merge Client / Vendors",
      url: getUrl("/username/marketing/mergeClient"),
      className: "gx-link",
    },

    project: {
      display: "Project",
      url: getUrl("/username/marketing/project/list"),
      className: "gx-link",
    },
    "project/list": {
      display: "List",
      url: getUrl("/username/marketing/project/list"),
    },
    "project/edit": {
      display: "Edit",
      url: getUrl("/username/marketing/project/edit/projectId").replace(
        "projectId",
        last
      ),
      className: "gx-link",
    },
    support_person: {
      display: "Support persons list  ",
      url: getUrl("/username/project_coordination/support_person"),
      className: "gx-link",
    },
    project_coordinators: {
      display: "Project Coordintors list  ",
      url: getUrl("/username/project_coordination/project_coordinators"),
      className: "gx-link",
    },
    "project_coordinators/edit": {
      display: "Edit",
      url: getUrl(
        "/username/project_coordination/project_coordinators/edit/project_coordinatorsId"
      ).replace("project_coordinatorsId", last),
      className: "gx-link",
    },
    engineers: {
      display: "Engineers list",
      url: getUrl("/username/engineering/engineers/list"),
      className: "gx-link",
    },
    "engineers/list": {
      display: "list",
      url: getUrl("/username/engineering/engineers/list"),
      className: "gx-link",
    },
    "engineers/edit": {
      display: "Edit",
      url: getUrl(
        "/username/engineering/engineers/edit/project_coordinatorsId"
      ).replace("engineerId", last),
      className: "gx-link",
    },
    "contract-person": {
      display: "Contract Persons List  ",
      url: getUrl("/username/contract/contract-person/list"),
      className: "gx-link",
    },
    "contract-person/list": {
      display: "Contract Persons ",
      url: getUrl("/username/contract/contract-person/list"),
      className: "gx-link",
    },
    "contract-person/edit": {
      display: "Edit",
      url: getUrl(
        "/username/contract/contract-person/edit/contractPersonId"
      ).replace("contractPersonId", last),
      className: "gx-link",
    },
    timesheet: {
      display: "Timesheet",
      url: getUrl("/username/engineering/timesheet/offerId").replace(
        "offerId",
        last
      ),
      className: "gx-link",
    },

    "account-details": {
      display: "Account details",
      url: getUrl("/username/consultant/account-details"),
      className: "gx-link",
    },
    reimbursement: {
      display: "Reimbursement",
      url: getUrl("/username/consultant/reimbursement"),
      className: "gx-link",
    },
    "account-receivable": {
      display: "Account Receivable",
      url: getUrl("/username/accounts/account-receivable/list"),
      className: "gx-link",
    },
    "account-receivable/list": {
      display: "List",
      url: getUrl("/username/accounts/account-receivable/list"),
      className: "gx-link",
    },
    transaction: {
      display: "Transactions",
      url: getUrl("/username/accounts/transaction"),
      className: "gx-link",
    },
    run_payroll: {
      display: "Run Payroll",
      url: getUrl("/username/accounts/run_payroll/payroll_location").replace(
        "payroll_location",
        last
      ),
      className: "gx-link",
    },
    "run_payroll/payroll_location": {
      display: "Run Payroll",
      url: getUrl("/username/accounts/run_payroll/payroll_location").replace(
        "payroll_location",
        last
      ),
      className: "gx-link",
    },
    support: {
      display: "support",
      url: getUrl("/username/accounts/support/payroll/consultantId"),
      className: "gx-link",
    },
    "support/payroll": {
      display: "payroll",
      url: getUrl("/username/accounts/support/payroll/consultantId").replace(
        "consultantId",
        last
      ),
      className: "gx-link",
    },
    "support/payroll/consultantId": {
      display: "payroll",
      url: getUrl("/username/accounts/support/payroll/consultantId").replace(
        "consultantId",
        last
      ),
      className: "gx-link",
    },

    support_payroll: {
      display: "support",
      url: getUrl("/username/project_coordination/support_payroll/supportId"),
      className: "gx-link",
    },
    "support_payroll/supportId": {
      display: "payroll",
      url: getUrl(
        "/username/project_coordination/support_payroll/supportId"
      ).replace("supportId", last),
      className: "gx-link",
    },

    "consultant/payroll": {
      display: "payroll",
      url: getUrl("/username/accounts/support/payroll/consultantId").replace(
        "consultantId",
        last
      ),
      className: "gx-link",
    },
    "consultant/payroll/consultantId": {
      display: "payroll",
      url: getUrl("/username/accounts/support/payroll/consultantId").replace(
        "consultantId",
        last
      ),
      className: "gx-link",
    },
    payroll: {
      display: "Payroll",
      url: getUrl("/username/accounts/payroll/consultantId"),
      className: "gx-link",
    },
    "payroll/consultantId": {
      display: "Run Payroll",
      url: getUrl("/username/accounts/payroll/consultantId").replace(
        "consultantId",
        last
      ),
      className: "gx-link",
    },
    "account-person": {
      display: "Accounts",
      url: getUrl("/username/accounts/account-person"),
      className: "gx-link",
    },
    "account-person/edit": {
      display: "Edit",
      url: getUrl(
        "/username/accounts/account-person/edit/accountPersonId"
      ).replace("accountPersonId", last),
      className: "gx-link",
    },
  };

  // for creating dynamic url below is done.
  breadcrumbNameMap[`screening/invite/${last}`] = {
    display: lastDisplay,
    url: getUrl("/username/recruitment/screening/invite/screener").replace(
      "screener",
      last
    ),
  };

  breadcrumbNameMap[`calendar/invite/${last}`] = {
    display: lastDisplay,
    url: getUrl("/username/marketing/calendar/invite/screener").replace(
      "screener",
      last
    ),
  };
  breadcrumbNameMap[`consultant/edit/${last}`] = {
    display: lastDisplay,
    url: getUrl("/username/recruitment/consultant/edit/consultantSlug").replace(
      "consultantSlug",
      last
    ),
  };
  breadcrumbNameMap[`consultant/view/${last}`] = {
    display: lastDisplay,
    url: getUrl("/username/accounts/consultant/view/consultantSlug").replace(
      "consultantSlug",
      last
    ),
  };
  breadcrumbNameMap[`recruiter/edit/${last}`] = {
    display: lastDisplay,
    url: getUrl("/username/recruitment/recruiter/edit/recruiterId").replace(
      "recruiterId",
      last
    ),
  };
  breadcrumbNameMap[`marketer/edit/${last}`] = {
    display: lastDisplay,
    url: getUrl("/username/marketing/marketer/edit/marketerId").replace(
      "marketerId",
      last
    ),
  };
  breadcrumbNameMap[`employee/edit/${last}`] = {
    display: lastDisplay,
    url: getUrl("/username/employee/edit/employeeId").replace(
      "employeeId",
      last
    ),
  };
  breadcrumbNameMap[`submission/edit/${last}`] = {
    display: lastDisplay,
    url: getUrl("/username/marketing/submission/edit/submissionId").replace(
      "submissionId",
      last
    ),
  };
  breadcrumbNameMap[`project/edit/${last}`] = {
    display: lastDisplay,
    url: getUrl("/username/marketing/project/edit/projectId").replace(
      "submissionId",
      last
    ),
  };
  breadcrumbNameMap[`project_coordinators/edit/${last}`] = {
    display: lastDisplay,
    url: getUrl(
      "/username/project_coordination/project_coordinators/edit/project_coordinatorsId"
    ).replace("project_coordinatorsId", last),
  };
  breadcrumbNameMap[`engineers/edit/${last}`] = {
    display: lastDisplay,
    url: getUrl("/username/engineering/engineers/edit/engineerId").replace(
      "engineersId",
      last
    ),
  };
  breadcrumbNameMap[`contract-person/edit/${last}`] = {
    display: lastDisplay,
    url: getUrl(
      "/username/contract/contract-person/edit/contractPersonId"
    ).replace("contractPersonId", last),
  };
  breadcrumbNameMap[`timesheet/${last}`] = {
    display: lastDisplay,
    url: getUrl("/username/engineering/timesheet/offerId").replace(
      "offerId",
      last
    ),
  };
  breadcrumbNameMap[`payroll/${last}`] = {
    display: lastDisplay,
    url: getUrl("/username/accounts/payroll/consultantId").replace(
      "consultantId",
      last
    ),
  };
  breadcrumbNameMap[`consultant/payroll/${last}`] = {
    display: lastDisplay,
    url: getUrl("/username/accounts/consultant/payroll/consultantId").replace(
      "consultantId",
      last
    ),
  };
  breadcrumbNameMap[`support/payroll/${last}`] = {
    display: lastDisplay,
    url: getUrl("/username/accounts/support/payroll/consultantId").replace(
      "consultantId",
      last
    ),
  };
  breadcrumbNameMap[`support_payroll/${last}`] = {
    display: lastDisplay,
    url: getUrl(
      "/username/project_coordination/support_payroll/supportId"
    ).replace("supportId", last),
  };
  breadcrumbNameMap[`run_payroll/${last}`] = {
    display: lastDisplay,
    url: getUrl("/username/accounts/run_payroll/payroll_location").replace(
      "payroll_location",
      last
    ),
  };
  breadcrumbNameMap[`account-person/edit/${last}`] = {
    display: lastDisplay,
    url: getUrl(
      "/username/accounts/account-person/edit/accountPersonId"
    ).replace("accountPersonId", last),
  };
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  pathSnippets.shift();
  if (!pathSnippets.some((r) => ["employee", "profile"].includes(r))) {
    pathSnippets.shift();
  }

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `${pathSnippets.slice(0, index + 1).join("/")}`;
    const isLast = index === pathSnippets.length - 1;
    const isFirst = index === 0;
    return isLast && !isFirst ? (
      <Breadcrumb.Item key={breadcrumbNameMap[url]["url"]}>
        <span>{breadcrumbNameMap[url]["display"]}</span>
      </Breadcrumb.Item>
    ) : (
      <Breadcrumb.Item key={breadcrumbNameMap[url]["url"]}>
        <Link to={breadcrumbNameMap[url]["url"]}>
          <span className={breadcrumbNameMap[url]["className"]}>
            {breadcrumbNameMap[url]["display"]}
          </span>
        </Link>
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key={breadcrumbNameMap["dashboard"]["url"]}>
      <Link to={breadcrumbNameMap["dashboard"]["url"]}>
        <span className={breadcrumbNameMap["dashboard"]["className"]}>
          <HomeOutlined />
        </span>
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  const currentBreadcrumbKey = Object.keys(breadcrumbNameMap).find((key) =>
    location.pathname.startsWith(breadcrumbNameMap[key].url)
  );
  const currentBreadcrumb = breadcrumbNameMap[currentBreadcrumbKey];
  console.log("currentBreadcrumb", currentBreadcrumb);
  return (
    <div>
      {currentBreadcrumb && (
        <div>
          <h1
            style={{ borderBottom: "1px dotted black", margin: "3px" }}
            key={currentBreadcrumb["url"]}
          >
            {currentBreadcrumb["display"]}
          </h1>
        </div>
      )}
      <Breadcrumb separator={">"}>
        {breadcrumbItems}
        {/* {currentBreadcrumb.customPath ?  currentBreadcrumb.customPath : ''} */}
      </Breadcrumb>
      {/* {
        currentBreadcrumb.customPath && currentBreadcrumb.customPath.map((path, i) => (
          <Breadcrumb separator={'>'}>
            <span>{path}</span>
          </Breadcrumb>
        ))
      } */}
    </div>
  );
};

export default BreadCrumb;
