import React from "react";
import {Navigate, Redirect, Route, Switch, useParams} from "react-router";
import {Routes} from 'react-router-dom';
import {PropsRoute} from "../../util/routeRestriction";
import asyncComponent from "../../util/asyncComponent";

const AsyncCalendarView = asyncComponent(() => import('./view'))

const UserCalendar = (props) => {
   const { username } = useParams()
  // <Switch> 
  return <Routes>
    {/* <Redirect exact from={`${props.match.url}/calendar`} to={`${props.match.url}`}/> */}
    {/* <Route path={`${username}/calendar`} element={<Navigate to={`${username}`} />} /> */}
    <Route path="view" element={
      <PropsRoute
       path={`${username}/view`}
       user={props.user}
       dispatch={props.dispatch}
       element={<AsyncCalendarView/>}/>
    }></Route>
  </Routes>

};

export default UserCalendar;




// import React from "react";
// import {Navigate, Redirect, Route, Switch} from "react-router";
// import {Routes} from 'react-router-dom';
// import {PropsRoute} from "../../util/routeRestriction";
// import asyncComponent from "../../util/asyncComponent";

// const AsyncCalendarView = asyncComponent(() => import('./view'))

// const UserCalendar = (props) => (

//   // <Switch>
//   <Routes>
//     {/* <Redirect exact from={`${props.match.url}/calendar`} to={`${props.match.url}`}/> */}
//     <Route path={`${props.match.url}/calendar`} element={<Navigate to={`${props.match.url}`} />} />
//      <PropsRoute
//       path={`${props.match.url}/view`}
//       user={props.user}
//       dispatch={props.dispatch}
//       component={AsyncCalendarView}/>
//   </Routes>

// );

// export default UserCalendar;

