import React from "react";
import {Navigate, Redirect, Route, Switch, useParams} from "react-router";
import {Routes} from 'react-router-dom';
import {PropsRoute} from "../../util/routeRestriction";
import asyncComponent from "../../util/asyncComponent";

// const AsyncProjectCoordinatorSupportPerson = asyncComponent(() => import(''));
const AsyncContractPerson = asyncComponent(() => import('./contractPerson/index'));
const AsyncContractPersonEdit = asyncComponent(() => import('./contractPerson/edit'));
const AsyncTimeSheet = asyncComponent(() => import('../engineer/timeSheet/index'));
const AsyncConsultantList = asyncComponent(() => import('./consultant/index'));
const AsyncEditConsultant = asyncComponent(() => import('./consultant/edit'))

const Contract = (props) => {
  const { username } = useParams();
  return <Routes> 
    {/* <Redirect exact from={`${props.match.url}/contract`} to={`${props.match.url}`}/> */}
    <Route path={`${username}/contract`} element={<Navigate to={`${username}`} />} />
      <Route path={`contract-person/list`} element={
        <PropsRoute path={`${username}contract/contract-person/list`} dispatch={props.dispatch}
        element={<AsyncContractPerson/>} user={props.user}/>
      }></Route>
      <Route path={`contract-person/edit/:contractPersonId`} element={
        <PropsRoute path={`${username}contract/contract-person/edit/:contractPersonId`} 
          dispatch={props.dispatch} element={<AsyncContractPersonEdit/>} user={props.user}/>
      }></Route>
      <Route path={`timesheet/:offerId`} element={
        <PropsRoute path={`${username}contract/timesheet/:offerId`} 
          dispatch={props.dispatch} element={<AsyncTimeSheet/>} user={props.user}/>
      } ></Route>
      <Route path={`consultant/list`} element={
        <PropsRoute path={`${username}contract/consultant/list`} dispatch={props.dispatch}
        element={<AsyncConsultantList/>} user={props.user}/>
      }></Route>
      <Route path={`consultant/edit/:consultantSlug`} element={
        <PropsRoute path={`${username}contract/consultant/edit/:consultantSlug`} dispatch={props.dispatch}
        element={<AsyncEditConsultant/>} user={props.user}/>
      }></Route>

  </Routes>
};

export default Contract;

// const Contract = (props) => (
  
//   <Routes> 
//     {/* <Redirect exact from={`${props.match.url}/contract`} to={`${props.match.url}`}/> */}
//     <Route path={`${props.match.url}/contract`} element={<Navigate to={`${props.match.url}`} />} />
//         <PropsRoute path={`${props.match.url}/contract-person/list`} dispatch={props.dispatch}
//           component={AsyncContractPerson} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/contract-person/edit/:contractPersonId`} 
//           dispatch={props.dispatch} component={AsyncContractPersonEdit} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/timesheet/:offerId`} 
//           dispatch={props.dispatch} component={AsyncTimeSheet} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/consultant/list`} dispatch={props.dispatch}
//           component={AsyncConsultantList} user={props.user}/>
//         <PropsRoute path={`${props.match.url}/consultant/edit/:consultantSlug`} dispatch={props.dispatch}
//           component={AsyncEditConsultant} user={props.user}/>

//   </Routes>
// );

// export default Contract;
