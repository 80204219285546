import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Card, Button, Form, message, Modal, Upload, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStart, fetchSuccess } from '../../appRedux/actions';
import { useLocation } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';


const ApproveInvoicePage = () => {

    const dispatch = useDispatch()
    const location = useLocation();
    const [token, setToken] = useState(null)
    const [id, setId] = useState(null)
    const [data, setData] = useState(null)
    const [actionTaken, setActionTaken] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [attachmentFileList, setAttachmentFileList] = useState([])
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        if (window && window.location && window.location.pathname) {
            const splitedUrl = window.location.pathname.split('/'); // Split by '/'
            if (splitedUrl.length > 1) {
                const fullData = splitedUrl[2]; // Assuming the data starts after `/invoices/`
                if (fullData) {
                    const [id, token1, token2] = fullData.split(':');
                    setToken(fullData)
                    setId(id)

                    const currentDomain = window.location.hostname;
                    var endpoint = '';
                    if (currentDomain === 'dev.stell.one') {
                        endpoint = "https://dev-backend.stell.one/api/project-coordinators/support-invoices/support-approved-invoices/";
                    }
                    else if (currentDomain === 'stell.one') {
                        endpoint = "https://prod-backend.stell.one/api/project-coordinators/support-invoices/support-approved-invoices/";
                    }
                    else if (currentDomain === 'staging.stell.one') {
                        endpoint = "https://backend.stell.one/api/project-coordinators/support-invoices/support-approved-invoices/";
                    }
                    else if (currentDomain === 'localhost' || currentDomain === '127.0.0.1' || currentDomain === '0.0.0.0') {
                        endpoint = "http://localhost:8000/api/project-coordinators/support-invoices/support-approved-invoices/";
                        // endpoint = "https://prod-backend.stell.one/api/consultants/on-boarding/";

                    } else {
                        endpoint = "https://backend.stell.one/api/project-coordinators/support-invoices/support-approved-invoices/";
                    }
                    dispatch(fetchStart());
                    setIsLoading(true)
                    const apiEndpoint = `${endpoint}${fullData ? fullData + '/' : ''}`
                    axios.get(apiEndpoint)
                        .then((response) => {
                            dispatch(fetchSuccess());
                            setIsLoading(false)
                            if (response && response.data) {
                                setData(response.data)
                            }
                        })
                        .catch((error) => {
                            setIsLoading(false)
                            message.error('Error !');
                            console.log('error', error);
                        });
                }
            }
        }
    }, [])

    const handleButtonClick = (action) => {
        if (action) {
            if (attachmentFileList && attachmentFileList.length === 0 && action === 'approved') {
                var error = {}
                error['upload'] = 'Please upload a file!';
                setFormErrors(error);
                return
            } else {
                setFormErrors({});
            }
            Modal.confirm({
                title: 'Confirmation !',
                content: 'Are you sure you want to proceed ?',
                onOk: () => handleSubmit(action),
                onCancel: Modal.destroyAll(),
            })
        }
    }

    const handleSubmit = (values) => {
        if (window && window.location && window.location.pathname) {
            const splitedUrl = window.location.pathname.split('/'); // Split by '/'
            if (splitedUrl.length > 1) {
                const fullData = splitedUrl[2]; // Assuming the data starts after `/invoices/`
                if (fullData) {
                    const currentDomain = window.location.hostname;
                    var endpoint = '';
                    if (currentDomain === 'dev.stell.one') {
                        endpoint = "https://dev-backend.stell.one/api/project-coordinators/support-invoices/support-approved-invoices/";
                    }
                    else if (currentDomain === 'stell.one') {
                        endpoint = "https://prod-backend.stell.one/api/project-coordinators/support-invoices/support-approved-invoices/";
                    }
                    else if (currentDomain === 'staging.stell.one') {
                        endpoint = "https://backend.stell.one/api/project-coordinators/support-invoices/support-approved-invoices/";
                    }
                    else if (currentDomain === 'localhost' || currentDomain === '127.0.0.1' || currentDomain === '0.0.0.0') {
                        endpoint = "http://localhost:8000/api/project-coordinators/support-invoices/support-approved-invoices/";
                        // endpoint = "https://prod-backend.stell.one/api/consultants/on-boarding/";

                    } else {
                        endpoint = "https://backend.stell.one/api/project-coordinators/support-invoices/support-approved-invoices/";
                    }
                    dispatch(fetchStart());

                    const formData = new FormData()
                    if(values){
                        formData.append('approved_by_support',values)
                    }
                    if(attachmentFileList && attachmentFileList.length > 0){
                        formData.append('support_approved_invoices',attachmentFileList[0])
                    }
                    const apiEndpoint = `${endpoint}${fullData ? fullData + '/' : ''}`
                    axios.patch(apiEndpoint, formData)
                        .then((response) => {
                            dispatch(fetchSuccess());
                            message.success('Submitted Successfully!');
                            setActionTaken(true)
                        })
                        .catch((error) => {
                            message.error('Error Submitting !');
                            console.log('error', error);
                        });
                }
            }
        }
    }

    const isPdf = data && data.invoice_attachment && data.invoice_attachment.attachment_url ? data.invoice_attachment.attachment_url.endsWith('.pdf') : '';
    const isImage = data && data.invoice_attachment && data.invoice_attachment.attachment_url ? data.invoice_attachment.attachment_url.match(/\.(jpeg|jpg|gif|png)$/i) : '';
    const currentDomain = window.location.hostname;
    var endpoint = '';
    if (currentDomain === 'dev.stell.one') {
        endpoint = "https://dev-backend.stell.one/api";
    }
    else if (currentDomain === 'stell.one') {
        endpoint = "https://prod-backend.stell.one/api";
    }
    else if (currentDomain === 'staging.stell.one') {
        endpoint = "https://backend.stell.one/api";
    }
    else if (currentDomain === 'localhost' || currentDomain === '127.0.0.1' || currentDomain === '0.0.0.0') {
        endpoint = "http://localhost:8000/api";
        // endpoint = "https://prod-backend.stell.one/api/consultants/on-boarding/";

    } else {
        endpoint = "https://backend.stell.one/api";
    }

    let resolvedUrl = "";

    if (data && data.invoice_attachment && data.invoice_attachment.attachment_url) {
        if (data.invoice_attachment.attachment_url.startsWith("http")) {
            resolvedUrl = data.invoice_attachment.attachment_url;
        } else {
            resolvedUrl = `${endpoint}${data.invoice_attachment.attachment_url}`;
        }
    } else {
        console.error("Attachment URL is missing or invalid.");
    }
    return (<>
        <div
            style={{
                display: "flex",
                justifyContent: "center", // Horizontally center
                alignItems: "center", // Vertically center
                minHeight: "100vh", // Full viewport height
                backgroundColor: "#f0f2f5", // Optional: Background color
            }}
        >
            {!isLoading ? data ? <Card
                title="Review Invoices"
                style={{
                    width: "900px", // Set card width
                    textAlign: "center", // Center the text inside the card
                }}
            >
                {!actionTaken ? <>
                    {data && data.invoice_attachment && data.invoice_attachment.name ?
                        data && data.invoice_attachment && data.invoice_attachment.attachment_url && (isPdf ? (
                            <iframe
                                src={resolvedUrl}
                                style={{ width: '100%', height: '500px' }}
                                title="PDF Viewer"
                            />
                        ) : isImage ? (
                            <img
                                src={resolvedUrl}
                                alt="Attachmentss"
                                style={{ width: '100%', maxHeight: '500px', objectFit: 'contain' }}
                            />
                        ) : (
                            <iframe
                                src={resolvedUrl}
                                style={{ width: '100%', height: '500px' }}
                                title="File Viewer"
                            />
                        ))
                        : ''
                    }
                    {data.invoice_attachment && data.invoice_attachment.id ? <>
                        <div
                            style={{
                                margin: "50px 0px 0px 0px", display: "flex", justifyContent: "center",
                                alignItems: "center", flexDirection: "column"
                            }}
                        >
                            <Col span={12} >
                                <Form>
                                    <Form.Item
                                        rules={[{ required: true, message: 'Please upload a file' }]}
                                        validateStatus={formErrors && formErrors['upload'] ? 'error' : ''}
                                        help={formErrors && formErrors['upload'] ? formErrors['upload'] : ''}
                                    >
                                        <Upload
                                            fileList={attachmentFileList}
                                            beforeUpload={(file, fileList) => {
                                                setAttachmentFileList([file]);
                                                return false;
                                            }}
                                            onRemove={(file) => {
                                                setAttachmentFileList([]);
                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>Click Here To Upload Files</Button>
                                        </Upload>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </div>
                        <div style={{ margin: "0px 0px 0px 0px" }}>
                            <Button type="primary" onClick={() => handleButtonClick('approved')} >
                                Approve
                            </Button>
                            <Button type="danger" onClick={() => handleButtonClick('rejected')} >
                                Reject
                            </Button>
                        </div>
                    </> : <span style={{ color: 'red' }} >No Attachment Found !</span>
                    }
                </>
                    : 'Thanks...'
                }
            </Card> : 'Not found...' : 'Please Wait...'}
        </div>
    </>
    )
}

export default ApproveInvoicePage