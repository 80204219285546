import React, {useEffect} from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {Link, useNavigate} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import {resetPasswordMail, showAuthLoader, userSignUp,} from "../appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import {message} from "antd/lib/index";
import CircularProgress from "../components/Extras/CircularProgress";

const FormItem = Form.Item;

const ForgotSuccess = (props) => {


  const dispatch = useDispatch();
  // const history = useHistory();
  const {loader, alertMessage, showMessage} = useSelector(({auth}) => auth);


  
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    var afterConfirm ="";
    const currentUrl = window.location.href;
    const lastIndex = currentUrl.lastIndexOf('confirm/');
    if (lastIndex !== -1) {
      afterConfirm = currentUrl.substring(lastIndex + 'confirm/'.length);
      console.log(afterConfirm);
    }

    const endPoint = 'auth/password-reset/confirm/'+afterConfirm;
    dispatch(showAuthLoader());
    dispatch(resetPasswordMail(endPoint, values));
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={"https://via.placeholder.com/272x395"} alt='Neature'/>
            </div>
            <div className="gx-app-logo-wid">
              <h1>Confirm Reset Password</h1>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={require("assets/images/logo.png")}/>
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">
              {/* <FormItem name="password"
                        rules={[{required: true, message: 'Please input your Password!'}]}>
                  <Input type="password" placeholder="Password"/>
              </FormItem>
              <FormItem name="password_confirm"
                        rules={[{required: true, message: 'Please input your Password!'}]}>
                  <Input type="password" placeholder="Confirm Password"/>
              </FormItem> */}
                <FormItem
                      name="password"
                      rules={[
                        { required: true, message: 'Please input your Password!' },
                        { min: 6, message: 'Password must be at least 6 characters long' },
                      ]}
                    >
                      <Input type="password" placeholder="Password" />
                    </FormItem>
                    <FormItem
                      name="password_confirm"
                      dependencies={['password']}
                      rules={[
                        { required: true, message: 'Please confirm your Password!' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject('The two passwords do not match!');
                          },
                        }),
                      ]}
                    >
                  <Input type="password" placeholder="Confirm Password" />
                </FormItem>
              <FormItem>
                <Button style={{backgroundColor:"#003366", color:'white'}} className="gx-mb-0" htmlType="submit">
                  Submit
                </Button>
                <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signin">Go to SignIn Page</Link>
              </FormItem>
            </Form>
          </div>
          {loader &&
          <div className="gx-loader-view">
            <CircularProgress/>
          </div>
          }
          {showMessage &&
          message.error(alertMessage)}
        </div>
      </div>
    </div>
  );
};


export default ForgotSuccess;
