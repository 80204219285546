import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "../../appRedux/actions/Auth";
// import {useHistory} from "react-router";
import {useNavigate} from "react-router-dom";
import {getUser} from "../../appRedux/actions/Users";

const UserInfo = () => {
  const dispatch = useDispatch();
  // const history = useHistory(); use this if node 14
  const navigate = useNavigate();
  const user = useSelector(({auth}) => auth.authUser);

  const getMyAccount = () => {
        dispatch(getUser());
        let path = "/".concat(user.username.concat("/profile/view"));
        // history.push(path);
        navigate(path)
    };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => getMyAccount()}>My Account</li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
             trigger="click">
      <Avatar src={"https://via.placeholder.com/150"}
              className="gx-avatar gx-pointer" alt=""/>
    </Popover>
  )

};

export default UserInfo;
